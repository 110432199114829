import { Field, FormikProps, useFormikContext } from 'formik'
import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../../components/inputs/CheckboxInput'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'
import { B3_FORM_INITIAL_VALUES } from '../constants'

interface Props { }

export const B31TechnicalSolutionConcept: React.FC<Props> = (props) => {
    const formik = useFormikContext() as FormikProps<typeof B3_FORM_INITIAL_VALUES>
    return (
        <>
            <FormSectionTwoColumns sectionKey="b3.overallConceptSolutions" title="B.3.1. Celková koncepce stavebně technického a technologického řešení">
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.overallConceptSolutions.constructionTechnicalSolution.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.overallConceptSolutions.constructionTechnicalSolution.label}
                    />
                    {formik.values.b3.overallConceptSolutions.constructionTechnicalSolution.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.overallConceptSolutions.constructionTechnicalSolution.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.overallConceptSolutions.technologicalSolution.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.overallConceptSolutions.technologicalSolution.label}
                    />
                    {formik.values.b3.overallConceptSolutions.technologicalSolution.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.overallConceptSolutions.technologicalSolution.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
            </FormSectionTwoColumns>
        </>
    )
}
