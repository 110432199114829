import { Field, FormikProps, useFormikContext } from 'formik'
import React from 'react'
import RadioInput from 'src/components/inputs/RadioInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { B7_FORM_INITIAL_VALUES } from './constants'
import { CheckboxInput } from 'src/components/inputs/CheckboxInput'

interface Props { }

export const B7EnvironmentalImpactsForm: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<typeof B7_FORM_INITIAL_VALUES>
  return (
    <>
      <FormSectionTwoColumns
        sectionKey="b7.environmentalImpact"
        title="a) vliv na životní prostředí a opatření vedoucí k minimalizaci negativních vlivů - zejména příroda a krajina, Natura 2000, omezení nežádoucích účinků venkovního osvětlení, přítomnost azbestu, hluk, vibrace,
voda, odpady, půda, vliv na klima a ovzduší, včetně zařazení stacionárních zdrojů a zhodnocení souladu s opatřeními uvedenými v příslušném programu zlepšování kvality ovzduší podle jiného právního
předpisu 3)"
      >
        <div className="sm:col-span-6">
          <Field
            name="b7.environmentalImpact.natureAndLandscape.enabled"
            component={CheckboxInput}
            label="Příroda a krajina"
          />
          {formik.values.b7.environmentalImpact.natureAndLandscape.enabled && (
            <Field
              name="b7.environmentalImpact.natureAndLandscape.description"
              component={RichTextInput}
            />
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b7.environmentalImpact.natura2000.enabled"
            component={CheckboxInput}
            label="Natura 2000"
          />
          {formik.values.b7.environmentalImpact.natura2000.enabled && (
            <Field
              name="b7.environmentalImpact.natura2000.description"
              component={RichTextInput}
            />
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b7.environmentalImpact.outdoorLighting.enabled"
            component={CheckboxInput}
            label="Venkovní osvětlení"
          />
          {formik.values.b7.environmentalImpact.outdoorLighting.enabled && (
            <Field
              name="b7.environmentalImpact.outdoorLighting.description"
              component={RichTextInput}
            />
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b7.environmentalImpact.asbestos.enabled"
            component={CheckboxInput}
            label="Azbest"
          />
          {formik.values.b7.environmentalImpact.asbestos.enabled && (
            <Field
              name="b7.environmentalImpact.asbestos.description"
              component={RichTextInput}
            />
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b7.environmentalImpact.noiseAndVibrations.enabled"
            component={CheckboxInput}
            label="Hluk a vibrace"
          />
          {formik.values.b7.environmentalImpact.noiseAndVibrations.enabled && (
            <Field
              name="b7.environmentalImpact.noiseAndVibrations.description"
              component={RichTextInput}
            />
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b7.environmentalImpact.water.enabled"
            component={CheckboxInput}
            label="Voda"
          />
          {formik.values.b7.environmentalImpact.water.enabled && (
            <Field
              name="b7.environmentalImpact.water.description"
              component={RichTextInput}
            />
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b7.environmentalImpact.waste.enabled"
            component={CheckboxInput}
            label="Odpady"
          />
          {formik.values.b7.environmentalImpact.waste.enabled && (
            <Field
              name="b7.environmentalImpact.waste.description"
              component={RichTextInput}
            />
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b7.environmentalImpact.soil.enabled"
            component={CheckboxInput}
            label="Půda"
          />
          {formik.values.b7.environmentalImpact.soil.enabled && (
            <Field
              name="b7.environmentalImpact.soil.description"
              component={RichTextInput}
            />
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b7.environmentalImpact.climateAndAir.enabled"
            component={CheckboxInput}
            label="Klima a ovzduší"
          />
          {formik.values.b7.environmentalImpact.climateAndAir.enabled && (
            <Field
              name="b7.environmentalImpact.climateAndAir.description"
              component={RichTextInput}
            />
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b7.environmentalImpact.stationarySources.enabled"
            component={CheckboxInput}
            label="Stacionární zdroje"
          />
          {formik.values.b7.environmentalImpact.stationarySources.enabled && (
            <Field
              name="b7.environmentalImpact.stationarySources.description"
              component={RichTextInput}
            />
          )}
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns
        sectionKey="b7.eiaConsideration"
        title="b) způsob zohlednění podmínek závazného stanoviska posouzení vlivu záměru na životní
      prostředí, je-li podkladem"
      >
        <div className="sm:col-span-6">
          <Field
            name="b7.eiaConsideration.enabled"
            component={RadioInput}
            label="Je součástí projektu?"
            options={[
              {
                label: 'Ano',
                value: true,
              },
              {
                label: 'Ne',
                value: false,
              },
            ]}
          />
          {formik.values.b7.eiaConsideration.enabled && (
            <Field
              name="b7.eiaConsideration.description"
              component={RichTextInput}
            />
          )}
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns
        sectionKey="b7.eiaComplianceDescription"
        title="c) popis souladu záměru s oznámením záměru podle zákona o posuzování vlivů na životní
      prostředí, bylo-li zjišťovací řízení ukončeno se závěrem, že záměr nepodléhá dalšímu
      posuzování podle tohoto zákona"
      >
        <div className="sm:col-span-6">
          <Field
            name="b7.eiaComplianceDescription.enabled"
            component={RadioInput}
            label="Je součástí projektu?"
            options={[
              {
                label: 'Ano',
                value: true,
              },
              {
                label: 'Ne',
                value: false,
              },
            ]}
          />
          {formik.values.b7.eiaComplianceDescription.enabled && (
            <Field
              name="b7.eiaComplianceDescription.description"
              component={RichTextInput}
            />
          )}
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns
        sectionKey="b7.integratedPermitCompliance"
        title="d) v případě záměrů spadajících do režimu zákona o integrované prevenci základní parametry
      způsobu naplnění závěrů o nejlepších dostupných technikách nebo integrované povolení, bylo-li
      vydáno"
      >
        <div className="sm:col-span-6">
          <Field
            name="b7.integratedPermitCompliance.enabled"
            component={RadioInput}
            label="Je součástí projektu?"
            options={[
              {
                label: 'Ano',
                value: true,
              },
              {
                label: 'Ne',
                value: false,
              },
            ]}
          />
          {formik.values.b7.integratedPermitCompliance.enabled && (
            <Field
              name="b7.integratedPermitCompliance.description"
              component={RichTextInput}
            />
          )}
        </div>
      </FormSectionTwoColumns>
    </>
  )
}
