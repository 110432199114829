import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogBackdrop,
  DialogPanel,
} from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { FolderIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { UseDisclosureProps } from '@chakra-ui/react'
import { cn } from '../lib/utils'
import { GalleryVerticalIcon } from 'lucide-react'

interface CommandOption {
  label: string
  target: string
  className?: string
}

interface Props {
  options: CommandOption[]
  onSelected: (option: CommandOption) => void
  disclosure: UseDisclosureProps
}

export const SemiTransparentCommandPalette: React.FC<Props> = (props) => {
  const [query, setQuery] = useState('')

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === '/') {
        props.disclosure.onOpen?.()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const filteredProjects =
    query === ''
      ? props.options
      : props.options.filter((project) => {
          return project.label
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(
              query
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase(),
            )
        })

  return (
    <Dialog
      className="relative z-10"
      open={props.disclosure.isOpen}
      onClose={() => {
        setQuery('')
        props.disclosure.onClose?.()
      }}
    >
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-10 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <DialogPanel className="mx-auto max-w-2xl transform divide-y divide-gray-500 divide-opacity-10 overflow-hidden rounded-xl bg-white bg-opacity-95 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in">
          <Combobox
            onChange={(item: CommandOption) => {
              if (item) {
                setQuery('')
                props.onSelected(item)
                props.disclosure.onClose?.()
              }
            }}
          >
            <div className="relative">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-900 text-opacity-80"
                aria-hidden="true"
              />
              <ComboboxInput
                autoFocus
                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 focus:ring-0 sm:text-md placeholder-gray-500"
                placeholder="Vyhledat..."
                onChange={(event) => setQuery(event.target.value)}
                onBlur={() => setQuery('')}
              />
            </div>

            {(query === '' || filteredProjects.length > 0) && (
              <ComboboxOptions
                static
                as="ul"
                className="max-h-80 scroll-py-2 divide-y divide-gray-500 divide-opacity-10 overflow-y-auto"
              >
                <li className="p-2">
                  {query === '' && (
                    <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-900">
                      Rychlá navigace
                    </h2>
                  )}
                  <ul className="text-sm text-gray-900 font-normal">
                    {(query === '' ? props.options : filteredProjects).map((option) => (
                      <ComboboxOption
                        as="li"
                        key={option.label}
                        value={option}
                        className={cn(
                          'group flex cursor-default select-none items-center rounded-md px-3 py-2 data-[focus]:bg-blue-600 data-[focus]:bg-opacity-100 data-[focus]:text-white',
                          option.className,
                        )}
                      >
                        <GalleryVerticalIcon />
                        <span className={cn('ml-3 flex-auto truncate')}>{option.label}</span>
                      </ComboboxOption>
                    ))}
                  </ul>
                </li>
              </ComboboxOptions>
            )}

            {query !== '' && filteredProjects.length === 0 && (
              <div className="px-6 py-14 text-center sm:px-14">
                <FolderIcon
                  className="mx-auto h-6 w-6 text-gray-900 text-opacity-40"
                  aria-hidden="true"
                />
                <p className="mt-4 text-sm text-gray-900">
                  We couldn't find any projects with that term. Please try again.
                </p>
              </div>
            )}
          </Combobox>
        </DialogPanel>
      </div>
    </Dialog>
  )
}
