import React, { Fragment, useContext } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ListBulletIcon, PencilIcon } from '@heroicons/react/20/solid'
import { ISelectOption } from '../../model/interfaces/ISelectOption'
import { SecondaryButton } from '../SecondaryButton'
import { TextInput } from './TextInput'
import { Field, FieldProps } from 'formik'
import { get } from 'lodash'
import { PlusIcon } from '@heroicons/react/24/outline'
import { getFieldValidity } from '../../model/helpers/formHelpers'
import { IsDisabledContext } from 'src/model/context/IsDisabledContext'

interface Props extends FieldProps {
  options: ISelectOption[]
  label?: string
  forbidCustom?: boolean
  isCustom?: boolean
  setIsCustom?: (value: boolean) => void
  addButtonAction?: (e: React.MouseEvent) => void
  eraseOnCustom?: boolean
  isLoading?: boolean
  onChange?: (newValue: any) => void
  wrapperClassname?: string
  placeholder?: string
  emptyPlaceholder?: string
  disabled?: boolean
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const SelectInput: React.FC<Props> = (props) => {
  const { isInvalid, message } = getFieldValidity(props.form, props.field.name)
  const name = `${props.field.name}.value`
  const isCustomName = `${props.field.name}.isCustom`
  const value = get(props.form.values, name)
  const isCustom = get(props.form.values, isCustomName)
  const selectedValue = props.options.find((option) => option.value === value)
  const disabledContext = useContext(IsDisabledContext)
  const isDisabled = props.disabled || disabledContext

  const handleIsCustom = (custom: boolean) => {
    if (props.eraseOnCustom) {
      props.form.setFieldValue(name, '')

      if (props.onChange) props.onChange('')
    }

    props.form.setFieldValue(isCustomName, custom)
  }

  return (
    <div className={props.wrapperClassname}>
      <Listbox
        disabled={isDisabled}
        value={value}
        onChange={(value: any) => {
          if (props.onChange) {
            props.onChange(value)
          }
          props.form.setFieldValue(name, value)
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
              {props.label}
              {isInvalid && <span className="text-red-500 ml-1">{message}</span>}
            </Listbox.Label>
            <div className="flex relative mt-1">
              {isCustom ? (
                <>
                  <div className="w-full">
                    <Field component={TextInput} name={name} />
                  </div>
                  <SecondaryButton
                    size="sm"
                    onClick={() => handleIsCustom(false)}
                    className="ml-3 h-9 mt-2"
                  >
                    <ListBulletIcon className="w-4 h-4 text-gray-500" />
                  </SecondaryButton>
                </>
              ) : (
                <>
                  <Listbox.Button className="relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                    <span className="block truncate">
                      {selectedValue?.label || props.placeholder || 'Vyberte ze seznamu'}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white/90 backdrop-blur py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {[
                        { value: '', label: props.emptyPlaceholder || 'Vyberte ze seznamu' },
                        ...props.options,
                      ].map((option) => (
                        <Listbox.Option
                          key={option.value}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-blue-600 text-white' : 'text-gray-900',
                              'relative cursor-default select-none py-2 px-2 rounded m-1',
                            )
                          }
                          value={option.value}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate pl-6',
                                )}
                              >
                                {option.label}
                              </span>

                              {option.value === props.field.value ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-blue-600',
                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                  {!props.forbidCustom && (
                    <SecondaryButton
                      isLoading={props.isLoading}
                      onClick={() => handleIsCustom(true)}
                      className="ml-3"
                    >
                      <PencilIcon className="w-4 h-4 text-gray-500" />
                    </SecondaryButton>
                  )}
                  {typeof props.addButtonAction !== 'undefined' && (
                    <SecondaryButton onClick={props.addButtonAction} className="ml-3">
                      <PlusIcon className="w-4 h-4 text-gray-500" />
                    </SecondaryButton>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}
