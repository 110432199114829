import { TrashIcon } from '@heroicons/react/24/outline'
import { FieldProps } from 'formik'
import React, { useCallback, useContext } from 'react'
import { useDropzone } from 'react-dropzone'
import { getFieldValidity } from '../model/helpers/formHelpers'
import { UploadIcon } from 'lucide-react'
import { Spinner } from '@chakra-ui/react'
import { AuthImage } from './AuthImage'
import { useDeleteDocumentMutation } from '../model/api/document/useDeleteDocumentMutation'
import { ImageBlobReduceStatic } from 'image-blob-reduce'
import { IsDisabledContext } from '../model/context/IsDisabledContext'
import { AuthImageModal } from './AuthImageModal'
import { Button } from './ui/button'
import { DownloadIcon } from '@chakra-ui/icons'

interface Props extends FieldProps {
  className?: string
  label?: string
  accept?: { [key in string]: string[] }
}

const reduce = (window as any).ImageBlobReduce as ImageBlobReduceStatic

export const MultiImageDropzoneInput: React.FC<Props> = (props) => {
  const isDisabled = useContext(IsDisabledContext) ?? false
  const [isLoading, setIsLoading] = React.useState(false)
  const { isInvalid, message } = getFieldValidity(props.form, props.field.name)
  const { mutate: deleteDocument } = useDeleteDocumentMutation()

  let currentValues = (props.field.value || []) as File[]
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (isLoading) {
        return
      }

      setIsLoading(true)
      try {
        const processedFiles = await Promise.all(
          acceptedFiles.map(async (file) => {
            const resultBlob = await reduce().toBlob(file, { max: 1024 })
            return new File([resultBlob], file.name, {
              type: file.type,
              lastModified: file.lastModified,
            })
          }),
        )

        const values = [...processedFiles]

        if (Array.isArray(currentValues)) {
          values.push(...currentValues)
        }

        // filter all values with `value.name` that are duplicated in array
        const filteredValues = values.filter(
          (value: any, index: number, self: any) =>
            index === self.findIndex((t: any) => t.name === value.name),
        )

        return props.form.setFieldValue(props.field.name, filteredValues)
      } catch (err) {
        console.log({ err })
      } finally {
        setIsLoading(false)
      }
    },
    [currentValues],
  )

  const onRemove = useCallback(
    (file: any) => {
      let isStoredPhoto = false
      let fileIndex = currentValues.findIndex((v) => v.name === file.name)

      if (typeof file.id !== 'undefined') {
        fileIndex = currentValues.findIndex((v: any) => v.id === file.id)
        isStoredPhoto = true
      }

      if (window.confirm('Opravdu chcete smazat fotku?')) {
        const newValues = [...currentValues]
        newValues.splice(fileIndex, 1)
        props.form.setFieldValue(props.field.name, newValues)

        if (isStoredPhoto) {
          deleteDocument(file.id)
        }
      }
    },
    [currentValues],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.accept,
  })

  if (isDisabled) {
    return (
      <>
        {Array.isArray(currentValues) && currentValues.length > 0 && (
          <div>
            <div className="flex justify-end mt-2">
              <IsDisabledContext.Provider value={false}>
                <Button className="text-gray-600" disabled={false} variant="link" type="button">
                  <DownloadIcon className="h-5 w-5 mr-2 text-gray-600" />
                  Stáhnout vše
                </Button>
              </IsDisabledContext.Provider>
            </div>
            <div className="mt-1 grid grid-cols-3 sm:grid-cols-4 gap-3">
              {currentValues.map((v: any, vIndex) => {
                if (!v.id) {
                  return null
                }

                return (
                  <AuthImageModal
                    image={v}
                    key={vIndex}
                    className="mx-auto my-auto rounded-md shadow-md h-32 w-32 object-cover transition-all ease-in-out duration-300 hover:scale-110 hover:shadow-md"
                  />
                )
              })}
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {props.label && (
        <label
          htmlFor={props.field.name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.label}
          {isInvalid && <span className="text-red-500 ml-1">{message}</span>}
        </label>
      )}
      <div className="border-2 border-dotted p-5 rounded mt-5 grid grid-cols-3 sm:grid-cols-4 gap-3">
        {Array.isArray(currentValues) &&
          currentValues.map((v: any, vIndex) => (
            <div
              className="cursor-pointer relative group"
              key={`${vIndex}-${v.name}`}
              onClick={() => onRemove(v)}
            >
              <div className="invisible group-hover:visible absolute top-0 left-0 bottom-0 right-0 flex justify-center align-middle justify-items-center bg-gray-300 bg-opacity-75 rounded-md">
                <TrashIcon className="h-12 w-12 my-auto text-red-500" />
              </div>
              {typeof v.id === 'undefined' ? (
                <img
                  className="mx-auto my-auto rounded-md shadow-md h-32 w-32 object-cover"
                  src={URL.createObjectURL(v)}
                  alt="preview"
                />
              ) : (
                <AuthImage
                  image={v}
                  className="mx-auto my-auto rounded-md shadow-md h-32 w-32 object-cover"
                />
              )}
            </div>
          ))}
        <div
          className="cursor-pointer border-2 border-dotted my-auto mx-auto h-24 w-24 rounded-md flex"
          {...getRootProps()}
        >
          {isLoading ? (
            <div className="flex justify-center items-center mx-auto">
              <Spinner size="sm" />
            </div>
          ) : (
            <>
              {isDragActive ? (
                <div className="mx-auto my-auto">
                  <span className="text-md">Přetáhněte sem</span>
                </div>
              ) : (
                <UploadIcon
                  className="h-12 w-12 text-gray-300 mx-auto my-auto"
                  aria-hidden="true"
                />
              )}
            </>
          )}
          <label htmlFor="file-upload" />
          <input
            multiple={true}
            name="file-upload"
            type="file"
            accept="image/*"
            className="sr-only"
            {...getInputProps()}
          />
        </div>
      </div>
    </>
  )
}
