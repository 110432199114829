import { Field, FormikProps, useFormikContext } from 'formik'
import React from 'react'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { B5_FORM_INITIAL_VALUES } from './constants'
import RadioInput from 'src/components/inputs/RadioInput'

interface Props { }

export const B5TransportSolutionForm: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<typeof B5_FORM_INITIAL_VALUES>
  return (
    <>
      <FormSectionTwoColumns sectionKey="b5.transportSolution" title="Popis dopravního řešení, napojení území na stávající dopravní infrastrukturu, přeložky, včetně pěších a cyklistických stezek, doprava v klidu, řešení
přístupnosti a bezbariérového užívání">
        <div className="sm:col-span-6">
          <div className="mt-3">
            <span className='text-sm font-bold'>Řešeno projektem?</span>
            <Field
              name="b5.transportSolution.enabled"
              component={RadioInput}
              options={[
                { label: 'Ano', value: true },
                { label: 'Ne', value: false },
              ]}
            />
          </div>
          {formik.values.b5.transportSolution.enabled && (
            <>
              <div className="mt-6">
                <span className='text-sm font-bold'>Popis</span>
                <Field
                  name="b5.transportSolution.description"
                  component={RichTextInput}
                />
              </div>
              <div className="mt-6">
                <span className='text-sm font-bold'>Napojení na stávající dopravní infrastrukturu</span>
                <Field
                  name="b5.transportSolution.connectionToTraffic"
                  component={RichTextInput}
                />
              </div>
              <div className="sm:col-span-6 mt-6">
                <Field
                  name="b5.transportSolution.reroutes.enabled"
                  component={CheckboxInput}
                  label="Přeložky"
                />
                {formik.values.b5.transportSolution.reroutes.enabled && (

                  <div className="pl-7 mt-3">
                    <Field
                      name="b5.transportSolution.reroutes.description"
                      component={RichTextInput}
                    />
                  </div>

                )}
              </div>
              <div className="sm:col-span-6 mt-6">
                <Field
                  name="b5.transportSolution.bikeTracks.enabled"
                  component={CheckboxInput}
                  label="Pěší a cyklistické stezky"
                />
                {formik.values.b5.transportSolution.bikeTracks.enabled && (

                  <div className="pl-7 mt-3">
                    <Field
                      name="b5.transportSolution.bikeTracks.description"
                      component={RichTextInput}
                    />
                  </div>

                )}
              </div>
              <div className="sm:col-span-6 mt-6">
                <Field
                  name="b5.transportSolution.staticTraffic.enabled"
                  component={CheckboxInput}
                  label="Doprava v klidu"
                />
                {formik.values.b5.transportSolution.staticTraffic.enabled && (

                  <div className="pl-7 mt-3">
                    <Field
                      name="b5.transportSolution.staticTraffic.description"
                      component={RichTextInput}
                    />
                  </div>

                )}
              </div>
              <div className="sm:col-span-6 mt-6">
                <Field
                  name="b5.transportSolution.accessibilitySolution.enabled"
                  component={CheckboxInput}
                  label="Řešení přístupnosti"
                />
                {formik.values.b5.transportSolution.accessibilitySolution.enabled && (

                  <div className="pl-7 mt-3">
                    <Field
                      name="b5.transportSolution.accessibilitySolution.description"
                      component={RichTextInput}
                    />
                  </div>

                )}
              </div>
              <div className="sm:col-span-6 mt-6">
                <Field
                  name="b5.transportSolution.obstacleFreeAccess.enabled"
                  component={CheckboxInput}
                  label="Bezbariérové užívaní"
                />
                {formik.values.b5.transportSolution.obstacleFreeAccess.enabled && (

                  <div className="pl-7 mt-3">
                    <Field
                      name="b5.transportSolution.obstacleFreeAccess.description"
                      component={RichTextInput}
                    />
                  </div>

                )}
              </div>
            </>
          )}
        </div>
      </FormSectionTwoColumns>
    </>
  )
}
