import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import RadioInput from '../../../../../components/inputs/RadioInput'
import { B1_INITIAL_VALUES } from 'shared'
import { DatePickerInput } from '../../../../../components/inputs/DatePickerInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Av2: React.FC<Props> = ({ formik }) => {
  const values = formik.values as typeof B1_INITIAL_VALUES
  return (
    <>
      <FormSectionTwoColumns
        title="a) základní popis stavby"
        subtitle="u změny stavby údaje o jejím současném stavu, závěry stavebně technického, případně stavebně historického průzkumu a výsledky statického posouzení nosných konstrukcí"
        sectionKey="b1a"
      >
        <>
          <div className="sm:col-span-6">
            <Field
              label="Nova stavba nebo zmena uzivani stavby"
              name="b1a.newOrChange"
              component={RadioInput}
              options={[
                {
                  label: 'Nová stavba',
                  value: 'new',
                },
                {
                  label: 'Změna dokončené stavby',
                  value: 'change',
                },
              ]}
            />
            {values.b1a.newOrChange === 'new' && (
              <div className="pl-7 sm:col-span-6 mt-3">
                <Field
                  name="b1a.buildingDescription"
                  label="Popis stavby"
                  component={RichTextInput}
                />
              </div>
            )}
            {values.b1a.newOrChange === 'change' && (
              <div className="pl-7">
                <div className="sm:col-span-6 mt-3">
                  <Field name="b1a.currentState" label="Soucasny stav" component={RichTextInput} />
                </div>
                <div className="sm:col-span-6 mt-3">
                  <Field
                    name="b1a.technicalState"
                    label="Stavebně technický / stavebně historický průzkum"
                    component={RichTextInput}
                  />
                </div>
                <div className="sm:col-span-6 mt-3">
                  <Field
                    name="b1a.bearingConstructionsCheck"
                    label="Výsledky statické posouzení nosných konstrukcí"
                    component={RichTextInput}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="sm:col-span-6">
            <Field
              name="b1a.temporaryOrPermanent"
              component={RadioInput}
              label="Trvalá nebo dočasná stavba"
              options={[
                {
                  label: 'Trvalá stavba',
                  value: 'permanent',
                },
                {
                  label: 'Dočasná stavba',
                  value: 'temporary',
                },
              ]}
            />
            {values.b1a.temporaryOrPermanent === 'temporary' && (
              <div className="sm:col-span-6 flex gap-3 mt-3">
                <Field
                  component={DatePickerInput}
                  name="b1a.temporaryStartDate"
                  selectsStart
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  placeholderText="Od"
                />
                <Field
                  component={DatePickerInput}
                  name="b1a.temporaryEndDate"
                  selectsEnd
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  placeholderText="Do"
                />
              </div>
            )}
          </div>
        </>
      </FormSectionTwoColumns>
    </>
  )
}
