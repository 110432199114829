import React from 'react'
import { Field, FormikProps } from 'formik'
import { ACCOMPANYING_REPORT_FORM_INITIAL_DATA } from './constants'
import { TextInput } from '../../../../components/inputs/TextInput'
import { DatePickerInput } from '../../../../components/inputs/DatePickerInput'
import {FormLabel} from "../../../../components/FormLabel";

interface Props {
  formik: FormikProps<typeof ACCOMPANYING_REPORT_FORM_INITIAL_DATA>
}

export const AccompanyingReportA4: React.FC<Props> = (props) => {
  return (
    <>
      <h2 id="a4Heading" className="font-semibold text-lg mt-8">
        A.4 Atributy stavby pro stanovení podmínek napojení a provádění činností v ochranných a
        bezpečnostních pásmech dopravní a technické infrastruktury
      </h2>
      <div className="mt-3">
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-3">
          <Field
            name="a4.depthOfConstruction"
            component={TextInput}
            label="a) Hloubka stavby"
            trailing={'m'}
          />
          <Field
            name="a4.heightOfConstruction"
            component={TextInput}
            label="b) Výška stavby"
            trailing={'m'}
          />
          <Field
            name="a4.numberOfPeople"
            component={TextInput}
            label="c) předpokládaná kapacita počtu osob ve stavbě"
            wrapperClassname="col-span-2"
          />
        </div>
        <div className="mt-3 flex flex-col gap-3">
          <FormLabel>
            d) Plánovaný začátek a konec realizace stavby
          </FormLabel>
          <div className="flex gap-3">
            <Field
              component={DatePickerInput}
              name="a4.plannedStartDate"
              placeholderText="Plánovaný začátek"
              label="Plánovaný začátek"
            />
            <Field
              component={DatePickerInput}
              name="a4.plannedEndDate"
              placeholderText="Plánovaný konec"
              label="Plánovaný konec"
            />
          </div>
        </div>
      </div>
    </>
  )
}
