import React, { useState } from 'react'
import { FieldProps } from 'formik'
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../model/helpers/classNameHelpers'
import { useDebounce } from '@uidotdev/usehooks'
import { useSearchMunicipality } from '../../model/api/municipality/useSearchMunicipality'
import { ISelectOption } from '../../model/interfaces/ISelectOption'
import { useGetMunicipality } from '../../model/api/municipality/useGetMunicipality'
import { municipalityToLabel } from '../../model/helpers/municipalityHelpers'
import { Spinner } from '@chakra-ui/react'

interface Props extends FieldProps {
  label?: string
}

export const MunicipalitySearchInput: React.FC<Props> = (props) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)

  const { data: municipalities, isLoading, isRefetching } = useSearchMunicipality(debouncedQuery)
  const { data: selectedMunicipality } = useGetMunicipality(props.field.value)

  const filteredOptions: ISelectOption[] = (municipalities?.data || [])
    .filter((m) => !!m.kuKod)
    .map((municipality) => ({
      label: municipalityToLabel(municipality),
      value: municipality.kuKod,
    }))
  const selectedOption = props.field.value ? selectedMunicipality?.data : undefined

  return (
    <Combobox
      as="div"
      value={props.field.value}
      onChange={(value) => props.form.setFieldValue(props.field.name, value)}
    >
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        {props.label} {isLoading || isRefetching ? <Spinner size="xs" /> : null}
      </Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          onChange={(e) => setQuery(e.currentTarget.value)}
          displayValue={() => (selectedOption ? municipalityToLabel(selectedOption) : 'Nenalezeno')}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredOptions.length > 0 && (
          <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={option.value}
                value={option.value}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9 rounded m-1',
                    active ? 'bg-blue-600 text-white' : 'text-gray-900',
                  )
                }
              >
                {({ active }) => {
                  const selected = false
                  return (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>
                        {option.label}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-blue-600',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )
                }}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
