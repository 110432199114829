import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { DropzoneInput } from '../../../../../components/inputs/DropzoneInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Fv2: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1f'}
      title="f) stávající ochrana území a stavby podle jiných právních předpisů"
      subtitle="včetně rozsahu omezení a podmínek pro ochranu"
    >
      <div className="sm:col-span-6 mt-5">
        <Field
          component={CheckboxInput}
          name="b1f.territoryProtection.enabled"
          label="Ochrana území a stavby podle jiných právních předpisů"
        />
      </div>
      {formik.values.b1f?.territoryProtection?.enabled && (
        <>
          <div className="sm:col-span-6 flex flex-col gap-3">
            <Field
              component={RichTextInput}
              name="b1f.territoryProtection.description"
              label="Popis stávající ochrany"
            />
            <Field
              component={RichTextInput}
              name="b1f.territoryProtection.scale"
              label="Rozsahy omezení"
            />
            <Field
              component={RichTextInput}
              name="b1f.territoryProtection.conclusion"
              label="Podmínky pro ochranu"
            />
          </div>
          <div className="sm:col-span-6">
            <Field
              name="b1f.territoryProtection.files"
              component={DropzoneInput}
              label="Dokumenty"
              fileLabel={`B1F - Chrana území podle jiných právních předpisů`}
            />
          </div>
        </>
      )}
    </FormSectionTwoColumns>
  )
}
