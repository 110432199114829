import React from 'react'
import { FieldProps } from 'formik'
import { cn } from '../../lib/utils'

interface Props extends FieldProps {
  label: string | React.ReactNode
  comment?: string
  className?: string
}

export const CheckboxInput: React.FC<Props> = (props) => {
  return (
    <div className={cn('relative flex items-start', props.className)}>
      <div className="flex h-6 items-center">
        <input
          id={props.field.name}
          aria-describedby={`${props.label}-description`}
          name={props.field.name}
          type="checkbox"
          checked={props.field.value}
          onChange={(e) => props.form.setFieldValue(props.field.name, e.target.checked)}
          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={props.field.name} className="font-medium text-gray-900">
          {props.label}
        </label>
        {props.comment && (
          <p id={`${props.field.name}-description`} className="text-gray-500">
            {props.comment}
          </p>
        )}
      </div>
    </div>
  )
}
