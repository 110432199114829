import React from 'react'
import { B31TechnicalSolutionConcept } from './sections/B31TechnicalSolutionConcept'
import { B32OverallAccessibilityConditions } from './sections/B32OverallAccessibilityConditions'
import { B33SafetyPrinciples } from './sections/B33SafetyPrinciples'
import { B34BasicTechnicalDescription } from './sections/B34BasicTechnicalDescription'
import { B35TechnicalDevices } from './sections/B35TechnicalDevices'
import { B37EnergyConservation } from './sections/B37EnergyConservation'
import { B38HygienicRequirements } from './sections/B38HygienicRequirements'
import { B39ProtectionAgainstEnvironmentalFactors } from './sections/B39ProtectionAgainstEnvironmentalFactors'
import { B36FireSafety } from './sections/B36FireSafety'

interface Props { }

export const B3TechnicalConnectionsFormV2: React.FC<Props> = (props) => {
  return (
    <>
      <B31TechnicalSolutionConcept />
      <B32OverallAccessibilityConditions />
      <B33SafetyPrinciples />
      <B34BasicTechnicalDescription />
      <B35TechnicalDevices />
      <B36FireSafety />
      <B37EnergyConservation />
      <B38HygienicRequirements />
      <B39ProtectionAgainstEnvironmentalFactors />
    </>
  )
}
