import React from 'react'
import { FormikProps, useFormikContext } from 'formik'

interface Props {
  ctrlD?: () => void
  ctrlE?: () => void
}

export const FormKeyboardShortcuts: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<any>

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault()
        formik.submitForm()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [formik])

  return null
}
