import { Field, FormikProps, useFormikContext } from 'formik'
import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../../components/inputs/CheckboxInput'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'
import { B3_FORM_INITIAL_VALUES } from '../constants'

interface Props { }

export const B38HygienicRequirements: React.FC<Props> = (props) => {
    const formik = useFormikContext() as FormikProps<typeof B3_FORM_INITIAL_VALUES>
    return (
        <>
            <FormSectionTwoColumns sectionKey="b3.hygienicRequirements" title="B.3.8 Hygienické požadavky na stavbu, požadavky na pracovní a komunální prostředí">
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.hygienicRequirements.ventilation.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.hygienicRequirements.ventilation.label}
                    />
                    {formik.values.b3.hygienicRequirements.ventilation.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.hygienicRequirements.ventilation.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.hygienicRequirements.lighting.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.hygienicRequirements.lighting.label}
                    />
                    {formik.values.b3.hygienicRequirements.lighting.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.hygienicRequirements.lighting.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.hygienicRequirements.sunlight.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.hygienicRequirements.sunlight.label}
                    />
                    {formik.values.b3.hygienicRequirements.sunlight.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.hygienicRequirements.sunlight.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.hygienicRequirements.shading.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.hygienicRequirements.shading.label}
                    />
                    {formik.values.b3.hygienicRequirements.shading.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.hygienicRequirements.shading.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.hygienicRequirements.waterSupply.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.hygienicRequirements.waterSupply.label}
                    />
                    {formik.values.b3.hygienicRequirements.waterSupply.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.hygienicRequirements.waterSupply.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.hygienicRequirements.noiseProtection.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.hygienicRequirements.noiseProtection.label}
                    />
                    {formik.values.b3.hygienicRequirements.noiseProtection.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.hygienicRequirements.noiseProtection.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.hygienicRequirements.sewage.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.hygienicRequirements.sewage.label}
                    />
                    {formik.values.b3.hygienicRequirements.sewage.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.hygienicRequirements.sewage.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.hygienicRequirements.other.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.hygienicRequirements.other.label}
                    />
                    {formik.values.b3.hygienicRequirements.other.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.hygienicRequirements.other.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
            </FormSectionTwoColumns>
        </>
    )
}
