import { Field, FormikProps, useFormikContext } from 'formik'
import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import RadioInput from '../../../../../../components/inputs/RadioInput'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'
import { B3_FORM_INITIAL_VALUES } from '../constants'

interface Props { }

export const B32OverallAccessibilityConditions: React.FC<Props> = () => {
    const formik = useFormikContext() as FormikProps<typeof B3_FORM_INITIAL_VALUES>
    return (
        <FormSectionTwoColumns
            sectionKey="b3.overallAccessibilityConditions"
            title="B.3.2 Celkové řešení podmínek přístupnosti"
        >
            <div className="sm:col-span-6 p-5 rounded-md">
                <Field
                    label="a) celkové řešení přístupnosti se specifikací jednotlivých části, které podléhají požadavkům na přístupnost, včetně dopadů předčasného užívání a
zkušebního provozu a vlivu na okolí"
                    name="b3.overallAccessibilityConditions.individualParts.enabled"
                    component={RadioInput}
                    options={[
                        {
                            label: 'Ano',
                            value: true,
                        },
                        {
                            label: 'Ne',
                            value: false,
                        },
                    ]}
                />
                {formik.values.b3.overallAccessibilityConditions.individualParts.enabled === true && (
                    <div className="pl-7">
                        <div className="sm:col-span-6 mt-3">
                            <Field
                                name="b3.overallAccessibilityConditions.individualParts.description"
                                label=""
                                component={RichTextInput}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="sm:col-span-6 p-5 rounded-md">
                <Field
                    label="b) popis navržených opatření - zejména přístup ke stavbě, prostory stavby a systémy určené pro užívání veřejností"
                    name="b3.overallAccessibilityConditions.proposedPrecautionsDescription.enabled"
                    component={RadioInput}
                    options={[
                        {
                            label: 'Ano',
                            value: true,
                        },
                        {
                            label: 'Ne',
                            value: false,
                        },
                    ]}
                />
                {formik.values.b3.overallAccessibilityConditions.proposedPrecautionsDescription.enabled === true && (
                    <div className="pl-7">
                        <div className="sm:col-span-6 mt-3">
                            <Field
                                name="b3.overallAccessibilityConditions.proposedPrecautionsDescription.description"
                                label=""
                                component={RichTextInput}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="sm:col-span-6 p-5 rounded-md">
                <Field
                    label="c) popis dopadů na přístupnost z hlediska uplatnění závažných územně technických nebo stavebně technických důvodů nebo jiných veřejných
zájmů."
                    name="b3.overallAccessibilityConditions.accessibilityImpactDescription.enabled"
                    component={RadioInput}
                    options={[
                        {
                            label: 'Ano',
                            value: true,
                        },
                        {
                            label: 'Ne',
                            value: false,
                        },
                    ]}
                />
                {formik.values.b3.overallAccessibilityConditions.accessibilityImpactDescription.enabled === true && (
                    <div className="pl-7">
                        <div className="sm:col-span-6 mt-3">
                            <Field
                                name="b3.overallAccessibilityConditions.accessibilityImpactDescription.description"
                                label=""
                                component={RichTextInput}
                            />
                        </div>
                    </div>
                )}
            </div>
        </FormSectionTwoColumns>
    )
}
