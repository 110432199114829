import React from 'react'
import { useProject } from '../../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { ProjectLayout } from '../../../layout/ProjectLayout'
import { Button } from '../../../../../components/ui/button'
import { ChevronLeft } from 'lucide-react'
import { Field, Form, Formik } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { ProjectFormIdentityContext } from '../../../../../model/context/ProjectFormIdentityContext'
import { ProjectFormEnum } from 'shared'
import { MultiImageDropzoneInput } from '../../../../../components/MultiImageDropzoneInput'
import { DropzoneInput } from '../../../../../components/inputs/DropzoneInput'
import { Link, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { dateFormatForUrl } from '../../../../../model/constants/dateConstants'
import { getDayOfWeek } from '../../../../../model/helpers/dateHelpers'
import { FolderKeyEnum } from '../../../../../model/enums/FolderKeyEnum'
import { RoutesEnum } from '../../../../../model/enums/RoutesEnum'
import { useCreateUpdateConstructionDiaryEntryMutation } from '../../../../../model/api/constructionDiary/useCreateUpdateConstructionDiaryEntryMutation'
import { useGetConstructionDiaryEntry } from '../../../../../model/api/constructionDiary/useGetConstructionDiaryEntry'
import { IDocumentEntity } from '../../../../../model/interfaces/IDocumentEntity'
import { mergeInitialValues } from '../../../../../model/helpers/formHelpers'
import { CONSTRUCTION_DIARY_ENTRY_INITIAL_DATA } from '../constants'
import { WorkDoneArray } from './WorkDoneArray'
import { WeatherCard } from '../../../../../components/WeatherCard'
import { SignPadInput } from '../../../../../components/inputs/SignPadInput'
import { SignaturesArray } from './SignaturesArray'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { TailwindModal } from '../../../../../components/TailwindModal'
import { clone, cloneDeep } from 'lodash'
import { AutoSave } from '../../../../../components/forms/AutoSave'
import { TaskGridDrawer } from '../../../../Tasks/components/TaskGridDrawer'
import { IsDisabledContext } from 'src/model/context/IsDisabledContext'

interface Props {}

export const ConstructionDiaryEntry: React.FC<Props> = (props) => {
  const toast = useToast()
  const signAndSaveDisclosure = useDisclosure()
  const navigate = useNavigate()
  const { project, id: projectId } = useProject()
  const { date: dateParam } = useParams<{ date: string }>()
  if (!dateParam) {
    throw new Error('Date is required')
  }

  const date = moment(dateParam, dateFormatForUrl)
  const { mutateAsync } = useCreateUpdateConstructionDiaryEntryMutation()
  const { data, isLoading, isRefetching } = useGetConstructionDiaryEntry(projectId, dateParam)

  if (!project || isLoading || data?.data?.date !== dateParam) {
    return <ProjectLoadingSkeleton />
  }

  const initialValues = mergeInitialValues<any>(CONSTRUCTION_DIARY_ENTRY_INITIAL_DATA, data?.data)

  return (
    <ProjectLayout project={project}>
      <div>
        <div className="flex flex-wrap justify-between items-center gap-6 sm:flex-nowrap mb-5">
          <div className="flex items-baseline gap-3">
            <Link to={`${RoutesEnum.PROJECTS}/${project.id}/${RoutesEnum.CONSTRUCTION_DIARY}`}>
              <Button variant="outline">
                <ChevronLeft size={16} />
              </Button>
            </Link>
            <h1 className="text-base font-semibold leading-7 text-gray-900">
              Záznam stavebního deníku - {getDayOfWeek(date.day())} {date.format('DD.MM.YYYY')}
            </h1>
          </div>
          <div className="flex justify-end">
            <TaskGridDrawer
              taskQuery={{ projectId }}
              subtitle="Úkoly vázané na tento stavební deník"
            />
          </div>
        </div>
        <div className="mt-5">
          <ProjectFormIdentityContext.Provider
            value={{ projectId: project.id, formEnum: ProjectFormEnum.CONSTRUCTION_DIARY_FORM }}
          >
            <IsDisabledContext.Provider value={false}>
              <Formik
                initialValues={{ ...initialValues }}
                onSubmit={async (values: any) => {
                  const submitter = values.submitter
                  // Convert values to form-data
                  const formData = new FormData()
                  formData.append('description', values.description)
                  values.photos?.forEach((photo: File | IDocumentEntity, photoIndex: number) => {
                    if (photo instanceof File) {
                      formData.append(`photos[${photoIndex}]`, photo)
                    }
                  })
                  formData.append('date', dateParam!)
                  formData.append('projectId', project.id)
                  if (values.workers) {
                    formData.append('workers', values.workers)
                  }

                  if (values.workDone) {
                    formData.append('workDone', JSON.stringify(values.workDone))
                  }
                  if (values.materials) {
                    formData.append('materials', values.materials)
                  }
                  if (values.mechanization) {
                    formData.append('mechanization', values.mechanization)
                  }
                  if (values.dustPrevention) {
                    formData.append('dustPrevention', values.dustPrevention)
                  }
                  if (values.accessibility) {
                    formData.append('accessibility', values.accessibility)
                  }

                  formData.append('signatures', JSON.stringify(values.signatures))

                  if (submitter === 'signature') {
                    formData.append('signature', values.signature)
                  }

                  await mutateAsync(formData)

                  if (submitter === 'signature') {
                    toast({
                      title: 'Záznam byl úspěšně uložen a podepsán',
                      status: 'success',
                      duration: 3000,
                      isClosable: true,
                    })
                    navigate(`${RoutesEnum.PROJECTS}/${project.id}/${RoutesEnum.CONSTRUCTION_DIARY}`)
                    return
                  }

                  if (submitter === 'auto-save') {
                    return
                  }

                  toast({
                    title: 'Záznam byl úspěšně uložen',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                  })
                }}
              >
                {(formik) => {
                  console.log({ values: formik.values })
                  return (
                    <Form>
                      <TailwindModal
                        disclosure={signAndSaveDisclosure}
                        dialogTitle="Uložit & Podepsat"
                      >
                        <Field name="signature" component={SignPadInput} />
                        <div className="mt-5">
                          <Button
                            onClick={async () => {
                              await formik.setFieldValue('submitter', 'signature')
                              return formik.submitForm()
                            }}
                            disabled={!formik.values.signature}
                            type="button"
                          >
                            Podepsat & Uložit
                          </Button>
                        </div>
                      </TailwindModal>
                      {data?.data?.weather && <WeatherCard weather={data.data.weather} />}

                      <div className="mt-5">
                        <Field
                          name="workers"
                          label="Jména a příjmení osob pracujících na staveništi"
                          component={RichTextInput}
                        />
                      </div>
                      <WorkDoneArray />
                      <div className="mt-5">
                        <Field
                          name="materials"
                          component={RichTextInput}
                          label="Dodávky materiálů, výrobků, strojů a zařízení pro stavbu, jejich uskladnění a zabudování"
                        />
                      </div>
                      <div className="mt-5">
                        <Field
                          name="mechanization"
                          component={RichTextInput}
                          label="Nasazení mechanizačních prostředků"
                        />
                      </div>
                      <div className="mt-5">
                        <Field
                          name="dustPrevention"
                          component={RichTextInput}
                          label="Přijatá opatření proti prašnosti"
                        />
                      </div>
                      <div className="mt-5">
                        <Field
                          name="accessibility"
                          component={RichTextInput}
                          label="Přijatá opatření k zajištění přístupnosti."
                        />
                      </div>
                      <div className="grid grid-cols-1 xl:grid-cols-2 gap-3">
                        <div>
                          <Field
                            name="photos"
                            label="Fotky"
                            component={MultiImageDropzoneInput}
                            accept={{ images: ['image/*'] }}
                          />
                        </div>
                        <div className="mt-3 sm:mt-0">
                          <Field
                            name="files"
                            component={DropzoneInput}
                            label="Soubory"
                            folderKey={`${FolderKeyEnum.CONSTRUCTION_DIARY}-${dateParam}`}
                          />
                        </div>
                      </div>
                      <div>
                        <SignaturesArray name="signatures" />
                      </div>
                      <div className="mt-8 flex justify-end gap-3">
                        <Button
                          isLoading={formik.isSubmitting}
                          variant="secondary"
                          type="button"
                          onClick={async () => {
                            await formik.setFieldValue('submitter', 'save')
                            return formik.submitForm()
                          }}
                        >
                          Uložit
                        </Button>
                        <Button
                          isLoading={formik.isSubmitting}
                          type="button"
                          onClick={signAndSaveDisclosure.onOpen}
                        >
                          Podepsat & Uložit
                        </Button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </IsDisabledContext.Provider>
          </ProjectFormIdentityContext.Provider>
        </div>
      </div>
    </ProjectLayout>
  )
}
