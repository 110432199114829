import { Field } from 'formik'
import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'

interface Props { }

export const B37EnergyConservation: React.FC<Props> = () => {
    return (
        <FormSectionTwoColumns sectionKey='b3.energyConservation' title="B.3.7 Úspora energie a tepelná ochrana budovy">
            <div className="sm:col-span-6 mt-5">
                <div className="mt-3 pl-7">
                    <Field name={`b3.energyConservation.description`} component={RichTextInput} />
                </div>
            </div>
        </FormSectionTwoColumns>
    )
}
