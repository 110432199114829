import React from 'react'
import { Field, useFormik, useFormikContext } from 'formik'
import { FormLabel } from '../../../../../components/FormLabel'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { Button } from '../../../../../components/ui/button'
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/20/solid'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { generateRandomString } from 'shared'
import moment from 'moment'
import { SelectInput } from '../../../../../components/inputs/SelectInput'
import { TIMES_FOR_PICKER_SELECT } from '../../../../../model/constants/dateConstants'
import { SignaturesArray } from './SignaturesArray'
import { Simulate } from 'react-dom/test-utils'
import ended = Simulate.ended

interface Props {}

export const WorkDoneArray: React.FC<Props> = (props) => {
  const formik = useFormikContext<any>()
  const workDone: Array<any> = (formik.values?.workDone ?? []).sort((a: any, b: any) => {
    return moment(a.time.value, 'HH:mm').diff(moment(b.time.value, 'HH:mm'))
  })

  const onRemove = (id: string) => {
    if (window.confirm('Opravdu chcete smazat tento záznam?')) {
      formik.setFieldValue(
        'workDone',
        formik.values.workDone.filter((workDone: any) => workDone.id !== id),
      )
    }
  }

  const onAdd = () => {
    const allTimes = TIMES_FOR_PICKER_SELECT
    const currentTime = moment().format('HH:mm')

    const closestTime = allTimes.reduce((a, b) => {
      return Math.abs(moment(b, 'HH:mm').diff(moment(currentTime, 'HH:mm'))) <
        Math.abs(moment(a, 'HH:mm').diff(moment(currentTime, 'HH:mm')))
        ? b
        : a
    })

    formik.setFieldValue('workDone', [
      ...formik.values.workDone,
      {
        id: generateRandomString(12),
        time: { value: closestTime },
        description: '',
      },
    ])
  }

  return (
    <div className="mt-5 mb-8">
      <FormLabel>Popis a množství provedených prací a montáží a jejich časový postup</FormLabel>
      {workDone.map((workDone: any, index: number) => {
        const disabled = workDone?.signatures?.length > 0

        return (
          <div className="my-2 flex flex-col gap-3 p-3 rounded-md border-dotted border-2">
            <div className="flex justify-between">
              <div className="max-w-[100px]">
                <Field
                  name={`workDone[${index}].time`}
                  component={SelectInput}
                  label="Čas"
                  forbidCustom={true}
                  options={TIMES_FOR_PICKER_SELECT.map((s) => ({
                    value: s,
                    label: s,
                  }))}
                  disabled={disabled}
                />
              </div>
              <Button onClick={() => onRemove(workDone.id)} variant="ghost" type="button">
                <TrashIcon className="h-6 text-red-600" />
              </Button>
            </div>
            <div>
              <Field
                label="Popis"
                name={`workDone[${index}].description`}
                component={RichTextInput}
                favoriteKey="workDoneDescription"
                disabled={disabled}
              />
            </div>
            <div>
              <SignaturesArray
                onAddSignAlert="Při podpisu se uzamkne záznam. Opravdu chcete přidat podpis?"
                label="Podpisy"
                name={`workDone[${index}].signatures`}
                buttonLabel="Přidat podpis"
              />
            </div>
            {disabled && (
              <div className="flex justify-end">
                <Button
                  variant="secondary"
                  onClick={() => {
                    if (
                      window.confirm(
                        'Odemknutím se vymažou všechny podpisy. Opravdu chcete odemknout?',
                      )
                    ) {
                      formik.setFieldValue(`workDone[${index}].signatures`, [])
                    }
                  }}
                >
                  Odemknout k úpravě
                </Button>
              </div>
            )}
          </div>
        )
      })}
      <div className="w-full mt-3">
        <Button onClick={onAdd} type="button" variant="outline" className="w-full">
          <PlusCircleIcon className="h-6 text-blue-600 mr-2" />
          Přidat popis práce
        </Button>
      </div>
    </div>
  )
}
