import { Field } from 'formik'
import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'

interface Props { }

export const B2SpacialAndArchitecturalSolutions: React.FC<Props> = () => {
  return (
    <FormSectionTwoColumns
      sectionKey="b2.characteristics"
      title=""
    >
      <div className="sm:col-span-6 flex flex-col gap-3">
        <Field
          name="b2.characteristics.compositionSolution"
          label="Kompozice prostorového řešení"
          component={RichTextInput}
        />
        <Field
          name="b2.characteristics.architecturalSolution"
          label="Základní architektonické řešení"
          component={RichTextInput}
        />
      </div>
    </FormSectionTwoColumns>
  )
}
