import { useQuery } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useGetParcels = (projectId: string) => {
  return useQuery({
    queryFn: () => api.get(`${ApiUrlEnum.PROJECT}/${projectId}/parcel`),
    queryKey: [ApiUrlEnum.PROJECT, projectId, 'parcel'],
  })
}
