import React, { useContext } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { FieldProps } from 'formik'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { Button } from '../../Button'
import { SecondaryButton } from '../../SecondaryButton'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { InfoPopover } from '../../InfoPopover'
import { FormLabel } from '../../FormLabel'
import { RichTextInputMenu } from './RichTextInputMenu'
import { ProjectFormIdentityContext } from '../../../model/context/ProjectFormIdentityContext'
import { IsDisabledContext } from '../../../model/context/IsDisabledContext'

interface Props extends FieldProps {
  label?: string
  large?: string
  disableFullscreen?: boolean
  help?: string | React.ReactNode
  fullscreen?: boolean
  noMenu?: boolean
  favoriteKey?: string
  disabled?: boolean
}

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  ['image'],

  ['clean'], // remove formatting button
]

const toolbarOptionsMinimalistic = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['image'],
]

export const RichTextInput: React.FC<Props> = (props) => {
  const disabledContext = useContext(IsDisabledContext)
  const isDisabled = props.disabled || disabledContext
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false })
  const onChange = (value: string) => {
    props.field.onChange({
      target: {
        value,
        name: props.field.name,
      },
    })
  }

  if (isOpen) {
    return (
      <>
        <Modal isOpen={true} onClose={onClose} size="4xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{props.label}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="modal-quill-container">
                <ReactQuill
                  theme="snow"
                  value={props.field.value}
                  onChange={onChange}
                  modules={{
                    toolbar: toolbarOptions,
                  }}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Potvrdit</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <>
      <FormLabel>
        <div className="flex justify-between">
          <div>{props.label}</div>
          <div>
            {props.noMenu !== true && !isDisabled && (
              <RichTextInputMenu
                favoriteKey={props.favoriteKey}
                field={props.field}
                form={props.form}
              />
            )}
          </div>
        </div>
      </FormLabel>
      {props.help && <InfoPopover content={props.help} />}
      {isDisabled ? (
        <p
          dangerouslySetInnerHTML={{ __html: props.field.value }}
          className="nestedImagesStyle text-sm"
        />
      ) : (
        <div
          className={`mt-1 nestedImagesStyle ${
            props.large
              ? 'modal-quill-container'
              : props.fullscreen
              ? 'fullscreen-quill-container'
              : 'base-quill-container'
          }`}
        >
          <ReactQuill
            theme="snow"
            value={props.field.value}
            onChange={onChange}
            modules={{
              toolbar: toolbarOptionsMinimalistic,
            }}
          />
          {!props.disableFullscreen && (
            <div className="w-full flex flex-row justify-end items-center mt-1">
              <SecondaryButton onClick={onOpen} className="w-10 h-10">
                <ArrowTopRightOnSquareIcon />
              </SecondaryButton>
            </div>
          )}
        </div>
      )}
    </>
  )
}
