import { useQuery } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { ProjectFormEnum } from '../../enums/ProjectFormEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { TechnicalFormStatusEnum } from 'shared'

export const useGetProjectTechnicalForm = (form: ProjectFormEnum, id: string) => {
  return useQuery(
    [QueryKeyEnum.PROJECT_FORMS, id, form],
    () =>
      api.get<{ data?: any; status?: TechnicalFormStatusEnum; preview?: string; id: string }>(
        `${ApiUrlEnum.PROJECT_FORM}/${id}/${form}`,
      ),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  )
}
