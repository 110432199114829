import React from 'react'
import { AlignJustifyIcon } from 'lucide-react'
import { SemiTransparentCommandPalette } from './SemiTransparentCommandPalette'
import { useDisclosure } from '@chakra-ui/react'
import { Tooltip, TooltipContent } from './ui/tooltip'
import { TooltipTrigger } from '@radix-ui/react-tooltip'
import { Badge } from './ui/badge'

interface Props {
  options: {
    label: string
    target: string
    className?: string
  }[]
  paddingTop?: number
}

export const QuickNavigationDropdown: React.FC<Props> = (props) => {
  const commandPaletteDisclosure = useDisclosure()
  const onItemClicked = (target: string) => {
    const element = document.getElementById(target)

    if (!element) {
      return
    }

    setTimeout(() => {
      const y = element.getBoundingClientRect().top + window.scrollY - (props.paddingTop ?? 75)
      window.scrollTo({ top: y, behavior: 'smooth' })
    }, 100)
  }

  return (
    <>
      <SemiTransparentCommandPalette
        options={props.options}
        onSelected={(option) => onItemClicked(option.target)}
        disclosure={commandPaletteDisclosure}
      />
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            onClick={commandPaletteDisclosure.onOpen}
            className="cursor-pointer rounded-full bg-gray-100/80 p-3 backdrop-blur border border-gray-300 hover:border-blue-600 hover:bg-blue-600 hover:text-white transition-colors ease-in-out duration-300"
          >
            <AlignJustifyIcon size={16} />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          Otevřít rychlou navigaci
          <Badge variant="secondary" className="ml-2">
            CTRL + /
          </Badge>
        </TooltipContent>
      </Tooltip>
    </>
  )
}
