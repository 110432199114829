import React from 'react'
import moment from 'moment/moment'
import { Button } from '../../../../components/ui/button'
import { TrashIcon } from '@heroicons/react/20/solid'
import { useDisclosure } from '@chakra-ui/react'
import { TailwindModal } from '../../../../components/TailwindModal'
import { ConstructionDiaryOtherEntryForm } from './ConstructionDiaryOtherEntryForm'
import { useDeleteConstructionDiaryOtherEntryMutation } from '../../../../model/api/constructionDiary/useDeleteConstructionDiaryOtherEntryMutation'

interface Props {
  entity: any
  projectId: string
}

export const ConstructionDiaryOtherEntry: React.FC<Props> = ({ entity, projectId }) => {
  const editFormDisclosure = useDisclosure()
  const { mutate: deleteEntity, isLoading: isDeleting } =
    useDeleteConstructionDiaryOtherEntryMutation()

  const onDelete = () => {
    if (window.confirm('Opravdu chcete smazat tento záznam?')) {
      deleteEntity({ id: entity.id })
    }
  }

  return (
    <>
      <TailwindModal disclosure={editFormDisclosure} dialogTitle="Upravit záznam">
        <ConstructionDiaryOtherEntryForm
          projectId={projectId}
          initValues={entity}
          onSuccess={editFormDisclosure.onClose}
        />
      </TailwindModal>
      <div key={entity.id} className="rounded-md shadow-md p-3 bg-gray-50 my-3">
        <div className="font-semibold text-gray-700">
          {moment(entity.date).format('DD. MM. YYYY')}
        </div>
        <div>
          <p
            className="text-gray-600 nestedImagesStyle"
            dangerouslySetInnerHTML={{ __html: entity.description }}
          />
        </div>
        <div className="flex justify-end gap-3 mt-3">
          <Button type="button" isLoading={isDeleting} onClick={onDelete} variant="secondary">
            <TrashIcon className="h-4 text-red-600" />
          </Button>
          <Button
            type="button"
            isLoading={isDeleting}
            onClick={editFormDisclosure.onOpen}
            variant="secondary"
          >
            Upravit
          </Button>
        </div>
      </div>
    </>
  )
}
