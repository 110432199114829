import React from 'react'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { Field, FormikProps, useFormikContext } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormLabel } from '../../../../../components/FormLabel'
import RadioInput from '../../../../../components/inputs/RadioInput'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { B2_FORM_INIT_VALUES } from '../B2UrbanisticSolution/constants'
import { B8_FORM_INITIAL_VALUES } from './constats'

interface Props { }

export const B8OrganizationForm: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<typeof B8_FORM_INITIAL_VALUES>

  return (
    <>
      <FormSectionTwoColumns title="a) potřeby a spotřeby rozhodujících médií a hmot, jejich zajištění">
        <div className="sm:col-span-6">
          <Field name="b8.consumption" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns title="b) odvodnění staveniště">
        <div className="sm:col-span-6">
          <Field name="b8.water" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns
        sectionKey="b8.connections"
        title="c) napojení staveniště na stávající dopravní a technickou infrastrukturu"
      >
        <div className="sm:col-span-6">
          <FormLabel>napojení na dopravní infrastrukturu</FormLabel>
          <div className="pl-7">
            <Field
              name="b8.connections.traffic"
              component={RadioInput}
              options={[
                { label: 'bude využíván stávající sjezd', value: 'current' },
                { label: 'bude zhotoven nový trvalý sjezd', value: 'new' },
                { label: 'bude zhotoven dočasný sjezd', value: 'newTemporary' },
                { label: 'vlastní', value: 'custom' },
              ]}
            />
            {formik.values.b8.connections.traffic === 'custom' && (
              <Field name="b8.connections.trafficCustom" component={RichTextInput} />
            )}
          </div>
        </div>
        <div className="sm:col-span-6">
          <FormLabel>napojení na technickou infrastrukturu</FormLabel>
          <div className="pl-7 mt-3">
            <Field
              name="b8.connections.water"
              component={CheckboxInput}
              label="Napojení na vodovod"
            />
            {formik.values.b8.connections.water && (
              <Field name="b8.connections.waterCustom" component={RichTextInput} />
            )}
            <Field
              name="b8.connections.drainage"
              component={CheckboxInput}
              label="Napojení na splaškovou kanalizaci"
            />
            {formik.values.b8.connections.drainage && (
              <Field name="b8.connections.drainageCustom" component={RichTextInput} />
            )}
            <Field
              name="b8.connections.electricity"
              component={CheckboxInput}
              label="Napojení na elektrickou energii"
            />
            {formik.values.b8.connections.electricity && (
              <Field name="b8.connections.electricityCustom" component={RichTextInput} />
            )}
            <div className="mt-3">
              <Field name="b8.connections.other" component={RichTextInput} label="Ostatní" />
            </div>
          </div>
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns title="d) vliv provádění stavby na okolní stavby a pozemky">
        <div className="sm:col-span-6">
          <Field name="b8.surroundingAreaImpact" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns title="e) ochrana okolí staveniště a požadavky na související asanace, demolice, kácení dřevin">
        <div className="sm:col-span-6">
          <Field name="b8.surroundingAreaProtection" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns title="f) maximální dočasné a trvalé zábory pro staveniště">
        <div className="sm:col-span-6">
          <Field name="b8.maxTemporaryArea" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns title="g) požadavky na bezbariérové obchozí trasy">
        <div className="sm:col-span-6">
          <Field name="b8.trailsProtection" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns title="h) maximální produkovaná množství a druhy odpadů a emisí při výstavbě, jejich likvidace">
        <div className="sm:col-span-6">
          <Field name="b8.maxEmissions" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns title="i) bilance zemních prací, požadavky na přísun nebo deponie zemin">
        <div className="sm:col-span-6">
          <Field name="b8.excavatedSoilSpreads" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns title="j) ochrana životního prostředí při výstavbě">
        <div className="sm:col-span-6">
          <Field name="b8.environmentProtection" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns title="k) zásady bezpečnosti a ochrany zdraví při práci na staveništi">
        <div className="sm:col-span-6">
          <Field name="b8.healthProtection" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns title="l) úpravy pro bezbariérové užívání výstavbou dotčených staveb">
        <div className="sm:col-span-6">
          <Field name="b8.barrierFreeProtection" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns title="m) zásady pro dopravní inženýrská opatření">
        <div className="sm:col-span-6">
          <Field name="b8.engineeringPracticesProtection" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns
        title="n) stanovení speciálních podmínek pro provádění stavby"
        subtitle="provádění stavby za provozu, opatření proti účinkům vnějšího prostředí při výstavbě apod."
      >
        <div className="sm:col-span-6">
          <Field name="b8.specialConditions" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns title="o) postup výstavby, rozhodující dílčí termíny">
        <div className="sm:col-span-6">
          <Field name="b8.buildingProcedure" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
    </>
  )
}
