export const EMISSIONS_CATEGORIES = [
  {
    code: '17 01',
    name: 'Beton, cihly, tašky a keramika',
    items: [
      { code: '17 01 01', name: 'Beton' },
      { code: '17 01 02', name: 'Cihly' },
      { code: '17 01 03', name: 'Tašky a keramické výrobky' },
      {
        code: '17 01 06',
        name: 'Směsi nebo oddělené frakce betonu, cihel, tašek a keramických výrobků obsahující nebezpečné látky',
      },
      {
        code: '17 01 07',
        name: 'Směsi nebo oddělené frakce betonu, cihel, tašek a keramických výrobků neuvedené pod číslem 17 01 06',
      },
    ],
  },
  {
    code: '17 02',
    name: 'Dřevo, sklo a plasty',
    items: [
      { code: '17 02 01', name: 'Dřevo' },
      { code: '17 02 02', name: 'Sklo' },
      { code: '17 02 03', name: 'Plasty' },
      {
        code: '17 02 04',
        name: 'Sklo, plasty a dřevo obsahující nebezpečné látky nebo nebezpečnými látkami znečištěné',
      },
    ],
  },
  {
    code: '17 03',
    name: 'Asfaltové směsi, dehet a výrobky z dehtu',
    items: [
      { code: '17 03 01', name: 'Asfaltové směsi obsahující dehet' },
      {
        code: '17 03 02',
        name: 'Asfaltové směsi neuvedené pod číslem 17 03 01',
      },
      { code: '17 03 03', name: 'Uhelný dehet a výrobky z dehtu' },
    ],
  },
  {
    code: '17 04',
    name: 'Kovy (včetně jejich slitin)',
    items: [
      { code: '17 04 01', name: 'Měď, bronz, mosaz' },
      { code: '17 04 02', name: 'Hliník' },
      { code: '17 04 03', name: 'Olovo' },
      { code: '17 04 04', name: 'Zinek' },
      { code: '17 04 05', name: 'Železo a ocel' },
      { code: '17 04 06', name: 'Cín' },
      { code: '17 04 07', name: 'Směsné kovy' },
      {
        code: '17 04 09',
        name: 'Kovový odpad znečištěný nebezpečnými látkami',
      },
      {
        code: '17 04 10',
        name: 'Kabely obsahující ropné látky, uhelný dehet a jiné nebezpečné látky',
      },
      {
        code: '17 04 11',
        name: 'Kabely neuvedené pod číslem 17 04 10',
      },
    ],
  },
  {
    code: '17 05',
    name: 'Zemina (včetně vytěžené zeminy z kontaminovaných míst), kamení, vytěžená jalová hornina a hlušina',
    items: [
      {
        code: '17 05 03',
        name: 'Zemina a kamení obsahující nebezpečné látky',
      },
      {
        code: '17 05 04',
        name: 'Zemina a kamení neuvedené pod číslem 17 05 03',
      },
      {
        code: '17 05 04 01',
        name: 'Sedimenty vytěžené z koryt vodních toků a vodních nádrží',
      },
      {
        code: '17 05 05',
        name: 'Vytěžená jalová hornina a hlušina obsahující nebezpečné látky',
      },
      {
        code: '17 05 06',
        name: 'Vytěžená jalová hornina a hlušina neuvedená pod číslem 17 05 05',
      },
      {
        code: '17 05 07',
        name: 'Štěrk ze železničního svršku obsahující nebezpečné látky',
      },
      {
        code: '17 05 08',
        name: 'Štěrk ze železničního svršku neuvedený pod číslem 17 05 07',
      },
    ],
  },
  {
    code: '17 06',
    name: 'Izolační materiály a stavební materiály s obsahem azbestu',
    items: [
      { code: '17 06 01', name: 'Izolační materiál s obsahem azbestu' },
      {
        code: '17 06 03',
        name: 'Jiné izolační materiály, které jsou nebo obsahují nebezpečné látky',
      },
      {
        code: '17 06 03 01',
        name: 'Izolační materiály na bázi polystyrenu obsahující nebezpečné látky',
      },
      {
        code: '17 06 04',
        name: 'Izolační materiály neuvedené pod čísly 17 06 01 a 17 06 03',
      },
      {
        code: '17 06 04 01',
        name: 'Izolační materiály na bázi polystyrenu s obsahem POPs vyžadující specifický způsob nakládání s ohledem na nařízení o POPs',
      },
      {
        code: '17 06 04 02',
        name: 'Izolační materiály na bázi polystyrenu',
      },
      {
        code: '17 06 05',
        name: 'Stavební materiály obsahující azbest',
      },
    ],
  },
  {
    code: '17 08',
    name: 'Stavební materiál na bázi sádry',
    items: [
      {
        code: '17 08 01',
        name: 'Stavební materiály na bázi sádry znečištěné nebezpečnými látkami',
      },
      {
        code: '17 08 02',
        name: 'Stavební materiály na bázi sádry neuvedené pod číslem 17 08 01',
      },
    ],
  },
  {
    code: '17 09',
    name: 'Jiné stavební a demoliční odpady',
    items: [
      { code: '17 09 01', name: 'Stavební a demoliční odpady obsahující rtuť' },
      {
        code: '17 09 02',
        name: 'Stavební a demoliční odpady obsahující PCB (např. těsnící materiály obsahující PCB, podlahoviny na bázi pryskyřic obsahující PCB, utěsněné zasklené dílce obsahující PCB, kondenzátory obsahující PCB)',
      },
      {
        code: '17 09 03',
        name: 'Jiné stavební a demoliční odpady (včetně směsných stavebních a demoličních odpadů) obsahující nebezpečné látky',
      },
      {
        code: '17 09 04',
        name: 'Směsné stavební a demoliční odpady neuvedené pod čísly 17 09 01, 17 09 02 a 17 09 03',
      },
    ],
  },
]

export const EMISSIONS_LIQUIDATION_METHODS = [
  { value: "Recyklace", label: "Recyklace"},
  { value: "Řízená skládka", label: "Řízená skládka"},
  { value: "Zpětné využití", label: "Zpětné využití"},
  { value: "Kovošrot", label: "Kovošrot"},
  { value: "Spalovna", label: "Spalovna"},
]
