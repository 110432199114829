import { Field, FormikProps, useFormikContext } from 'formik'
import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import RadioInput from '../../../../../../components/inputs/RadioInput'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'
import { B3_FORM_INITIAL_VALUES } from '../constants'

interface Props { }

export const B34BasicTechnicalDescription: React.FC<Props> = () => {
    const formik = useFormikContext() as FormikProps<typeof B3_FORM_INITIAL_VALUES>
    return (
        <FormSectionTwoColumns
            sectionKey="b3.basicTechnicalDescription"
            title="B.3.4 Základní technický popis stavby"
        >
            <div className="sm:col-span-6 p-5 rounded-md">
                <Field
                    label="a) popis stávajícího stavu"
                    name="b3.basicTechnicalDescription.a.enabled"
                    component={RadioInput}
                    options={[
                        {
                            label: 'Nová stavba',
                            value: false,
                        },
                        {
                            label: 'Změna dokončené stavby',
                            value: true,
                        },
                    ]}
                />
                {formik.values.b3.basicTechnicalDescription.a.enabled === true && (
                    <div className="pl-7">
                        <div className="sm:col-span-6 mt-3">
                            <Field
                                name="b3.basicTechnicalDescription.a.description"
                                label=""
                                component={RichTextInput}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="sm:col-span-6 flex flex-col gap-3">
                <label className='block text-sm font-semibold leading-6 pl-5 text-gray-900'>
                    b) popis navrženého stavebně technického a konstrukčního řešení
                </label>
                <div className="pl-7">
                    <div className="sm:col-span-6 mt-3">
                        <Field
                            name="b3.basicTechnicalDescription.b.constructionTechnicalSolution"
                            label="Stavební technické řešení"
                            component={RichTextInput}
                        />
                    </div>
                </div>
                <div className="pl-7">
                    <div className="sm:col-span-6 mt-3">
                        <Field
                            name="b3.basicTechnicalDescription.b.constructionSolution"
                            label="Konstrukční řešení"
                            component={RichTextInput}
                        />
                    </div>
                </div>
            </div>

        </FormSectionTwoColumns>
    )
}
