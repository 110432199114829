import React from 'react'
import { Field, FormikProps } from 'formik'
import {
  ACCOMPANYING_REPORT_A3_CONSTRUCTION_TYPE,
  ACCOMPANYING_REPORT_A3_ELEVATOR_OPTIONS,
  ACCOMPANYING_REPORT_A3_GAS_CONNECTION_OPTIONS,
  ACCOMPANYING_REPORT_A3_HEATHING_METHOD_OPTIONS,
  ACCOMPANYING_REPORT_A3_SEWER_CONNECTION_OPTIONS,
  ACCOMPANYING_REPORT_A3_WATER_CONNECTION_OPTIONS,
  ACCOMPANYING_REPORT_FORM_INITIAL_DATA,
  USAGE_METHOD_OPTIONS,
} from './constants'
import { NumberInput } from '../../../../components/inputs/NumberInput'
import { TextInput } from '../../../../components/inputs/TextInput'
import { SelectInput } from '../../../../components/inputs/SelectInput'
import { FileTreeInput } from '../../../../components/inputs/FileTreeInput'

interface Props {
  formik: FormikProps<typeof ACCOMPANYING_REPORT_FORM_INITIAL_DATA>
}

export const AccompanyingReportA3: React.FC<Props> = (props) => {
  return (
    <>
      <h2 id="a3Heading" className="font-semibold text-lg mt-8">
        A.3 TEA - Technicko-ekonomické atributy budov
      </h2>
      <div className="mt-3">
        <div className="gap-3 grid grid-cols-1 sm:grid-cols-3">
          <Field
            name="a3.enclosedSpace"
            component={TextInput}
            label="a) Obestavěný prostor"
            trailing={
              <>
                m<sup>2</sup>
              </>
            }
          />
          <Field
            name="a3.builtUpArea"
            component={TextInput}
            label="b) Zastavěná plocha"
            trailing={
              <>
                m<sup>2</sup>
              </>
            }
          />
          <Field
            name="a3.floorArea"
            component={TextInput}
            label="c) Podlahová plocha"
            trailing={
              <>
                m<sup>2</sup>
              </>
            }
          />
          <Field
            name="a3.numUndergroundFloors"
            component={NumberInput}
            label="d) Počet podzemních podlaží"
            stepper={true}
          />
          <Field
            name="a3.numAboveGroundFloors"
            component={NumberInput}
            label="e) Počet nadzemních podlaží"
            stepper={true}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-3">
          <Field
            name="a3.usageMethod"
            component={FileTreeInput}
            label="f) Způsob využití"
            options={USAGE_METHOD_OPTIONS}
            initialExpandedItems={['1', '1.1', '1.2']}
          />
          <Field
            name="a3.constructionType"
            component={SelectInput}
            options={ACCOMPANYING_REPORT_A3_CONSTRUCTION_TYPE}
            label="g) Druh kostrukce"
            forbidCustom={true}
          />
          <Field
            name="a3.heatingMethod"
            component={SelectInput}
            options={ACCOMPANYING_REPORT_A3_HEATHING_METHOD_OPTIONS}
            forbidCustom={true}
            label="h) Způsob vytápění"
          />
          <Field
            name="a3.waterConnection"
            component={SelectInput}
            forbidCustom={true}
            options={ACCOMPANYING_REPORT_A3_WATER_CONNECTION_OPTIONS}
            label="i) Přípojka vodovodu"
          />
          <Field
            name="a3.sewerConnection"
            component={SelectInput}
            label="j) Přípojka kanalizační sítě"
            forbidCustom={true}
            options={ACCOMPANYING_REPORT_A3_SEWER_CONNECTION_OPTIONS}
          />
          <Field
            name="a3.gasConnection"
            component={SelectInput}
            forbidCustom={true}
            options={ACCOMPANYING_REPORT_A3_GAS_CONNECTION_OPTIONS}
            label="k) Přípojka plynu"
          />
          <Field
            name="a3.elevator"
            component={SelectInput}
            options={ACCOMPANYING_REPORT_A3_ELEVATOR_OPTIONS}
            label="l) Výtah"
            forbidCustom={true}
          />
        </div>
      </div>
    </>
  )
}
