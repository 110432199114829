import React from 'react'
import { ProjectFormEnum } from '../../../../../model/enums/ProjectFormEnum'
import { useProject } from '../../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { TechnicalFormWrapper } from '../TechnicalFormWrapper'
import { B7EnvironmentalImpactsForm } from './B7EnvironmentalImpactsForm'
import { B7_FORM_INITIAL_VALUES, B7NavOptions } from './constants'

interface Props { }

export const B7EnvironmentalImpacts: React.FC<Props> = (props) => {
  const { project } = useProject()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <TechnicalFormWrapper
      form={ProjectFormEnum.TECHNICAL_FORM_B7}
      initialValues={B7_FORM_INITIAL_VALUES}
      title="B.7 Ochrana obyvatelstva"
      quickNavOptions={B7NavOptions}
    >
      <B7EnvironmentalImpactsForm />
    </TechnicalFormWrapper>
  )
}
