import { QuestionIcon } from '@chakra-ui/icons'
import { useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { TailwindModal } from './TailwindModal'

interface Props {
  title?: string
  content: string | React.ReactNode
}

export const InfoPopover: React.FC<Props> = (props) => {
  const disclosure = useDisclosure()
  return (
    <>
      <TailwindModal disclosure={disclosure} dialogTitle={props.title || 'Nápověda'}>
        {props.content}
      </TailwindModal>
      <span className="inline ml-3 cursor-pointer text-blue-600" onClick={disclosure.onOpen}>
        <QuestionIcon />
      </span>
    </>
  )
}
