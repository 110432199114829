import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import RadioInput from '../../../../../components/inputs/RadioInput'
import { ConnectionToTransportHelp } from '../info/ConnectionToTransportHelp'
import { RadioInputPanel } from '../../../../../components/RadioInputPanel'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { DropzoneInput } from '../../../../../components/inputs/DropzoneInput'
import { ArrayForm } from '../../../../../components/ArrayForm'
import { EmissionsTable } from '../../../../../components/forms/EmissionsTable/EmissionsTable'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Kv2: React.FC<Props> = ({ formik }) => {
  const values = formik.values

  return (
    <FormSectionTwoColumns
      sectionKey={'b1k'}
      title="k) limitní bilance stavby"
      subtitle="potřeby a spotřeby médií a hmot, hospodaření se srážkovou vodou, celkové produkované množství, druhy a kategorie odpadů a emisí apod."
    >
      <div className="sm:col-span-6 mt-5 flex flex-col gap-3">
        <div className="">
          <Field
            name="b1k.constructionBalanceSubstanceConsumtion"
            component={CheckboxInput}
            label="Potřeby a spotřeby médií a hmot"
          />
          {values.b1k.constructionBalanceSubstanceConsumtion && (
            <div className="pl-7">
              <div className="sm:col-span-6 ">
                <Field
                  name="b1k.constructionBalanceSubstanceConsumtionDescription"
                  component={RichTextInput}
                />
              </div>
            </div>
          )}
        </div>
        <div className="">
          <Field
            name="b1k.constructionBalanceRainWater"
            component={CheckboxInput}
            label="Hospodaření se srážkovou vodou"
          />
          {values.b1k.constructionBalanceRainWater && (
            <div className="pl-7">
              <div className="sm:col-span-6 ">
                <Field
                  name="b1k.constructionBalanceRainWaterDescription"
                  component={RichTextInput}
                />
              </div>
            </div>
          )}
        </div>
        <div className="">
          <Field
            name="b1k.constructionBalanceWasteAndEmissions.enabled"
            component={CheckboxInput}
            label="Celkové produkované množství, druhy a kategorie odpadů a emisí apod."
          />
          {values.b1k?.constructionBalanceWasteAndEmissions?.enabled && (
            <div className="pl-7 mt-2">
              <EmissionsTable name="b1k.constructionBalanceWasteAndEmissions.data" />
            </div>
          )}
        </div>
      </div>
    </FormSectionTwoColumns>
  )
}
