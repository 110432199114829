"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMISSIONS_CATEGORIES = exports.A_FORMS = exports.B_FORMS = exports.ACCOMPANYING_REPORT_FORM_INITIAL_DATA = exports.B9_FORM_INITIAL_VALUES = exports.B8_FORM_INITIAL_VALUES = exports.B7_FORM_INITIAL_VALUES = exports.B6_FORM_INITIAL_VALUES = exports.B5_FORM_INITIAL_VALUES = exports.B4_FORM_INITIAL_VALUES = exports.B3_FORM_INITIAL_VALUES = exports.B2_FORM_INIT_VALUES = exports.B1_INITIAL_VALUES = void 0;
const utils_1 = require("./utils");
const enums_1 = require("./enums");
const B3_SECTION_INITIAL_VALUES = {
    enabled: false,
    description: '',
    label: '',
};
const B4_SECTION_INITIAL_VALUES = {
    enabled: false,
    connectionCondition: 'existing',
    material: '',
    length: '',
    connectionDimension: '',
    performanceCapacity: '',
    description: '',
};
exports.B1_INITIAL_VALUES = {
    test: '',
    radio: 'email',
    person: 'Person 1',
    b1a: {
        aiGenerated: '',
        aiGeneratedAccepted: false,
        isBuildingPlot: true,
        typeOfPlot: '',
        placement: {
            value: '',
            custom: false,
        },
        shape: '',
        landSlope: '',
        previousUtilization: '',
        urbanization: '',
        surroundingArea: '',
        landscapeCharacter: true,
        landscapeCharacterDescription: '',
        newOrChange: '',
        currentState: '',
        technicalState: '',
        historicalState: '',
        bearingConstructionsCheck: '',
        usage: '',
        temporaryOrPermanent: '',
        temporaryFrom: new Date(),
        temporaryTo: new Date(),
    },
    b1b: {
        complianceWithPlanningDocumentation: true,
        complianceWithPlanningDocumentationDescription: '',
        complianceWithPlanningGoalsTasks: true,
        complianceWithPlanningGoalsTasksDescription: '',
    },
    b1c: {
        culturalHeritageCompliance: true,
        planningGoalsCompliance: true,
        planningDocumentationCompliance: true,
        issuedExceptions: [
            {
                name: '',
                file: '',
                description: '',
            },
        ],
    },
    b1d: {
        custom: [
            {
                name: '',
                enabled: false,
                conclusion: '',
                files: [],
            },
        ],
        pretext: `
        V projektové dokumentaci byly zohledněny podmínky závazných stanovisek dotčených
        orgánů, a to především v její textové části, situačních výkresech a
        architektonicko-stavební části. Veškerá stanoviska jsou přílohou projektové
        dokumentace (E - dokladová část).
    `,
        test: [
            {
                name: 'Krajská hygienická stanice',
                checked: false,
                description: '',
            },
            {
                name: 'Odbor dopravy',
                checked: false,
                description: '',
            },
            {
                name: 'Odbor územního plánování',
                checked: false,
                description: '',
            },
            {
                name: 'Odbor životního prostředí',
                checked: false,
                description: '',
            },
            {
                name: 'Odbor ochrany prostředí',
                checked: false,
                description: '',
            },
            {
                name: 'Odbor dopravy',
                checked: false,
                description: '',
            },
            {
                name: 'Hasičský záchranný sbor',
                checked: false,
                description: '',
            },
            {
                name: 'Vodoprávní úřad',
                checked: false,
                description: '',
            },
            {
                name: 'Obecní úřad s rozšířenou působností',
                checked: false,
                description: '',
            },
            {
                custom: true,
                name: '',
                checked: false,
                description: '',
            },
        ],
    },
    b1e: {
        issuedExceptions: [],
        geologicalSurvey: false,
        geologicalSurveyConclusion: '',
        hydrogeologicalSurvey: false,
        hydrogeologicalSurveyConclusion: '',
        historicalBuildingSurvey: false,
        historicalBuildingSurveyConclusion: '',
        engineerGeologySurvey: false,
        engineerGeologySurveyConclusion: '',
        radonMeasurement: false,
        radonMeasurementLevel: '',
        radonMeasurementConclusion: '',
        custom: [
            {
                name: '',
                enabled: false,
                conclusion: '',
                files: [],
            },
        ],
    },
    b1f: {
        territoryProtection: {
            enabled: false,
            conclusion: '',
        },
    },
    b1g: {
        floodArea: {
            enabled: false,
            measures: '',
            positions: '',
            forms: '',
        },
        underminedArea: {
            enabled: false,
            measures: '',
            positions: '',
            forms: '',
        },
        others: [
            {
                name: '',
                enabled: false,
                measures: '',
            },
        ],
        sanitation: {
            enabled: false,
            details: '',
            permissionRequired: '',
            form: '',
            extraApproval: false,
        },
        demolition: {
            enabled: false,
            details: '',
            permissionRequired: '',
            form: '',
            extraApproval: false,
        },
        treeFelling: {
            enabled: false,
            details: '',
            permissionRequired: '',
            form: '',
        },
        impactOnSurroundingBuildings: {
            enabled: false,
            measures: '',
        },
        impactOnSurroundingLands: {
            enabled: false,
            measures: '',
        },
        protectionOfSurroundings: {
            enabled: false,
            measures: '',
        },
        impactOnDrainageConditions: {
            enabled: false,
            measures: '',
        },
    },
    b1h: {
        impactOnSurroundingBuildings: {
            enabled: false,
            measures: '',
        },
        impactOnSurroundingLands: {
            enabled: false,
            measures: '',
        },
        protectionOfSurroundings: {
            enabled: false,
            measures: '',
        },
        impactOnDrainageConditions: {
            enabled: false,
            measures: '',
        },
    },
    b1i: {
        sanitation: {
            enabled: false,
            details: '',
            permissionRequired: '',
            form: '',
            extraApproval: false,
        },
        demolition: {
            enabled: false,
            details: '',
            permissionRequired: '',
            form: '',
            extraApproval: false,
        },
        treeFelling: {
            enabled: false,
            details: '',
            permissionRequired: '',
            form: '',
        },
    },
    b1j: {
        landSeizureAgricultural: {
            enabled: false,
            details: '',
        },
        landSeizureForest: {
            enabled: false,
            details: '',
        },
    },
    b1k: {
        connectionToTransportInfrastructure: {
            enabled: false,
            newOrExistingExit: '',
        },
        connectionToTechnicalInfrastructure: {
            waterSupply: {
                enabled: false,
                newOrExistingConnection: '',
            },
            sewerage: {
                enabled: false,
                newOrExistingConnection: '',
            },
            stormDrain: {
                enabled: false,
                newOrExistingConnection: '',
                disposalOnOwnLand: '',
            },
            gasPipeline: {
                enabled: false,
                newOrExistingConnection: '',
            },
            electricity: {
                enabled: false,
                newOrExistingConnection: '',
            },
            communicationLines: {
                enabled: false,
                newOrExistingConnection: '',
            },
            other: '',
            custom: [
                {
                    name: '',
                    enabled: false,
                    newOrExistingConnection: '',
                    description: '',
                },
            ],
        },
        barrierFreeAccess: {
            enabled: false,
            details: '',
        },
    },
    b1l: {
        objectAndTimeConnections: {
            allAtOnce: true,
            custom: '',
        },
        conditionalInvestments: false,
        conditionalInvestmentsDetails: '',
        triggeredInvestments: false,
        triggeredInvestmentsDetails: '',
        relatedInvestments: false,
        relatedInvestmentsDetails: '',
    },
};
exports.B2_FORM_INIT_VALUES = {
    b2: {
        characteristics: {
            a: {
                newOrChange: '',
                currentState: '',
                technicalState: '',
                historicalState: '',
                bearingConstructionsCheck: '',
            },
            b: {
                usage: '',
            },
            c: {
                temporaryOrPermanent: '',
                temporaryFrom: new Date(),
                temporaryTo: new Date(),
            },
            d: {
                barrierFreeExceptions: false,
                technicalExceptions: false,
                technicalExceptionsDescription: '',
                barrierFreeExceptionsDescription: '',
            },
            e: {
                doss: '',
            },
            f: {
                constructionProtection: false,
            },
            g: {
                buildUpArea: '',
                enclosedArea: '',
                usableArea: '',
                units: [
                    {
                        value: '',
                    },
                ],
            },
            h: {
                constructionBalanceSubstanceConsumtion: false,
                constructionBalanceRainWater: false,
                constructionBalanceWasteAndEmissions: false,
                constructionBalanceEnergyConsumption: false,
            },
            i: {
                divisionToStages: '',
            },
            j: {
                estimatedCosts: '',
            },
        },
        barrierFreeUsage: {
            isBarrierFree: false,
            description: 'Stavba je navržena a bude provedena v souladu s vyhláškou č. 398/2009 Sb. - Vyhláška o obecných technických požadavcích zabezpečujících bezbariérové užívání staveb  ',
        },
        safety: {
            description: 'Stavba je navržena a bude provedena v souladu se všemi  platnými normami, předpisy a vyhláškami, které zabezpečují bezpečnost při užívání. Mezi hlavní patří:<br/>' +
                '<br/>' +
                '- vyhláška č. 268/2009 Sb. o obecných technických požadavcích na stavby<br/>' +
                '- vyhláška č. 398/2009 Sb., o obecných technických požadavcích zabezpečujících bezbariérové užívání staveb<br/>' +
                '- Vyhláška č. 246/2001 Sb. o stanovení podmínek požární bezpečnosti a výkonu státního požárního dozoru (vyhláška o požární prevenci)<br/>' +
                '- ČSN 73 4130 – schodiště a šikmé rampy<br/>' +
                '- ČSN 74 3305 – ochranná zábradlí<br/>' +
                '- ČSN 73 0810 – Požární bezpečnost staveb – společná ustanovení<br/>' +
                '- ČSN 33 2000-4-41 ED.3 - elektrické instalace nízkého napětí - Část 4-41: Ochranná opatření pro zajištění bezpečnosti - Ochrana před úrazem elektrickým proudem<br/>' +
                '- A další<br/>' +
                '<br/>' +
                'Hlavní uzávěry, vypínače a další prvky, které to vyžadují, budou označeny příslušnými tabulkami.<br/>' +
                '<br/>' +
                'Veškeré technologie umístěné v objektu, budou dodány včetně dokladů o způsobu bezpečného užívání. <br/>' +
                '<br/>' +
                'K jednotlivým profesím, zařízením, instalacím a rozvodům budou vystaveny revizní zprávy o způsobilosti k bezpečnému provozu. <br/>' +
                '<br/>' +
                'Zařízení technického vybavení objektu budou podléhat pravidelnému servisu a budou ovládána zaškolenými pracovníky.“<br/>',
        },
        fireSafety: {
            description: 'Řešeno samostatnou částí projektové dokumentace D.1.3 - Požárně-bezpečnostní řešení',
        },
        energyAndHeat: {
            description: 'Viz. průkaz energetické náročnosti budovy, který je součástí dokladové části projektové dokumentace.',
        },
        hygiene: {
            others: [
                {
                    indexIdentifier: (0, utils_1.generateRandomString)(),
                    name: '',
                    description: '',
                },
            ],
        },
        externalFactorsProtection: {
            wayWardStream: false,
            technicalSeismicity: false,
            noise: false,
            floodProtection: false,
            underMining: false,
            methane: false,
            others: [
                {
                    name: '',
                    description: '',
                },
            ],
        },
        objectCharacteristics: {
            building: '',
            mechanicsAndStability: '',
            constructionAndMaterial: {
                vertical: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
                horizontal: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
                foundation: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
                roof: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
                isolation: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
                fillings: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
                other: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
            },
        },
    },
};
exports.B3_FORM_INITIAL_VALUES = {
    b3: {
        overallConceptSolutions: {
            constructionTechnicalSolution: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: 'Stavební technické řešení' }),
            technologicalSolution: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: 'Technologické řešení' }),
        },
        overallAccessibilityConditions: {
            individualParts: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `a) celkové řešení přístupnosti se specifikací jednotlivých části, které podléhají
            požadavkům na přístupnost, včetně dopadů předčasného užívání a zkušebního provozu a
            vlivu na okolí` }),
            proposedPrecautionsDescription: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `b) popis navržených opatření - zejména přístup ke stavbě, prostory stavby a systémy
            určené pro užívání veřejností` }),
            accessibilityImpactDescription: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `c) popis dopadů na přístupnost z hlediska uplatnění závažných územně technických nebo
            stavebně technických důvodů nebo jiných veřejných zájmů` }),
        },
        safetyPrinciples: {
            description: `
      <p>
        Stavba je navržena a bude provedena v souladu se všemi platnými normami, předpisy a vyhláškami, které zabezpečují bezpečnost při užívání.
        Mezi hlavní patří:
      </p>
      <p></p>
      <p>
        • vyhláška č. 268/2009 Sb. o obecných technických požadavcích na stavby
      </p>
      <p>
        • vyhláška č. 398/2009 Sb., o obecných technických požadavcích zabezpečujících bezbariérové užívání staveb
      </p>
      <p>
        • vyhláška č. 246/2001 Sb. o stanovení podmínek požární bezpečnosti a výkonu státního požárního dozoru (vyhláška o požární prevenci)
      </p>
      <p>
        • ČSN 73 4130 – schodiště a šikmé rampy
      </p>
      <p>
        • ČSN 74 3305 – ochranná zábradlí
      </p>
      <p>
        • ČSN 73 0810 – Požární bezpečnost staveb – společná ustanovení
      </p>
      <p>
        • ČSN 33 2000-4-41 ED.3 - elektrické instalace nízkého napětí - Část 4-41: Ochranná opatření pro zajištění bezpečnosti - Ochrana před úrazem elektrickým
        proudem
      </p>
      <p>
        • A další
      </p>
      <p></p>
      <p>
        Hlavní uzávěry, vypínače a další prvky, které to vyžadují, budou označeny příslušnými tabulkami.
        Veškeré technologie umístěné v objektu, budou dodány včetně dokladů o způsobu bezpečného užívání.
        K jednotlivým profesím, zařízením, instalacím a rozvodům budou vystaveny revizní zprávy o způsobilosti k bezpečnému provozu.
        Zařízení technického vybavení objektu budou podléhat pravidelnému servisu a budou ovládána zaškolenými pracovníky.
      </p>
    `,
        },
        basicTechnicalDescription: {
            a: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `a) popis stávajícího stavu` }),
            b: {
                label: 'b) popis navrženého stavebně technického a konstrukčního řešení',
                constructionTechnicalSolution: '',
                constructionSolution: '',
            },
        },
        technicalDevices: {
            a: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `a) popis stávajícího stavu` }),
            b: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `b) popis navrženého řešení` }),
            c: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `c) energetické výpočty` }),
        },
        fireSafety: {
            a: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `a) charakteristiky a kritéria pro stanovení kategorie stavby podle požadavků jiného právního předpisu - výška stavby, zastavěná plocha, počet
podlaží, počet osob, pro který je stavba určena, nebo jiný parametr stavby, zejména světlá výška podlaží nebo délka tunelu apod.
` }),
            b: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `b) kritéria - třída využití, přítomnost nebezpečných látek nebo jiných rizikových faktorů prohlášení stavby za kulturní památku` }),
        },
        energyConservation: {
            description: 'Viz. průkaz energetické náročnosti budovy v dokladové části',
        },
        hygienicRequirements: {
            ventilation: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Větrání` }),
            lighting: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Osvětlení` }),
            sunlight: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Proslunění` }),
            shading: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Stínění` }),
            waterSupply: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Zásobování vodou` }),
            noiseProtection: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Ochrana proti hluku` }),
            sewage: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Odpady` }),
            other: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Další` }),
        },
        protectionAgainstEnvironmentalFactors: {
            floodMeasures: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Protipovodňová opatření` }),
            radonLeaks: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Ochrana před pronikáním radonu z podloží` }),
            protectionAgainstStrayCurrents: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Ochrana před bludnými proudy` }),
            protectionAgainstSeismicity: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Ochrana před technickou i přírodní seizmicitou` }),
            protectionAgainstGroundwater: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Ochrana před agresivní a tlakovou podzemní vodou` }),
            protectionAgainstNoise: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Ochrana před hlukem a ostatními účinky` }),
            influenceOfUndermining: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Vliv poddolování` }),
            methaneOccurrence: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: `Výskyt metanu` }),
        },
    },
};
exports.B4_FORM_INITIAL_VALUES = {
    b4: {
        technicalInfrastructureConnection: {
            waterSupply: Object.assign({}, B4_SECTION_INITIAL_VALUES),
            sewage: Object.assign({}, B4_SECTION_INITIAL_VALUES),
            drainage: Object.assign({}, B4_SECTION_INITIAL_VALUES),
            gasPipeline: Object.assign({}, B4_SECTION_INITIAL_VALUES),
            wiring: Object.assign({}, B4_SECTION_INITIAL_VALUES),
            communicationLine: Object.assign({}, B4_SECTION_INITIAL_VALUES),
            other: Object.assign({}, B4_SECTION_INITIAL_VALUES),
        },
    },
};
exports.B5_FORM_INITIAL_VALUES = {
    b5: {
        transportSolution: {
            enabled: false,
            description: '',
            connectionToTraffic: '',
            reroutes: {
                enabled: false,
                description: '',
            },
            bikeTracks: {
                enabled: false,
                description: '',
            },
            staticTraffic: {
                enabled: false,
                description: '',
            },
            accessibilitySolution: {
                enabled: false,
                description: '',
            },
            obstacleFreeAccess: {
                enabled: false,
                description: '',
            },
        },
    },
};
exports.B6_FORM_INITIAL_VALUES = {
    b6: {
        vegetationAndTerrain: {
            enabled: false,
            description: '',
        },
    },
};
exports.B7_FORM_INITIAL_VALUES = {
    b7: {
        environmentalImpact: {
            natureAndLandscape: {
                enabled: false,
                description: '',
            },
            natura2000: {
                enabled: false,
                description: '',
            },
            outdoorLighting: {
                enabled: false,
                description: '',
            },
            asbestos: {
                enabled: false,
                description: '',
            },
            noiseAndVibrations: {
                enabled: false,
                description: '',
            },
            water: {
                enabled: false,
                description: '',
            },
            waste: {
                enabled: false,
                description: '',
            },
            soil: {
                enabled: false,
                description: '',
            },
            climateAndAir: {
                enabled: false,
                description: '',
            },
            stationarySources: {
                enabled: false,
                description: '',
            },
        },
        eiaConsideration: {
            enabled: false,
            description: '',
        },
        eiaComplianceDescription: {
            enabled: false,
            description: '',
        },
        integratedPermitCompliance: {
            enabled: false,
            description: '',
        },
    },
};
exports.B8_FORM_INITIAL_VALUES = {
    b8: {
        consumption: `
      <p>
        Potřeby a spotřeby médií budou vždy konkrétně závislé na druhu prováděné stavební 
        činnosti. Jejich zajištění je závislé na požadavcích provozovatelů sítí.
      </p>
      <p>
        Samotné stavební práce budou probíhat na pozemcích, které jsou v osobním 
        vlastnictví stavebníka. 
      </p>
    `,
        water: `
      <p>
        Staveniště není potřeba zvlášť odvodňovat. Pouze v případě nutnosti je možno potřebnou plochu odvodnit pomocí svahované jámy, do které prosakuje voda svahy a dnem. Prosakující vodu dále u pasu svahu zachytit systémem obvodových rigolů, anebo drenů. V prostoru dna výkopu plošnými dreny, a svézt do několika sběrných studní, kde se voda odčerpá a vyveze. Odvodnění staveniště bude detailněji řešeno s hlavním dodavatelem stavby.
      </p>
    `,
        connections: {
            traffic: '',
            water: false,
            drainage: false,
            electricity: false,
            other: '',
        },
        surroundingAreaImpact: `
      <p>Stavba nebude mít trvalý vliv na životní prostředí. V průběhu vlastní výstavby dojde k
      dočasnému zhoršení podmínek dané lokality (hluk, různá omezení vyplývající 
      z postupu výstavby). Na staveništi budou učiněna veškerá možná opatření k jejich 
      eliminaci. Komunální odpad bude shromažďován do kontejnerových nádob s pravidelným
      týdenním režimem odvozu</p>    
    `,
        surroundingAreaProtection: `
      <p>
        Staveniště bude oploceno plotem výšky 1,8 m, čímž bude zamezeno přístupu
        nepovolaných osob na staveniště. Dále je třeba minimalizovat hluk ze stavebních
        mechanismů, prašnost ze stavební činnosti a udržovat v čistotě výjezd ze staveniště.
        Veškeré odpady ze stavební činnosti budou uchovávány v kontejnerech a likvidovány
        na řízených skládkách. Požadavky na asanace, demolice ani kácení dřevin se zde nevyskytují.
      </p>    
    `,
        maxTemporaryArea: `
      <p>
        Výstavba proběhne pouze na pozemcích ve vlastnictví stavebníka. Nejsou tedy nutné žádné další dočasné ani trvalé zábory.  
      </p>
    `,
        trailsProtection: `
      <p>
        Požadavky na bezbariérové obchozí trasy se zde nevyskytují
      </p>
    `,
        maxEmissions: `
      <p>
        Přesné místo likvidace bude určeno realizační firmou. V případě výskytu nebezpečných 
        a ekologicky či zdravotně závadných materiálu, se s nimi bude rovněž manipulovat dle 
        platné legislativy v posledním znění.
      </p>
    `,
        excavatedSoilSpreads: `
      <p>
        Vytěžená zemina se rozprostře na parcele investora. Požadavky na přísun ani deponie zemin se v projektu nevyskytují.
      </p>
    `,
        environmentProtection: `
      <p>
        Vlastní výstavba se realizuje v místě s okolní zástavbou. Je proto třeba respektovat tuto skutečnost a vytvořit oplocením uzavřené staveniště. Dále je třeba minimalizovat hluk ze stavebních mechanismů, prašnost ze stavební činnosti a udržovat v čistotě výjezd ze staveniště. Stavba nebude mít trvalý vliv na životní prostředí. V průběhu vlastní výstavby dojde k dočasnému zhoršení podmínek dané lokality (hluk, různá omezení vyplývající z postupu výstavby). Na staveništi budou učiněna veškerá možná opatření k jejich eliminaci. Veškeré odpady ze stavební činnosti budou uchovávány v přepravních obalech a likvidovány na řízených skládkách.
      </p>
    `,
        healthProtection: ``,
        barrierFreeProtection: `
      <p>S ohledem na druh výstavby není projektem řešeno. .</p>
    `,
        engineeringPracticesProtection: `
      <p>S ohledem na druh výstavby není projektem řešeno. .</p>
    `,
        specialConditions: `
      <p>S ohledem na druh výstavby není projektem řešeno. .</p>
    `,
        buildingProcedure: `
      <p>Zahájení stavby je uvažováno bezprostředně po vydání stavebního povolení. Stavba 
      proběhne najednou jako celek.</p>    
    `,
    },
};
exports.B9_FORM_INITIAL_VALUES = {
    b9: {
        waterSolution: 'Dešťové vody budou zachyceny soustavou svodů a budou odvedeny do akumulační' +
            'nádrže o obejmu ……m3. Dešťová voda bude využívána dále k zálivce. Přebytky budou likvidovány …… ',
    },
};
exports.ACCOMPANYING_REPORT_FORM_INITIAL_DATA = {
    a1: {
        constructionName: '',
        builder: undefined,
        builders: [undefined],
        documentationAuthor: undefined,
        primaryDocumentationAuthor: undefined,
        otherAuthors: [
            {
                label: '',
                author: undefined,
                indexIdentifier: (0, utils_1.generateRandomString)(),
            },
        ],
        surveyingEngineers: [
            {
                authorizationNumber: '',
                author: undefined,
                indexIdentifier: (0, utils_1.generateRandomString)(),
            },
        ],
        places: [
            {
                indexIdentifier: (0, utils_1.generateRandomString)(),
                municipality: undefined,
                siteFacilities: false,
                statutoryServitudes: false,
            },
        ],
    },
    a2: {
        baseDocumentations: [{ indexIdentifier: (0, utils_1.generateRandomString)() }],
    },
    a3: {
        enclosedSpace: '',
        builtUpArea: '',
        floorArea: '',
        numAboveGroundFloors: '',
        numUndergroundFloors: '',
        usageMethod: '',
        constructionType: '',
        heatingMethod: '',
        waterConnection: '',
        sewerConnection: '',
        gasConnection: '',
        elevator: '',
        elevatorDescription: '',
    },
    a4: {
        depthOfConstruction: '',
        heightOfConstruction: '',
        numberOfPeople: '',
        plannedStartDate: null,
        plannedEndDate: null,
    },
};
exports.B_FORMS = [
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B1,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B2,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B3,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B4,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B5,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B6,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B7,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B8,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B9,
];
exports.A_FORMS = [enums_1.ProjectFormEnum.ACCOMPANYING_REPORT_FORM_A1];
exports.EMISSIONS_CATEGORIES = [
    {
        code: '17 01',
        name: 'Beton, cihly, tašky a keramika',
        items: [
            { code: '17 01 01', name: 'Beton' },
            { code: '17 01 02', name: 'Cihly' },
            { code: '17 01 03', name: 'Tašky a keramické výrobky' },
            {
                code: '17 01 06',
                name: 'Směsi nebo oddělené frakce betonu, cihel, tašek a keramických výrobků obsahující nebezpečné látky',
            },
            {
                code: '17 01 07',
                name: 'Směsi nebo oddělené frakce betonu, cihel, tašek a keramických výrobků neuvedené pod číslem 17 01 06',
            },
        ],
    },
    {
        code: '17 02',
        name: 'Dřevo, sklo a plasty',
        items: [
            { code: '17 02 01', name: 'Dřevo' },
            { code: '17 02 02', name: 'Sklo' },
            { code: '17 02 03', name: 'Plasty' },
            {
                code: '17 02 04',
                name: 'Sklo, plasty a dřevo obsahující nebezpečné látky nebo nebezpečnými látkami znečištěné',
            },
        ],
    },
    {
        code: '17 03',
        name: 'Asfaltové směsi, dehet a výrobky z dehtu',
        items: [
            { code: '17 03 01', name: 'Asfaltové směsi obsahující dehet' },
            {
                code: '17 03 02',
                name: 'Asfaltové směsi neuvedené pod číslem 17 03 01',
            },
            { code: '17 03 03', name: 'Uhelný dehet a výrobky z dehtu' },
        ],
    },
    {
        code: '17 04',
        name: 'Kovy (včetně jejich slitin)',
        items: [
            { code: '17 04 01', name: 'Měď, bronz, mosaz' },
            { code: '17 04 02', name: 'Hliník' },
            { code: '17 04 03', name: 'Olovo' },
            { code: '17 04 04', name: 'Zinek' },
            { code: '17 04 05', name: 'Železo a ocel' },
            { code: '17 04 06', name: 'Cín' },
            { code: '17 04 07', name: 'Směsné kovy' },
            {
                code: '17 04 09',
                name: 'Kovový odpad znečištěný nebezpečnými látkami',
            },
            {
                code: '17 04 10',
                name: 'Kabely obsahující ropné látky, uhelný dehet a jiné nebezpečné látky',
            },
            {
                code: '17 04 11',
                name: 'Kabely neuvedené pod číslem 17 04 10',
            },
        ],
    },
    {
        code: '17 05',
        name: 'Zemina (včetně vytěžené zeminy z kontaminovaných míst), kamení, vytěžená jalová hornina a hlušina',
        items: [
            {
                code: '17 05 03',
                name: 'Zemina a kamení obsahující nebezpečné látky',
            },
            {
                code: '17 05 04',
                name: 'Zemina a kamení neuvedené pod číslem 17 05 03',
            },
            {
                code: '17 05 04 01',
                name: 'Sedimenty vytěžené z koryt vodních toků a vodních nádrží',
            },
            {
                code: '17 05 05',
                name: 'Vytěžená jalová hornina a hlušina obsahující nebezpečné látky',
            },
            {
                code: '17 05 06',
                name: 'Vytěžená jalová hornina a hlušina neuvedená pod číslem 17 05 05',
            },
            {
                code: '17 05 07',
                name: 'Štěrk ze železničního svršku obsahující nebezpečné látky',
            },
            {
                code: '17 05 08',
                name: 'Štěrk ze železničního svršku neuvedený pod číslem 17 05 07',
            },
        ],
    },
    {
        code: '17 06',
        name: 'Izolační materiály a stavební materiály s obsahem azbestu',
        items: [
            { code: '17 06 01', name: 'Izolační materiál s obsahem azbestu' },
            {
                code: '17 06 03',
                name: 'Jiné izolační materiály, které jsou nebo obsahují nebezpečné látky',
            },
            {
                code: '17 06 03 01',
                name: 'Izolační materiály na bázi polystyrenu obsahující nebezpečné látky',
            },
            {
                code: '17 06 04',
                name: 'Izolační materiály neuvedené pod čísly 17 06 01 a 17 06 03',
            },
            {
                code: '17 06 04 01',
                name: 'Izolační materiály na bázi polystyrenu s obsahem POPs vyžadující specifický způsob nakládání s ohledem na nařízení o POPs',
            },
            {
                code: '17 06 04 02',
                name: 'Izolační materiály na bázi polystyrenu',
            },
            {
                code: '17 06 05',
                name: 'Stavební materiály obsahující azbest',
            },
        ],
    },
    {
        code: '17 08',
        name: 'Stavební materiál na bázi sádry',
        items: [
            {
                code: '17 08 01',
                name: 'Stavební materiály na bázi sádry znečištěné nebezpečnými látkami',
            },
            {
                code: '17 08 02',
                name: 'Stavební materiály na bázi sádry neuvedené pod číslem 17 08 01',
            },
        ],
    },
    {
        code: '17 09',
        name: 'Jiné stavební a demoliční odpady',
        items: [
            { code: '17 09 01', name: 'Stavební a demoliční odpady obsahující rtuť' },
            {
                code: '17 09 02',
                name: 'Stavební a demoliční odpady obsahující PCB (např. těsnící materiály obsahující PCB, podlahoviny na bázi pryskyřic obsahující PCB, utěsněné zasklené dílce obsahující PCB, kondenzátory obsahující PCB)',
            },
            {
                code: '17 09 03',
                name: 'Jiné stavební a demoliční odpady (včetně směsných stavebních a demoličních odpadů) obsahující nebezpečné látky',
            },
            {
                code: '17 09 04',
                name: 'Směsné stavební a demoliční odpady neuvedené pod čísly 17 09 01, 17 09 02 a 17 09 03',
            },
        ],
    },
];
