import React from 'react'
import { FieldProps } from 'formik'
import { getFieldValidity } from '../../model/helpers/formHelpers'
import {
  formatNumberWithDelimiters,
  removeNumberDelimiters,
} from '../../model/helpers/numberHelpers'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { FormLabel } from '../FormLabel'

interface Props extends FieldProps {
  label?: string
  placeholder?: string
  wrapperClassname?: string
  trailing?: string | React.ReactElement
  stepper?: boolean
}

export const NumberInput: React.FC<Props> = (props) => {
  const { isInvalid, message } = getFieldValidity(props.form, props.field.name)

  return (
    <div className={props.wrapperClassname}>
      {props.label && (
        <FormLabel htmlFor={props.field.name}>
          {props.label}
          {isInvalid && <span className="text-red-600 ml-3">{message}</span>}
        </FormLabel>
      )}
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type="text"
          name={props.field.name}
          id={props.field.name}
          value={formatNumberWithDelimiters(props.field.value)}
          className={`text-right block w-full rounded-md border-0 py-1.5 ${
            props.stepper ? 'pr-24' : 'pr-12'
          } text-gray-900 shadow-sm ${
            isInvalid ? 'ring-red-500' : 'ring-gray-300'
          } ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6`}
          placeholder={props.placeholder}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const number = removeNumberDelimiters(e.target.value)
            props.form.setFieldValue(props.field.name, number)
          }}
        />
        {props.trailing && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {props.trailing}
          </div>
        )}
        {props.stepper && (
          <div className="absolute inset-y-0 right-0 flex items-center">
            <button
              onClick={() => {
                let number = Number(props.field.value) - 1

                if (isNaN(number)) {
                  number = 0
                }

                props.form.setFieldValue(props.field.name, number)
              }}
              type="button"
              className="inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <MinusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5 text-gray-400" />
            </button>
            <button
              onClick={() => {
                let number = Number(props.field.value) + 1

                if (isNaN(number)) {
                  number = 1
                }

                props.form.setFieldValue(props.field.name, number)
              }}
              type="button"
              className="inline-flex items-center  rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <PlusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5 text-gray-400" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
