import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { NumberInput } from '../../../../../components/inputs/NumberInput'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Jv2: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1j'}
      title="j) navrhované parametry stavby"
      subtitle="například zastavěná plocha, obestavěný prostor, podlahová plocha podle jednotlivých funkcí (bytů, služeb, administrativy apod.), typ navržené technologie, předpokládané kapacity provozu a výroby"
    >
      <div className="sm:col-span-6 mt-5 flex flex-col gap-3">
        <div className="gap-3 grid grid-cols-1 sm:grid-cols-3">
          <Field
            name="b1j.builtUpArea"
            component={TextInput}
            label="Zastavěná plocha"
            trailing={
              <>
                m<sup>2</sup>
              </>
            }
          />
          <Field
            name="b1j.enclosedSpace"
            component={TextInput}
            label="Obestavěný prostor"
            trailing={
              <>
                m<sup>2</sup>
              </>
            }
          />
          <Field
            name="b1j.floorArea"
            component={TextInput}
            label="Podlahová plocha"
            trailing={
              <>
                m<sup>2</sup>
              </>
            }
          />
          <Field
            name="b1j.numberOfPersons"
            component={NumberInput}
            label="Předpokládaný počet osob"
            stepper={true}
          />
        </div>
        <div>
          <Field
            name="b1j.floorAreaShouldSplit"
            component={CheckboxInput}
            label="Rozdělit podlahovou plochu podle jednotlivých funkcí"
          />
          {formik.values?.b1j?.floorAreaShouldSplit && (
            <div className="pl-7">
              <Field name="b1j.floorAreaSplit" component={RichTextInput} />
            </div>
          )}
        </div>
        <div>
          <Field
            name="b1j.typeOfTechnology"
            component={RichTextInput}
            label="Typ navržené technologie"
          />
        </div>
        <div>
          <Field
            name="b1j.capacity"
            component={RichTextInput}
            label="Předpokládané kapacity provozu a výroby"
          />
        </div>
      </div>
    </FormSectionTwoColumns>
  )
}
