import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { InfoPopover } from '../../../../../components/InfoPopover'
import RadioInput from '../../../../../components/inputs/RadioInput'
import { DatePickerInput } from '../../../../../components/inputs/DatePickerInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Hv2: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1h'}
      title="h) požadavky na zábory"
      subtitle="požadavky na maximální dočasné a trvalé zábory zemědělského půdního fondu nebo pozemků určených k plnění funkce lesa"
    >
      <div className="sm:col-span-6 mt-5 flex flex-col gap-3">
        <div className="sm:col-span-6">
          <Field
            component={CheckboxInput}
            name="b1h.landSeizureAgricultural.enabled"
            label="Zábory ZPF"
          />
        </div>
        {formik.values.b1h?.landSeizureAgricultural?.enabled && (
          <div className="sm:col-span-6 pl-7">
            <Field
              name="b1h.landSeizureAgricultural.temporary"
              component={RadioInput}
              options={[
                { value: 'permanent', label: 'Trvalé' },
                { value: 'temporary', label: 'Dočasné' },
              ]}
            />
            {formik.values.b1h?.landSeizureAgricultural?.temporary === 'temporary' && (
              <div className="flex gap-3 mt-2">
                <Field
                  component={DatePickerInput}
                  name={`b1h.landSeizureAgricultural.temporaryDateFrom`}
                  label="Od"
                />
                <Field
                  component={DatePickerInput}
                  name={`b1h.landSeizureAgricultural.temporaryDateFrom`}
                  label="Do"
                />
              </div>
            )}
            <Field component={RichTextInput} name="b1h.landSeizureAgricultural.details" label="" />
          </div>
        )}
        <div className="sm:col-span-6">
          <Field
            component={CheckboxInput}
            name="b1h.landSeizureForest.enabled"
            label="Zábory pozemků určených k plnění funkce lesa"
          />
        </div>
        {formik.values.b1h?.landSeizureForest?.enabled && (
          <div className="sm:col-span-6 pl-7">
            <Field
              name="b1h.landSeizureForest.temporary"
              component={RadioInput}
              options={[
                { value: 'permanent', label: 'Trvalé' },
                { value: 'temporary', label: 'Dočasné' },
              ]}
            />
            {formik.values.b1h?.landSeizureForest?.temporary === 'temporary' && (
              <div className="flex gap-3 mt-2">
                <Field
                  component={DatePickerInput}
                  name={`b1h.landSeizureForest.temporaryDateFrom`}
                  label="Od"
                />
                <Field
                  component={DatePickerInput}
                  name={`b1h.landSeizureForest.temporaryDateFrom`}
                  label="Do"
                />
              </div>
            )}
            <Field component={RichTextInput} name="b1h.landSeizureForest.details" label="" />
          </div>
        )}
      </div>
    </FormSectionTwoColumns>
  )
}
