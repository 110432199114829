import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Ov2: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      title="o) seznam výsledků zeměměřických činností podle jiného právního předpisu"
      subtitle="pokud mají podle projektu výsledků zeměměřických činností vzniknout v souvislosti s povolením stavby"
      sectionKey="b1o"
    >
      <div className="sm:col-span-6 mt-5 flex flex-col gap-3">
        <div>
          <Field
            name="b1o.measurements.enabled"
            component={CheckboxInput}
            label="Je součástí projektu"
          />
          {formik.values?.b1o?.measurements?.enabled && (
            <div className="pl-7 flex flex-col gap-3">
              <Field name="b1o.measurements.description" component={RichTextInput} />
            </div>
          )}
        </div>
      </div>
    </FormSectionTwoColumns>
  )
}
