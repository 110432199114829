import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Nv2: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      title="n) základní požadavky na předčasné užívání staveb a zkušební provoz staveb"
      subtitle="doba jejich trvání ve vztahu k dokončení a užívání stavby"
      sectionKey="b1n"
    >
      <div className="sm:col-span-6 mt-5 flex flex-col gap-3">
        <div>
          <Field
            name="b1n.pretermUsage.enabled"
            component={CheckboxInput}
            label="Požadavky na předčasné užívání staveb"
          />
          {formik.values?.b1n?.pretermUsage?.enabled && (
            <div className="mt-2 pl-7 flex flex-col gap-3">
              <Field
                name="b1n.pretermUsage.description"
                component={RichTextInput}
                label="Popis požadavků"
              />
              <Field
                name="b1n.pretermUsage.length"
                component={RichTextInput}
                label="Doba trvání vzhledem k dokončení a užívání stavby"
              />
            </div>
          )}
        </div>
        <div>
          <Field
            name="b1n.testUsage.enabled"
            component={CheckboxInput}
            label="Zkušební provoz staveb"
          />
          {formik.values?.b1n?.testUsage?.enabled && (
            <div className="mt-2 pl-7 flex flex-col gap-3">
              <Field
                name="b1n.testUsage.description"
                component={RichTextInput}
                label="Popis požadavků"
              />
              <Field
                name="b1n.testUsage.length"
                component={RichTextInput}
                label="Doba trvání vzhledem k dokončení a užívání stavby"
              />
            </div>
          )}
        </div>
      </div>
    </FormSectionTwoColumns>
  )
}
