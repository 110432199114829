import { Field, FormikProps, useFormikContext } from 'formik'
import React from 'react'
import { FormLabel } from '../../../../../components/FormLabel'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import RadioInput from '../../../../../components/inputs/RadioInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { B4_FORM_INITIAL_VALUES } from './constants'

interface Props { }

export const B4TechnicalInfrastructureConnectionForm: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<typeof B4_FORM_INITIAL_VALUES>
  return (
    <>
      <FormSectionTwoColumns
        sectionKey="b4.technicalInfrastructureConnection"
        title="Napojovací místa technické infrastruktury"
        subtitle="přeložky, křížení se stavbami technické a dopravní infrastruktury a souběhy s nimi v případě, kdy je stavba umístěna v ochranném pásmu stavby technické nebo dopravní infrastruktury, nebo je-li ohrožena bezpečnost, připojovací rozměry, výkonové kapacity a délky."
      >
        <div className="sm:col-span-6">
          <Field
            name="b4.technicalInfrastructureConnection.waterSupply.enabled"
            component={CheckboxInput}
            label="Vodovod"
          />
          {formik.values.b4.technicalInfrastructureConnection.waterSupply.enabled && (
            <>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Přípojka</span>
                <Field
                  name="b4.technicalInfrastructureConnection.waterSupply.connectionCondition"
                  component={RadioInput}
                  options={[
                    { label: 'Stávající', value: 'existing' },
                    { label: 'Nová', value: 'new' },
                    { label: 'Rekonstruovaná', value: 'reconstructed' },
                  ]}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Materiál a dimenze</span>
                <Field
                  name="b4.technicalInfrastructureConnection.waterSupply.material"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Délka</span>
                <Field
                  name="b4.technicalInfrastructureConnection.waterSupply.length"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Připojovací rozměr</span>
                <Field
                  name="b4.technicalInfrastructureConnection.waterSupply.connectionDimension"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Výkonová kapacita</span>
                <Field
                  name="b4.technicalInfrastructureConnection.waterSupply.performanceCapacity"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <Field
                  name="b4.technicalInfrastructureConnection.waterSupply.description"
                  component={RichTextInput}
                />
              </div>
            </>
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b4.technicalInfrastructureConnection.sewage.enabled"
            component={CheckboxInput}
            label="Splašková kanalizace"
          />
          {formik.values.b4.technicalInfrastructureConnection.sewage.enabled && (
            <>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Přípojka</span>
                <Field
                  name="b4.technicalInfrastructureConnection.sewage.connectionCondition"
                  component={RadioInput}
                  options={[
                    { label: 'Stávající', value: 'existing' },
                    { label: 'Nová', value: 'new' },
                    { label: 'Rekonstruovaná', value: 'reconstructed' },
                  ]}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Materiál a dimenze</span>
                <Field
                  name="b4.technicalInfrastructureConnection.sewage.material"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Délka</span>
                <Field
                  name="b4.technicalInfrastructureConnection.sewage.length"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Připojovací rozměr</span>
                <Field
                  name="b4.technicalInfrastructureConnection.sewage.connectionDimension"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Výkonová kapacita</span>
                <Field
                  name="b4.technicalInfrastructureConnection.sewage.performanceCapacity"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <Field
                  name="b4.technicalInfrastructureConnection.sewage.description"
                  component={RichTextInput}
                />
              </div>
            </>
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b4.technicalInfrastructureConnection.drainage.enabled"
            component={CheckboxInput}
            label="Dešťová kanalizace"
          />
          {formik.values.b4.technicalInfrastructureConnection.drainage.enabled && (
            <>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Přípojka</span>
                <Field
                  name="b4.technicalInfrastructureConnection.drainage.connectionCondition"
                  component={RadioInput}
                  options={[
                    { label: 'Stávající', value: 'existing' },
                    { label: 'Nová', value: 'new' },
                    { label: 'Rekonstruovaná', value: 'reconstructed' },
                  ]}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Materiál a dimenze</span>
                <Field
                  name="b4.technicalInfrastructureConnection.drainage.material"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Délka</span>
                <Field
                  name="b4.technicalInfrastructureConnection.drainage.length"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Připojovací rozměr</span>
                <Field
                  name="b4.technicalInfrastructureConnection.drainage.connectionDimension"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Výkonová kapacita</span>
                <Field
                  name="b4.technicalInfrastructureConnection.drainage.performanceCapacity"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <Field
                  name="b4.technicalInfrastructureConnection.drainage.description"
                  component={RichTextInput}
                />
              </div>
            </>
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b4.technicalInfrastructureConnection.gasPipeline.enabled"
            component={CheckboxInput}
            label="Plynovod"
          />
          {formik.values.b4.technicalInfrastructureConnection.gasPipeline.enabled && (
            <>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Přípojka</span>
                <Field
                  name="b4.technicalInfrastructureConnection.gasPipeline.connectionCondition"
                  component={RadioInput}
                  options={[
                    { label: 'Stávající', value: 'existing' },
                    { label: 'Nová', value: 'new' },
                    { label: 'Rekonstruovaná', value: 'reconstructed' },
                  ]}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Materiál a dimenze</span>
                <Field
                  name="b4.technicalInfrastructureConnection.gasPipeline.material"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Délka</span>
                <Field
                  name="b4.technicalInfrastructureConnection.gasPipeline.length"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Připojovací rozměr</span>
                <Field
                  name="b4.technicalInfrastructureConnection.gasPipeline.connectionDimension"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Výkonová kapacita</span>
                <Field
                  name="b4.technicalInfrastructureConnection.gasPipeline.performanceCapacity"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <Field
                  name="b4.technicalInfrastructureConnection.gasPipeline.description"
                  component={RichTextInput}
                />
              </div>
            </>
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b4.technicalInfrastructureConnection.wiring.enabled"
            component={CheckboxInput}
            label="Vedení NN"
          />
          {formik.values.b4.technicalInfrastructureConnection.wiring.enabled && (
            <>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Přípojka</span>
                <Field
                  name="b4.technicalInfrastructureConnection.wiring.connectionCondition"
                  component={RadioInput}
                  options={[
                    { label: 'Stávající', value: 'existing' },
                    { label: 'Nová', value: 'new' },
                    { label: 'Rekonstruovaná', value: 'reconstructed' },
                  ]}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Materiál a dimenze</span>
                <Field
                  name="b4.technicalInfrastructureConnection.wiring.material"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Délka</span>
                <Field
                  name="b4.technicalInfrastructureConnection.wiring.length"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Připojovací rozměr</span>
                <Field
                  name="b4.technicalInfrastructureConnection.wiring.connectionDimension"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Výkonová kapacita</span>
                <Field
                  name="b4.technicalInfrastructureConnection.wiring.performanceCapacity"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <Field
                  name="b4.technicalInfrastructureConnection.wiring.description"
                  component={RichTextInput}
                />
              </div>
            </>
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b4.technicalInfrastructureConnection.communicationLine.enabled"
            component={CheckboxInput}
            label="Sdělovací vedení"
          />
          {formik.values.b4.technicalInfrastructureConnection.communicationLine.enabled && (
            <>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Přípojka</span>
                <Field
                  name="b4.technicalInfrastructureConnection.communicationLine.connectionCondition"
                  component={RadioInput}
                  options={[
                    { label: 'Stávající', value: 'existing' },
                    { label: 'Nová', value: 'new' },
                    { label: 'Rekonstruovaná', value: 'reconstructed' },
                  ]}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Materiál a dimenze</span>
                <Field
                  name="b4.technicalInfrastructureConnection.communicationLine.material"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Délka</span>
                <Field
                  name="b4.technicalInfrastructureConnection.communicationLine.length"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Připojovací rozměr</span>
                <Field
                  name="b4.technicalInfrastructureConnection.communicationLine.connectionDimension"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Výkonová kapacita</span>
                <Field
                  name="b4.technicalInfrastructureConnection.communicationLine.performanceCapacity"
                  component={TextInput}
                />
              </div>
              <div className="pl-7 mt-3">
                <Field
                  name="b4.technicalInfrastructureConnection.communicationLine.description"
                  component={RichTextInput}
                />
              </div>
            </>
          )}
        </div>
        <div className="sm:col-span-6">
          <Field
            name="b4.technicalInfrastructureConnection.other.enabled"
            component={CheckboxInput}
            label="Vlastní"
          />
          {formik.values.b4.technicalInfrastructureConnection.other.enabled && (
            <>
              <div className="pl-7 mt-3">
                <span className='text-sm'>Popis</span>
                <Field
                  name="b4.technicalInfrastructureConnection.other.description"
                  component={TextInput}
                />
              </div>
            </>
          )}
        </div>
      </FormSectionTwoColumns>
    </>
  )
}
