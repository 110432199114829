import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Spinner, useDisclosure } from '@chakra-ui/react'
import { ProjectLayout } from '../../layout/ProjectLayout'
import { ButtonWithDropdown } from '../../../../components/ButtonWithDropdown'
import { TailwindModal } from '../../../../components/TailwindModal'
import { ProjectLoadingSkeleton } from '../../layout/ProjectLoadingSkeleton'
import { useProject } from '../../../../model/hooks/useProject'
import { RoutesEnum } from '../../../../model/enums/RoutesEnum'
import { useGetIssues } from '../../../../model/api/project/useGetIssues'
import { IssueStatusEnum } from '../../../../model/enums/IssueStatusEnum'
import { PaginationButton } from '../../../../components/PaginationButton'
import { Formik } from 'formik'
import { IssuesFilter } from './IssuesFilter'
import { useGetObjects } from '../../../../model/api/project/useGetObjects'
import { IssuesList } from './IssuesList'

export const Issues: React.FC = () => {
  const [searchParams, setSearchParamsRaw] = useSearchParams({
    page: '1',
    limit: '20',
  })
  const searchParamStatus = searchParams.get('status') as undefined | IssueStatusEnum
  const publicLinkDisclosure = useDisclosure()
  const { project, projectIsLoading } = useProject()
  const navigate = useNavigate()
  const {
    data: issuesData,
    isLoading: isLoadingIssues,
    refetch: refetchIssues,
    isRefetching: isRefetchingIssues,
  } = useGetIssues(
    project?.id,
    searchParams.get('page') as string | undefined,
    searchParams.get('limit') as string | undefined,
    searchParamStatus,
    searchParams.get('object') as string | undefined,
  )
  const { data: objectsData } = useGetObjects(project?.id)

  const setSearchParams = (params: any) => {
    let needsPageReset = false
    for (const key in params) {
      if (key !== 'page' && params[key] !== searchParams.get(key)) {
        needsPageReset = true
      }
      searchParams.set(key, params[key])
    }
    if (needsPageReset) {
      searchParams.set('page', '1')
    }
    setSearchParamsRaw(searchParams)
    setTimeout(() => {
      refetchIssues()
    }, 100)
  }
  if (
    typeof project === 'undefined' ||
    projectIsLoading ||
    isLoadingIssues ||
    typeof issuesData === 'undefined'
  ) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <ProjectLayout project={project}>
      <TailwindModal dialogTitle="Veřejný náhled" disclosure={publicLinkDisclosure}>
        <a href={`http://qik.qikapp.cz/preview/project/${project.id}`} target="_blank">
          http://qik.qikapp.cz/preview/project/{project.id}
        </a>
      </TailwindModal>
      <div>
        <div className="flex flex-wrap items-center gap-6 sm:flex-nowrap ">
          <h1 className="text-base font-semibold leading-7 text-gray-900">Vady a nedodělky</h1>
          <Formik
            initialValues={{
              status: { value: searchParams.get('status') || '' },
              object: { value: searchParams.get('object') || '' },
            }}
            onSubmit={() => {}}
          >
            <IssuesFilter
              setSearchParams={setSearchParams}
              objects={objectsData?.data || []}
              hidePerPage={true}
            />
          </Formik>
          {isRefetchingIssues && <Spinner />}
          <a href="#" className="ml-auto flex items-center gap-x-1 px-3 py-2 text-sm text-white">
            <ButtonWithDropdown
              onClick={() =>
                navigate(
                  `${RoutesEnum.PROJECTS}/${project.id}/${RoutesEnum.ISSUES}/${
                    RoutesEnum.ISSUES_FORM
                  }?${searchParams.toString()}`,
                )
              }
              projectId={project.id}
              openPublicLink={publicLinkDisclosure.onOpen}
            />
          </a>
        </div>
      </div>
      <IssuesList issues={issuesData?.data?.data || []} projectId={project.id} />
      <div>
        {issuesData.data && (
          <PaginationButton
            page={Number(issuesData.data.page)}
            limit={Number(issuesData.data.limit)}
            totalCount={issuesData.data.totalCount}
            totalPages={issuesData.data.totalPages}
            setPage={(page: number) => setSearchParams({ page })}
          />
        )}
      </div>
    </ProjectLayout>
  )
}
