import { Field, FormikProps, useFormikContext } from 'formik'
import React from 'react'
import RadioInput from 'src/components/inputs/RadioInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { B6_FORM_INITIAL_VALUES } from './constants'

interface Props { }

export const B6VegetationAndTerrainForm: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<typeof B6_FORM_INITIAL_VALUES>
  return (
    <>
      <FormSectionTwoColumns
        sectionKey="b6.vegetationAndTerrain"
        title=""
      >
        <div className="sm:col-span-6 mt-3">
          <Field
            name="b6.vegetationAndTerrain.enabled"
            component={RadioInput}
            options={[
              {
                label: 'Ano',
                value: true,
              },
              {
                label: 'Ne',
                value: false,
              }
            ]
            }
            label="Je součástí projektu?"
          />
          {formik.values.b6.vegetationAndTerrain.enabled && (
            <Field name="b6.vegetationAndTerrain.description" component={RichTextInput} />
          )
          }
        </div>
      </FormSectionTwoColumns>
    </>
  )
}
