import React from 'react'
import { B2SpacialAndArchitecturalSolutions } from './sections/B2SpacialAndArchitecturalSolutions'

interface Props { }

export const B2UrbanisticSolution: React.FC<Props> = (props) => {
  return (
    <>
      <div className="mt-5">
        <B2SpacialAndArchitecturalSolutions />
      </div>
    </>
  )
}
