import { Field, FormikProps, useFormikContext } from 'formik'
import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import RadioInput from '../../../../../../components/inputs/RadioInput'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'
import { B3_FORM_INITIAL_VALUES } from '../constants'

interface Props { }

export const B36FireSafety: React.FC<Props> = () => {
    const formik = useFormikContext() as FormikProps<typeof B3_FORM_INITIAL_VALUES>
    return (
        <FormSectionTwoColumns
            sectionKey="b3.fireSafety"
            title="B.3.6 Zásady požární bezpečnosti"
        >
            <div className="sm:col-span-6 p-5 rounded-md">
                <Field
                    label={formik.values.b3.fireSafety.a.label}
                    name="b3.fireSafety.a.enabled"
                    component={RadioInput}
                    options={[
                        {
                            label: 'Řešeno samostatnou částí projektové dokumentace D.3',
                            value: false,
                        },
                        {
                            label: 'Jiné',
                            value: true,
                        },
                    ]}
                />
                {formik.values.b3.fireSafety.a.enabled === true && (
                    <div className="pl-7">
                        <div className="sm:col-span-6 mt-3">
                            <Field
                                name="b3.fireSafety.a.description"
                                label=""
                                component={RichTextInput}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="sm:col-span-6 p-5 rounded-md">
                <Field
                    label={formik.values.b3.fireSafety.b.label}
                    name="b3.fireSafety.b.enabled"
                    component={RadioInput}
                    options={[
                        {
                            label: 'Řešeno samostatnou částí projektové dokumentace D.3',
                            value: false,
                        },
                        {
                            label: 'Jiné',
                            value: true,
                        },
                    ]}
                />
                {formik.values.b3.fireSafety.b.enabled === true && (
                    <div className="pl-7">
                        <div className="sm:col-span-6 mt-3">
                            <Field
                                name="b3.fireSafety.b.description"
                                label=""
                                component={RichTextInput}
                            />
                        </div>
                    </div>
                )}
            </div>
        </FormSectionTwoColumns>
    )
}
