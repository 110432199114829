import { B7_FORM_INITIAL_VALUES as B7_FORM_INITIAL_VALUES_SHARED } from 'shared'

export const B7_FORM_INITIAL_VALUES = B7_FORM_INITIAL_VALUES_SHARED

export type B7FormValues = typeof B7_FORM_INITIAL_VALUES

export const B7NavOptions = [
  {
    label: 'a) vliv na životní prostředí a opatření vedoucí k minimalizaci negativních vlivů',
    target: 'b7.environmentalImpact',
  },
  {
    label:
      'b) způsob zohlednění podmínek závazného stanoviska posouzení vlivu záměru na životní prostředí',
    target: 'b7.eiaConsideration',
  },
  {
    label:
      'c) popis souladu záměru s oznámením záměru podle zákona o posuzování vlivů na životní prostředí',
    target: 'b7.eiaComplianceDescription',
  },
  {
    label: 'd) záměry spadající do režimu zákona o integrované prevenci',
    target: 'b7.integratedPermitCompliance',
  },
]
