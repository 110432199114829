import React, { Fragment, useEffect, useMemo } from 'react'
import { EMISSIONS_CATEGORIES, EMISSIONS_LIQUIDATION_METHODS } from './constants'
import { SelectInput } from '../../inputs/SelectInput'
import { Field, useFormikContext } from 'formik'
import { NumberInput } from '../../inputs/NumberInput'
import { Button } from '../../ui/button'
import { useDisclosure } from '@chakra-ui/react'
import { TailwindModal } from '../../TailwindModal'
import { get } from 'lodash'
import { formatNumberWithDelimiters } from '../../../model/helpers/numberHelpers'

interface Props {
  name: string
}

export const EmissionsTable: React.FC<Props> = (props) => {
  const formik = useFormikContext<any>()
  const values = get(formik.values, props.name, {})
  const editModeDisclosure = useDisclosure()
  const isInEditMode = editModeDisclosure.isOpen
  const jsonStringyfiedValues = JSON.stringify(values)
  const sum = useMemo(() => {
    return EMISSIONS_CATEGORIES.reduce((acc, category) => {
      const categoryValue = category.items.reduce((acc, item) => {
        const itemValue = get(values, `${item.code}.weight`, 0)
        return acc + Number(itemValue)
      }, 0)
      return acc + Number(categoryValue)
    }, 0)
  }, [jsonStringyfiedValues])

  const filledEmissionCategoriesAndItems = useMemo(() => {
    return EMISSIONS_CATEGORIES.map((emissionCategory) => {
      const filledItems = emissionCategory.items
        .filter((item) => {
          const itemValue = get(values, `${item.code}.weight`, 0)
          return itemValue > 0
        })
        .map((item) => ({
          ...item,
          weight: get(values, `${item.code}.weight`, 0),
          liquidation: get(values, `${item.code}.liquidation`, 0),
        }))
      return {
        ...emissionCategory,
        items: filledItems,
      }
    }).filter((category) => category.items.length > 0)
  }, [jsonStringyfiedValues])

  useEffect(() => {
    if (!isInEditMode) {
      return
    }

    setTimeout(() => {
      formik.setFieldValue('b1k.constructionBalanceWasteAndEmissions.sum', sum)
      formik.setFieldValue(
        'b1k.constructionBalanceWasteAndEmissions.filledEmissionCategoriesAndItems',
        filledEmissionCategoriesAndItems,
      )
    }, 0)
  }, [sum, filledEmissionCategoriesAndItems, isInEditMode])

  if (!editModeDisclosure.isOpen) {
    return (
      <div className="bg-gray-100 p-6 rounded-md">
        <table className="w-full text-left">
          <tbody>
            {filledEmissionCategoriesAndItems.map((emissionCategory) => (
              <Fragment key={emissionCategory.code}>
                <tr className="text-sm font-bold text-gray-900">
                  <td colSpan={3}>
                    {emissionCategory.code} {emissionCategory.name}
                  </td>
                </tr>
                {emissionCategory.items.map((emissionItem) => {
                  const itemValue = get(values, `${emissionItem.code}.weight`, 0)
                  const liquidationValue = get(values, `${emissionItem.code}.liquidation.value`, '')
                  return (
                    <tr key={emissionItem.code} className="">
                      <td className="text-sm font-medium text-gray-900">
                        {emissionItem.code} {emissionItem.name}
                      </td>
                      <td className="text-sm font-medium text-gray-900 text-right whitespace-nowrap">
                        {liquidationValue}
                      </td>
                      <td className="text-sm font-medium text-gray-900 text-right pl-3">
                        {formatNumberWithDelimiters(itemValue)}&nbsp;kg
                      </td>
                    </tr>
                  )
                })}
              </Fragment>
            ))}
            <tr>
              <td className="text-sm text-right font-bold" colSpan={2}>
                Celkem
              </td>
              <td className="text-sm text-right font-bold" colSpan={1}>
                {formatNumberWithDelimiters(sum)}&nbsp;Kg
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex justify-end mt-3">
          <Button type="button" variant="outline" onClick={editModeDisclosure.onOpen}>
            Upravit
          </Button>
        </div>
      </div>
    )
  }

  return (
    <TailwindModal
      disclosure={editModeDisclosure}
      dialogTitle="Celkové produkované množství, druhy a kategorie odpadů a emisí apod."
      className="sm:max-w-6xl"
    >
      <div className="mt-6 border-t border-gray-100">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <table className="w-full text-left">
              <thead className="">
                <tr>
                  <th className="w-72">Kategorie</th>
                  <th className="hidden sm:table-cell">Způsob likvidace</th>
                  <th className="hidden sm:table-cell text-right">Množství (kg)</th>
                </tr>
              </thead>
              <tbody>
                {EMISSIONS_CATEGORIES.map((emissionCategory) => (
                  <Fragment key={emissionCategory.code}>
                    <tr className="text-sm leading-6 text-gray-900">
                      <th
                        scope="colgroup"
                        colSpan={3}
                        className="relative isolate py-0 font-semibold"
                      >
                        {emissionCategory.code} {emissionCategory.name}
                        <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-100" />
                        <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-100" />
                      </th>
                    </tr>
                    {emissionCategory.items.map((emissionItem) => {
                      const fieldName = `${props.name}.${emissionItem.code}`
                      return (
                        <tr key={emissionItem.code}>
                          <td className="relative py-0 pr-6">
                            <div className="flex gap-x-6">
                              <div className="flex-auto">
                                <div className="flex items-start gap-x-3">
                                  <div className="text-sm font-medium leading-6 text-gray-900 flex flex-col gap-3 sm:block">
                                    <span>
                                      {emissionItem.code} {emissionItem.name}
                                    </span>
                                    <div className="sm:hidden">
                                      <Field
                                        name={`${fieldName}.liquidation`}
                                        component={SelectInput}
                                        options={EMISSIONS_LIQUIDATION_METHODS}
                                      />
                                    </div>
                                    <div className="sm:hidden">
                                      <Field
                                        name={`${fieldName}.weight`}
                                        component={NumberInput}
                                        trailing="kg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                            <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                          </td>
                          <td className="py-0 hidden sm:table-cell">
                            <Field
                              name={`${fieldName}.liquidation`}
                              component={SelectInput}
                              options={EMISSIONS_LIQUIDATION_METHODS}
                            />
                          </td>
                          <td className="py-0 pl-8 text-right hidden sm:table-cell w-48">
                            <Field
                              name={`${fieldName}.weight`}
                              component={NumberInput}
                              trailing="kg"
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </Fragment>
                ))}
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td className="py-6 text-right font-bold text-xl">
                    Celkem: {formatNumberWithDelimiters(sum)} kg
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </TailwindModal>
  )
}
