import React from 'react'
import { useProject } from '../../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { Field } from 'formik'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { B9_FORM_INITIAL_VALUES } from './constants'
import { TechnicalFormWrapper } from '../TechnicalFormWrapper'
import { ProjectFormEnum } from '../../../../../model/enums/ProjectFormEnum'

interface Props {}

export const B9Water: React.FC<Props> = (props) => {
  const { project } = useProject()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <TechnicalFormWrapper
      form={ProjectFormEnum.TECHNICAL_FORM_B9}
      initialValues={B9_FORM_INITIAL_VALUES}
      title="B.9 Celkové vodohospodářské řešení"
    >
      <FormSectionTwoColumns title="">
        <div className="sm:col-span-6">
          <Field name="b9.waterSolution" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
    </TechnicalFormWrapper>
  )
}
