import React from 'react'
import { Field, FormikProps } from 'formik'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Cv2: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      title="c) údaje o souladu stavby"
      subtitle="údaje o souladu stavby s územně plánovací dokumentací a územními opatřeními nebo s cíli a úkoly územního plánování, a s požadavky na ochranu kulturně historických, architektonických, archeologických a urbanistických hodnot v území"
      sectionKey="b1c"
    >
      <div className="sm:col-span-6 mt-5">
        <div className="flex flex-col gap-5">
          <div>
            <Field
              name="b1c.planningDocumentationCompliance"
              component={CheckboxInput}
              label="Soulad stavby s územně plánovací dokumentací a územními opatřeními"
            />
            <div className="pl-7">
              <Field name="b1c.planningDocumentationDescription" component={RichTextInput} />
            </div>
          </div>
          <div>
            <Field
              name="b1c.planningGoalsCompliance"
              component={CheckboxInput}
              label="Soulad stavby s cíly a úkoly územního plánování"
            />
            <div className="pl-7">
              <Field name="b1c.planningGoalsDescription" component={RichTextInput} />
            </div>
          </div>
          <div>
            <Field
              name="b1c.culturalHeritageCompliance"
              component={CheckboxInput}
              label="Soulad stavby s požadavky na ochranu kulturně historických, architektonických, archeologických a urbanistických hodnot v území"
            />
            <div className="pl-7">
              <Field name="b1c.culturalHeritageDescription" component={RichTextInput} />
            </div>
          </div>
        </div>
      </div>
    </FormSectionTwoColumns>
  )
}
