import React from 'react'
import { IWeatherDailySummary } from 'shared'
import { WeatherDetail, WeatherHeader, WeatherIcon, WeatherValue } from './WeatherCard'
import { CloudIcon, CloudRainIcon, LucideIcon, SunIcon, SunriseIcon } from 'lucide-react'
import { cn } from '../lib/utils'

interface Props {
  weatherData: IWeatherDailySummary
}

export const ShortWeatherCard: React.FC<Props> = (props) => {
  const precipitation = props.weatherData.precipitation.total
  const cloudCover = props.weatherData.cloud_cover.afternoon

  const [Icon, color]: [LucideIcon, string] = React.useMemo(() => {
    if (precipitation >= 13) {
      return [CloudRainIcon, 'text-blue-500']
    } else if (cloudCover > 50) {
      return [CloudIcon, 'text-gray-700']
    } else {
      return [SunIcon, 'text-yellow-600']
    }
  }, [precipitation, cloudCover])

  return (
    <div className="py-5 px-3 mr-3 flex flex-col items-center justify-center gap-3">
      <Icon size={48} className={cn(color)} />
      {Math.round(props.weatherData.temperature.afternoon)}°C
    </div>
  )
}
