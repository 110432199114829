"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskQueryStatus = exports.TechnicalFormStatusEnum = exports.ProjectFormEnum = exports.UserStatusEnum = exports.UserRoleEnum = void 0;
/**
 * USERS
 */
var UserRoleEnum;
(function (UserRoleEnum) {
    UserRoleEnum["ADMIN"] = "admin";
    UserRoleEnum["USER"] = "user";
})(UserRoleEnum || (exports.UserRoleEnum = UserRoleEnum = {}));
var UserStatusEnum;
(function (UserStatusEnum) {
    UserStatusEnum["ACTIVE"] = "active";
    UserStatusEnum["INACTIVE"] = "inactive";
    UserStatusEnum["INVITED"] = "invited";
})(UserStatusEnum || (exports.UserStatusEnum = UserStatusEnum = {}));
/**
 * Project (form)
 */
var ProjectFormEnum;
(function (ProjectFormEnum) {
    ProjectFormEnum["ACCOMPANYING_REPORT_FORM_A1"] = "ACCOMPANYING_REPORT_FORM_A1";
    ProjectFormEnum["TECHNICAL_FORM_B1"] = "TECHNICAL_FORM_B1";
    ProjectFormEnum["TECHNICAL_FORM_B2"] = "TECHNICAL_FORM_B2";
    ProjectFormEnum["TECHNICAL_FORM_B3"] = "TECHNICAL_FORM_B3";
    ProjectFormEnum["TECHNICAL_FORM_B4"] = "TECHNICAL_FORM_B4";
    ProjectFormEnum["TECHNICAL_FORM_B5"] = "TECHNICAL_FORM_B5";
    ProjectFormEnum["TECHNICAL_FORM_B6"] = "TECHNICAL_FORM_B6";
    ProjectFormEnum["TECHNICAL_FORM_B7"] = "TECHNICAL_FORM_B7";
    ProjectFormEnum["TECHNICAL_FORM_B8"] = "TECHNICAL_FORM_B8";
    ProjectFormEnum["TECHNICAL_FORM_B9"] = "TECHNICAL_FORM_B9";
    ProjectFormEnum["TECHNICAL_FORM_B10"] = "TECHNICAL_FORM_B10";
    ProjectFormEnum["CONSTRUCTION_DIARY_FORM"] = "CONSTRUCTION_DIARY_FORM";
})(ProjectFormEnum || (exports.ProjectFormEnum = ProjectFormEnum = {}));
var TechnicalFormStatusEnum;
(function (TechnicalFormStatusEnum) {
    TechnicalFormStatusEnum["EDIT"] = "EDIT";
    TechnicalFormStatusEnum["PREVIEW"] = "PREVIEW";
    TechnicalFormStatusEnum["DONE"] = "DONE";
})(TechnicalFormStatusEnum || (exports.TechnicalFormStatusEnum = TechnicalFormStatusEnum = {}));
var TaskQueryStatus;
(function (TaskQueryStatus) {
    TaskQueryStatus["DONE"] = "done";
    TaskQueryStatus["NOT_DONE"] = "not-done";
    TaskQueryStatus["ALL"] = "all";
})(TaskQueryStatus || (exports.TaskQueryStatus = TaskQueryStatus = {}));
