import { Field, FormikProps, useFormikContext } from 'formik'
import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../../components/inputs/CheckboxInput'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'
import { B3_FORM_INITIAL_VALUES } from '../constants'

interface Props { }

export const B39ProtectionAgainstEnvironmentalFactors: React.FC<Props> = (props) => {
    const formik = useFormikContext() as FormikProps<typeof B3_FORM_INITIAL_VALUES>
    return (
        <>
            <FormSectionTwoColumns sectionKey="b3.protectionAgainstEnvironmentalFactors" title="B.3.9 Zásady ochrany stavby před negativními účinky vnějšího prostředí">
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.protectionAgainstEnvironmentalFactors.floodMeasures.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.protectionAgainstEnvironmentalFactors.floodMeasures.label}
                    />
                    {formik.values.b3.protectionAgainstEnvironmentalFactors.floodMeasures.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.protectionAgainstEnvironmentalFactors.floodMeasures.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.protectionAgainstEnvironmentalFactors.radonLeaks.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.protectionAgainstEnvironmentalFactors.radonLeaks.label}
                    />
                    {formik.values.b3.protectionAgainstEnvironmentalFactors.radonLeaks.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.protectionAgainstEnvironmentalFactors.radonLeaks.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.protectionAgainstEnvironmentalFactors.protectionAgainstStrayCurrents.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.protectionAgainstEnvironmentalFactors.protectionAgainstStrayCurrents.label}
                    />
                    {formik.values.b3.protectionAgainstEnvironmentalFactors.protectionAgainstStrayCurrents.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.protectionAgainstEnvironmentalFactors.protectionAgainstStrayCurrents.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.protectionAgainstEnvironmentalFactors.protectionAgainstSeismicity.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.protectionAgainstEnvironmentalFactors.protectionAgainstSeismicity.label}
                    />
                    {formik.values.b3.protectionAgainstEnvironmentalFactors.protectionAgainstSeismicity.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.protectionAgainstEnvironmentalFactors.protectionAgainstSeismicity.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.protectionAgainstEnvironmentalFactors.protectionAgainstGroundwater.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.protectionAgainstEnvironmentalFactors.protectionAgainstGroundwater.label}
                    />
                    {formik.values.b3.protectionAgainstEnvironmentalFactors.protectionAgainstGroundwater.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.protectionAgainstEnvironmentalFactors.protectionAgainstGroundwater.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.protectionAgainstEnvironmentalFactors.protectionAgainstNoise.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.protectionAgainstEnvironmentalFactors.protectionAgainstNoise.label}
                    />
                    {formik.values.b3.protectionAgainstEnvironmentalFactors.protectionAgainstNoise.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.protectionAgainstEnvironmentalFactors.protectionAgainstNoise.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.protectionAgainstEnvironmentalFactors.influenceOfUndermining.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.protectionAgainstEnvironmentalFactors.influenceOfUndermining.label}
                    />
                    {formik.values.b3.protectionAgainstEnvironmentalFactors.influenceOfUndermining.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.protectionAgainstEnvironmentalFactors.influenceOfUndermining.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
                <div className="sm:col-span-6 mt-5">
                    <Field
                        name={`b3.protectionAgainstEnvironmentalFactors.methaneOccurrence.enabled`}
                        component={CheckboxInput}
                        label={formik.values.b3.protectionAgainstEnvironmentalFactors.methaneOccurrence.label}
                    />
                    {formik.values.b3.protectionAgainstEnvironmentalFactors.methaneOccurrence.enabled && (
                        <>
                            <div className="mt-3 pl-7">
                                <Field name={`b3.protectionAgainstEnvironmentalFactors.methaneOccurrence.description`} component={RichTextInput} />
                            </div>
                        </>
                    )}
                </div>
            </FormSectionTwoColumns>
        </>
    )
}
