export const B1NavOptions = [
  {
    label: 'a) Základní popis stavby',
    target: 'b1a',
  },
  {
    label: 'b) Charakteristika území a stavebního pozemku',
    target: 'b1b',
  },
  {
    label: 'c) údaje o souladu stavby',
    target: 'b1c',
  },
  {
    label: 'd) výčet a závěry průzkumů',
    target: 'b1d',
  },
  {
    label: 'e) informace o nutnosti povolení výjimky z požadavků na výstavbu',
    target: 'b1e',
  },
  {
    label: 'f) stávající ochrana území a stavby podle jiných právních předpisů',
    target: 'b1f',
  },
  {
    label: 'g) vliv stavby na okolí',
    target: 'b1g',
  },
  {
    label:
      'h) požadavky na maximální dočasné a trvalé zábory zemědělského půdního fondu nebo pozemků určených k plnění funkce lesa',
    target: 'b1h',
  },
  {
    label: 'i) navrhovaná a vznikající ochranná a bezpečnostní pásma',
    target: 'b1i',
  },
  {
    label: 'j) navrhované parametry stavby',
    target: 'b1j',
  },
  {
    label: 'k) limitní bilance stavby',
    target: 'b1k',
  },
  {
    label: 'l) požadavky na kapacity veřejných sítí',
    target: 'b1l',
  },
  {
    label: 'm) základní předpoklady výstavby',
    target: 'b1m',
  },
  {
    label: 'n) základní požadavky na předčasné užívání staveb a zkušební provoz staveb',
    target: 'b1n',
  },
  {
    label: 'o) seznam výsledků zeměměřických činností podle jiného právního předpisu',
    target: 'b1o',
  },
]
