import React, { useCallback, useEffect } from 'react'
import { Field, FormikProps } from 'formik'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { SelectInput } from '../../../../../components/inputs/SelectInput'
import { TYPE_OF_PLOT_SELECT_OPTIONS } from '../../../constants/options'
import { TextAreaInput } from '../../../../../components/inputs/TextAreaInput'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Button } from '../../../../../components/ui/button'
import { InfoPopover } from '../../../../../components/InfoPopover'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { Carousel, CarouselNext, CarouselPrevious } from 'src/components/ui/carousel'
import {
  CarouselContent,
  CarouselItem,
  type CarouselApi,
} from '../../../../../components/ui/carousel'
import { Card, CardContent } from '../../../../../components/ui/card'
import { SimpleSelect } from '../../../../../components/SimpleSelect'
import { useGetParcels } from '../../../../../model/api/project/useGetParcels'
import { useProject } from '../../../../../model/hooks/useProject'
import { Link, Routes } from 'react-router-dom'
import { RoutesEnum } from '../../../../../model/enums/RoutesEnum'
import { get } from 'lodash'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Bv2: React.FC<Props> = ({ formik }) => {
  const drainageMap =
    'https://heis.vuv.cz/data/webmap/isapi.dll?map=isvs_zapluz&lon=17.6689321&lat=49.1977879&scale=1890'
  const minningMap = 'https://mapy.geology.cz/dulni_dila_poddolovani/'
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isOpen: miningIsOpen, onClose: miningOnClose, onOpen: mininOnOpen } = useDisclosure()

  return (
    <>
      <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mapa záplavového území</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody>
            <iframe src={drainageMap} width="100%" height="750px" />
          </ModalBody>
          <ModalFooter>
            <a target="_blank" className="flex gap-1 items-center" href={drainageMap}>
              Otevřít na stránce poskytovatele <ExternalLinkIcon mx="2px" />
            </a>
            <Button onClick={onClose} className="ml-3">
              Zavřít
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal size="4xl" isOpen={miningIsOpen} onClose={miningOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mapa poddolovaného území</ModalHeader>
          <ModalCloseButton onClick={miningOnClose} />
          <ModalBody>
            <iframe src={minningMap} width="100%" height="750px" />
          </ModalBody>
          <ModalFooter>
            <a target="_blank" className="flex gap-1 items-center" href={minningMap}>
              Otevřít na stránce poskytovatele <ExternalLinkIcon mx="2px" />
            </a>
            <Button onClick={miningOnClose} className="ml-3">
              Zavřít
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <FormSectionTwoColumns
        title="b) charakteristika území a stavebního pozemku"
        subtitle={
          <>
            <p className="mb-3">
              dosavadní využití a zastavěnost území, poloha vzhledem k záplavovému území,
              poddolovanému území apod.
            </p>
            <div className="flex flex-col gap-2">
              <Button variant="secondary" onClick={onOpen}>
                Mapa záplavového území
              </Button>
              <Button variant="secondary" onClick={mininOnOpen}>
                Mapa poddolovaného území
              </Button>
            </div>
          </>
        }
        sectionKey={'b1b'}
      >
        <div className="sm:col-span-6 p-8">
          <ParcelsCarousel formik={formik} />
        </div>
      </FormSectionTwoColumns>
    </>
  )
}

const ParcelsCarousel = ({ formik }: { formik: FormikProps<any> }) => {
  const { id: projectId } = useProject()
  const { data, isLoading } = useGetParcels(projectId)
  const [carouselApi, setCarouselApi] = React.useState<CarouselApi>()
  const [slideInView, setSlideInView] = React.useState(0)

  React.useEffect(() => {
    if (data?.data?.length > 0 && get(formik.values, 'b1b.places', []).length === 0) {
      console.log('Setting places')
      formik.setFieldValue('b1b.places', data?.data)
    }
  }, [data, formik])

  if (isLoading) {
    return <div>Načítám parcely...</div>
  }

  if (data?.data?.length === 0) {
    return (
      <div>
        Nebyly nalezeny žádné parcely. Pro založení parcel přejděte na{' '}
        <Link
          target="_blank"
          to={`${RoutesEnum.PROJECTS}/${projectId}/${RoutesEnum.ACCOMPANYING_REPORT}`}
        >
          <span className="text-blue-600 underline">Průvodní list</span>
        </Link>
      </div>
    )
  }

  carouselApi?.on('slidesInView', (args) => {
    setSlideInView(args?.slidesInView()[0] ?? 0)
    console.log({ slideInView, raw: args?.slidesInView() })
  })

  const parcels = data?.data || []
  const parcelToLabel = (parcel: any) =>
    [
      `Parcela číslo ${parcel.parcelNumber}`,
      parcel.municipality?.obecNazev,
      parcel.municipality?.krajNazev,
    ].join(', ')

  const options = parcels.map((parcel: any, index: any) => ({
    label: parcelToLabel(parcel),
    value: index,
  }))

  const selected = options.find((option: any) => option.value === slideInView)!

  return (
    <div className="flex flex-col gap-1">
      <div className="mx-1">
        <SimpleSelect
          options={options}
          onSelected={(option) => {
            setSlideInView(option.value as number)
            carouselApi?.scrollTo(option.value as number)
          }}
          selected={selected}
        />
      </div>
      <Carousel className="w-full" setApi={setCarouselApi}>
        <CarouselContent className="">
          {parcels.map((parcel: any, index: any) => (
            <CarouselItem key={index} className="">
              <div className="p-1">
                <Card className="">
                  <CardContent className="flex aspect-square items-center justify-center p-6">
                    <Fields index={index} label={parcelToLabel(parcel)} formik={formik} />
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  )
}

const Fields = ({
  formik,
  label,
  index,
}: {
  formik: FormikProps<any>
  label?: string
  index: number
}) => {
  const places = get(formik.values, 'b1b.places', [])
  const currentPlace = places[index]

  return (
    <div className="sm:col-span-6 flex flex-col gap-3">
      <p className="text-center font-semibold text-md mb-3">{label}</p>
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name={`b1b.places[${index}].characteristics.isBuildingPlot`}
          label="Stavební pozemek"
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          component={SelectInput}
          name={`b1b.places[${index}].characteristics.typeOfPlot`}
          options={TYPE_OF_PLOT_SELECT_OPTIONS}
          label="Druh pozemku podle katastru nemovitostí"
        />
      </div>
      <div className="sm:col-span-6 flex flex-col gap-3">
        <Field
          component={SelectInput}
          name={`b1b.places[${index}].characteristics.placement`}
          options={[
            { label: 'Střední část obce', value: 'Střední část obce' },
            { label: 'Severní část obce', value: 'Severní část obce' },
            { label: 'Jižní část obce', value: 'Jižní část obce' },
            { label: 'Východní část obce', value: 'Východní část obce' },
            { label: 'Západní část obce', value: 'Západní část obce' },
          ]}
          label="Umístění"
          isCustom={currentPlace?.characteristics?.placement?.custom}
          setIsCustom={(value: any) =>
            formik.setFieldValue(`b1b.places[${index}].characteristics.placement.custom`, value)
          }
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          component={SelectInput}
          name={`b1b.places[${index}].characteristics.shape`}
          options={[
            { label: 'Čtverec', value: 'Čtverec' },
            { label: 'Obdélník', value: 'Obdélník' },
            { label: 'Lichoběžník', value: 'Lichoběžník' },
            { label: 'Nepravidelný tvar', value: 'Nepravidelný tvar' },
          ]}
          label="Tvar"
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          component={SelectInput}
          name={`b1b.places[${index}].characteristics.landSlope`}
          options={[
            { label: 'Rovinatý pozemek', value: 'Rovinatý pozemek' },
            { label: 'Mírně svažitý pozemek', value: 'Mírně svažitý pozemek' },
            { label: 'Svažitý pozemek', value: 'Svažitý pozemek' },
            { label: 'Strmý pozemek', value: 'Strmý pozemek' },
          ]}
          label="Svažitost parcely"
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          component={SelectInput}
          name={`b1b.places[${index}].characteristics.urbanization`}
          options={[
            { label: 'Hustě zastavěné území', value: 'Hustě zastavěné území' },
            { label: 'Zřídka zastavěné území', value: 'Zřídka zastavěné území' },
            { label: 'Málo zastavěné území', value: 'Málo zastavěné území' },
            { label: 'Nezastavěné území', value: 'Nezastavěné území' },
          ]}
          label="Zastavěnost"
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          component={SelectInput}
          name={`b1b.places[${index}].characteristics.surroundingArea`}
          options={[
            { label: 'Rodinné domy', value: 'Rodinné domy' },
            { label: 'Bytové domy', value: 'Bytové domy' },
            { label: 'Účelové hřiště', value: 'Účelové hřiště' },
            { label: 'Občanská vybavenost', value: 'Občanská vybavenost' },
            { label: 'Zahrady', value: 'Zahrady' },
            { label: 'Chaty', value: 'Chaty' },
            { label: 'Pole', value: 'Pole' },
          ]}
          label="Okolí"
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          component={SelectInput}
          name={`b1b.places[${index}].characteristics.previousUtilization`}
          options={[
            {
              label: 'Stávající stavba',
              value: 'Stávající stavba',
            },
            { label: 'Zahrada', value: 'Zahrada' },
            { label: 'Pole', value: 'Pole' },
            { label: 'Žádné', value: 'Žádné' },
          ]}
          label="Dosavadní využití"
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name={`b1b.places[${index}].characteristics.landscapeCharacter`}
          label="Soulad s charaterem území"
        />
        {!currentPlace?.characteristics?.landscapeCharacter && (
          <div className="mt-3 pl-7">
            <Field
              component={TextAreaInput}
              name={`b1b.places[${index}].characteristics.landscapeCharacterDescription`}
              label="Odůvodnění"
            />
          </div>
        )}
      </div>
      <div className="sm:col-span-6">
        <div className="flex">
          <Field
            component={CheckboxInput}
            name={`b1b.places[${index}].characteristics.floodArea.enabled`}
            label="Záplavové území"
          />
        </div>
      </div>
      {currentPlace?.characteristics?.floodArea?.enabled && (
        <div className="sm:col-span-6 pl-7">
          <Field
            component={RichTextInput}
            name={`b1b.places[${index}].characteristics.floodArea.measures`}
            label="Opatření"
          />
        </div>
      )}
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name={`b1b.places[${index}].characteristics.underminedArea.enabled`}
          label="Poddolované území"
        />
      </div>
      {currentPlace?.characteristics?.underminedArea?.enabled && (
        <div className="sm:col-span-6 pl-7">
          <Field
            component={RichTextInput}
            name={`b1b.places[${index}].characteristics.underminedArea.measures`}
            label="Opatření"
          />
        </div>
      )}
    </div>
  )
}
