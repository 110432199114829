import { ACCOMPANYING_REPORT_FORM_INITIAL_DATA as ACCOMPANYING_REPORT_FORM_INITIAL_DATA_SHARED } from 'shared'
import { ISelectOption } from '../../../../model/interfaces/ISelectOption'
import {FileTreeElement} from "../../../../components/inputs/FileTreeInput";

export const ACCOMPANYING_REPORT_FORM_INITIAL_DATA = {
  ...ACCOMPANYING_REPORT_FORM_INITIAL_DATA_SHARED,
}

export const ACCOMPANYING_REPORT_A3_CONSTRUCTION_TYPE: ISelectOption[] = [
  {
    value: '1 Nezjištěno',
    label: '1 Nezjištěno',
  },
  {
    value: '2 Zděný z nepálených cihel',
    label: '2 Zděný z nepálených cihel',
  },
  {
    value: '3 Zděný cihelný',
    label: '3 Zděný cihelný',
  },
  {
    value: '4 Zděný kamenný',
    label: '4 Zděný kamenný',
  },
  {
    value: '5 Zděný jiné',
    label: '5 Zděný jiné',
  },
  {
    value: '6 Zděný smíšený',
    label: '6 Zděný smíšený',
  },
  {
    value: '7 Monolitický betonový',
    label: '7 Monolitický betonový',
  },
  {
    value: '8 Montované stěnové panely betonové',
    label: '8 Montované stěnové panely betonové',
  },
  {
    value: '9 Montované stěnové panely dřevěné',
    label: '9 Montované stěnové panely dřevěné',
  },
  {
    value: '10 Montované stěnové panely jiné',
    label: '10 Montované stěnové panely jiné',
  },
  {
    value: '11 Dřevo – sruby a roubenky',
    label: '11 Dřevo – sruby a roubenky',
  },
  {
    value: '12 Dřevo – lehký rámcový skelet – panelová montáž',
    label: '12 Dřevo – lehký rámcový skelet – panelová montáž',
  },
  {
    value: '13 Dřevo – lehký rámcový skelet – staveništní montáž',
    label: '13 Dřevo – lehký rámcový skelet – staveništní montáž',
  },
  {
    value: '14 Dřevo – těžký skelet',
    label: '14 Dřevo – těžký skelet',
  },
  {
    value: '15 Panely z masivního dřeva',
    label: '15 Panely z masivního dřeva',
  },
  {
    value: '16 Dřevo – ostatní dřevěné konstrukce',
    label: '16 Dřevo – ostatní dřevěné konstrukce',
  },
  {
    value: '17 Kombinovaný',
    label: '17 Kombinovaný',
  },
  {
    value: '18 Speciální a jiné',
    label: '18 Speciální a jiné',
  },
]

export const ACCOMPANYING_REPORT_A3_HEATHING_METHOD_OPTIONS: ISelectOption[] = [
  {
    value: '1 Nezjištěno',
    label: '1 Nezjištěno',
  },
  {
    value: '2 Ústřední dálkové',
    label: '2 Ústřední dálkové',
  },
  {
    value: '3 Ústřední domovní',
    label: '3 Ústřední domovní',
  },
  {
    value: '4 Ústřední bytové',
    label: '4 Ústřední bytové',
  },
  {
    value: '5 Kombinované',
    label: '5 Kombinované',
  },
  {
    value: '6 Lokální zdroje',
    label: '6 Lokální zdroje',
  },
  {
    value: '7 Bez vytápění',
    label: '7 Bez vytápění',
  },
]

export const ACCOMPANYING_REPORT_A3_WATER_CONNECTION_OPTIONS: ISelectOption[] = [
  {
    value: '1 Nezjištěno',
    label: '1 Nezjištěno',
  },
  {
    value: '2 bez zdroje',
    label: '2 bez zdroje',
  },
  {
    value: '3 Centrální zdroj',
    label: '3 Centrální zdroj',
  },
  {
    value: '4 Lokální zdroj',
    label: '4 Lokální zdroj',
  },
  {
    value: '5 Lokální zásobník',
    label: '5 Lokální zásobník',
  },
  {
    value: '6 Kombinované',
    label: '6 Kombinované',
  },
]

export const ACCOMPANYING_REPORT_A3_SEWER_CONNECTION_OPTIONS: ISelectOption[] = [
  {
    value: '1 Nezjištěno',
    label: '1 Nezjištěno',
  },
  {
    value: '2 Bez zajištění',
    label: '2 Bez zajištění',
  },
  {
    value: '3 Jednotná kanalizace s centrální čistírnou odpadních vod',
    label: '3 Jednotná kanalizace s centrální čistírnou odpadních vod',
  },
  {
    value: '4 Jednotná kanalizace bez centrální čistírny odpadních vod',
    label: '4 Jednotná kanalizace bez centrální čistírny odpadních vod',
  },
  {
    value: '5 Oddílná kanalizace s centrální čistírnou odpadních vod',
    label: '5 Oddílná kanalizace s centrální čistírnou odpadních vod',
  },
  {
    value: '6 Lokální čistírna odpadních vod',
    label: '6 Lokální čistírna odpadních vod',
  },
  {
    value: '7 Lokální zásobník',
    label: '7 Lokální zásobník',
  },
  {
    value: '8 Kombinované',
    label: '8 Kombinované',
  },
]

export const ACCOMPANYING_REPORT_A3_GAS_CONNECTION_OPTIONS: ISelectOption[] = [
  {
    value: '1 Nezjištěno',
    label: '1 Nezjištěno',
  },
  {
    value: '2 Bez zdroje',
    label: '2 Bez zdroje',
  },
  {
    value: '3 Veřejná síť',
    label: '3 Veřejná síť',
  },
  {
    value: '4 Domovní zásobník – bez rozlišení',
    label: '4 Domovní zásobník – bez rozlišení',
  },
  {
    value: '5 Domovní zásobník – LPG',
    label: '5 Domovní zásobník – LPG',
  },
  {
    value: '6 Domovní zásobník – CNG',
    label: '6 Domovní zásobník – CNG',
  },
  {
    value: '7 Domovní zásobník – LNG',
    label: '7 Domovní zásobník – LNG',
  },
  {
    value: '8 Domovní zásobník – bioplyn',
    label: '8 Domovní zásobník – bioplyn',
  },
  {
    value: '9 Domovní zásobník – degazační',
    label: '9 Domovní zásobník – degazační',
  },
  {
    value: '10 Domovní zásobník – jiné',
    label: '10 Domovní zásobník – jiné',
  },
  {
    value: '11 Kombinované',
    label: '11 Kombinované',
  },
]

export const ACCOMPANYING_REPORT_A3_ELEVATOR_OPTIONS: ISelectOption[] = [
  {
    value: '1 Nezjištěno',
    label: '1 Nezjištěno',
  },
  {
    value: '2 S výtahem',
    label: '2 S výtahem',
  },
  {
    value: '3 S výtahem bezbariérovým',
    label: '3 S výtahem bezbariérovým',
  },
  {
    value: '4 Bez výtahu',
    label: '4 Bez výtahu',
  },
]

export const USAGE_METHOD_OPTIONS: FileTreeElement[] = [
  {
    id: '1',
    isSelectable: true,
    name: '1. Budovy',
    children: [
      {
        id: '1.1',
        isSelectable: true,
        name: '1.1 Budovy bytové',
        children: [
          {
            id: '1.1.1',
            isSelectable: true,
            name: '1.1.1 Budovy jednobytové',
            children: [
              {
                id: '1.1.1.0',
                isSelectable: true,
                name: '1.1.1.0 Budovy jednobytové',
                children: [
                  {
                    id: '1.1.1.0.1.1',
                    isSelectable: true,
                    name: '1.1.1.0.1.1 Budovy jednobytové',
                  },
                  {
                    id: '1.1.1.0.1.2',
                    isSelectable: true,
                    name: '1.1.1.0.1.2 Budovy jednobytové se služebním vybavením',
                  },
                  {
                    id: '1.1.1.0.2.1',
                    isSelectable: true,
                    name: '1.1.1.0.2.1 Chalupy pro rekreaci jednobytové',
                  },
                  {
                    id: '1.1.1.0.2.2',
                    isSelectable: true,
                    name: '1.1.1.0.2.2 Chaty pro rekreaci jednobytové',
                  },
                  {
                    id: '1.1.1.0.9.1',
                    isSelectable: true,
                    name: '1.1.1.0.9.1 Oplocení budov 1110',
                  },
                ],
              },
            ],
          },
          {
            id: '1.1.2',
            isSelectable: true,
            name: '1.1.2 Budovy dvou a vícebytové',
            children: [
              {
                id: '1.1.2.1',
                isSelectable: true,
                name: '1.1.2.1 Budovy dvoubytové',
                children: [
                  {
                    id: '1.1.2.1.1.1',
                    isSelectable: true,
                    name: '1.1.2.1.1.1 Budovy dvoubytové',
                  },
                  {
                    id: '1.1.2.1.1.2',
                    isSelectable: true,
                    name: '1.1.2.1.1.2 Budovy dvoubytové se služebním vybavením',
                  },
                  {
                    id: '1.1.2.1.2.1',
                    isSelectable: true,
                    name: '1.1.2.1.2.1 Chalupy pro rekreaci dvoubytové',
                  },
                  {
                    id: '1.1.2.1.2.2',
                    isSelectable: true,
                    name: '1.1.2.1.2.2 Chaty pro rekreaci dvoubytové',
                  },
                  {
                    id: '1.1.2.1.9.1',
                    isSelectable: true,
                    name: '1.1.2.1.9.1 Oplocení budov 1121',
                  },
                ],
              },
              {
                id: '1.1.2.2',
                isSelectable: true,
                name: '1.1.2.2 Budovy tří a vícebytové',
                children: [
                  {
                    id: '1.1.2.2.1.3',
                    isSelectable: true,
                    name: '1.1.2.2.1.3 Budovy tříbytové – typové',
                  },
                  {
                    id: '1.1.2.2.1.4',
                    isSelectable: true,
                    name: '1.1.2.2.1.4 Budovy tříbytové – netypové',
                  },
                  {
                    id: '1.1.2.2.1.5',
                    isSelectable: true,
                    name: '1.1.2.2.1.5 Budovy čtyř a vícebytové – typové',
                  },
                  {
                    id: '1.1.2.2.1.6',
                    isSelectable: true,
                    name: '1.1.2.2.1.6 Budovy čtyř a vícebytové – netypové',
                  },
                  {
                    id: '1.1.2.2.2.1',
                    isSelectable: true,
                    name: '1.1.2.2.2.1 Chalupy pro rekreaci tří a vícebytové',
                  },
                  {
                    id: '1.1.2.2.2.2',
                    isSelectable: true,
                    name: '1.1.2.2.2.2 Chaty pro rekreaci tří a vícebytové',
                  },
                  {
                    id: '1.1.2.2.9.1',
                    isSelectable: true,
                    name: '1.1.2.2.9.1 Oplocení budov 1122',
                  },
                ],
              },
            ],
          },
          {
            id: '1.1.3',
            isSelectable: true,
            name: '1.1.3 Budovy bytové ostatní',
            children: [
              {
                id: '1.1.3.1',
                isSelectable: true,
                name: '1.1.3.1 Budovy bytové ostatní',
                children: [
                  {
                    id: '1.1.3.0.1.1',
                    isSelectable: true,
                    name: '1.1.3.0.1.1 Budovy se službami sociální péče',
                  },
                  {
                    id: '1.1.3.0.1.2',
                    isSelectable: true,
                    name: '1.1.3.0.1.2 Budovy pro ubytování studentů, zaměstnanců apod.',
                  },
                  {
                    id: '1.1.3.0.1.9',
                    isSelectable: true,
                    name: '1.1.3.0.1.9 Budovy bytové ostatní j. n.',
                  },
                  {
                    id: '1.1.3.0.9.1',
                    isSelectable: true,
                    name: '1.1.3.0.9.1 Oplocení budov 1130',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '1.2',
        isSelectable: true,
        name: '1.2 Budovy nebytové',
        children: [
          {
            id: '1.2.1',
            isSelectable: true,
            name: '1.2.1 Hotely a obdobné budovy',
            children: [
              {
                id: '1.2.1.1',
                isSelectable: true,
                name: '1.2.1.1 Hotely',
                children: [
                  {
                    id: '1.2.1.1.1.1',
                    isSelectable: true,
                    name: '1.2.1.1.1.1 Budovy hotelů a podobných ubytovacích zařízení',
                  },
                  {
                    id: '1.2.1.1.1.2',
                    isSelectable: true,
                    name: '1.2.1.1.1.2 Budovy restaurací, barů, kaváren',
                  },
                  {
                    id: '1.2.1.1.9.1',
                    isSelectable: true,
                    name: '1.2.1.1.9.1 Oplocení budov 1211',
                  },
                ],
              },
              {
                id: '1.2.1.2',
                isSelectable: true,
                name: '1.2.1.2 Budovy ostatní pro krátkodobé ubytování',
                children: [
                  {
                    id: '1.2.1.2.1.1',
                    isSelectable: true,
                    name: '1.2.1.2.1.1 Budovy ostatní pro krátkodobé ubytování (kromě chat bez bytu)',
                  },
                  {
                    id: '1.2.1.2.1.2',
                    isSelectable: true,
                    name: '1.2.1.2.1.2 Chaty bez bytu',
                  },
                  {
                    id: '1.2.1.2.9.1',
                    isSelectable: true,
                    name: '1.2.1.2.9.1 Oplocení budov 1212',
                  },
                ],
              },
            ],
          },
          {
            id: '1.2.2',
            isSelectable: true,
            name: '1.2.2 Budovy administrativní',
            children: [
              {
                id: '1.2.2.0',
                isSelectable: true,
                name: '1.2.2.0 Budovy administrativní',
                children: [
                  {
                    id: '1.2.2.0.1.1',
                    isSelectable: true,
                    name: '1.2.2.0.1.1 Budovy peněžních ústavů',
                  },
                  {
                    id: '1.2.2.0.1.2',
                    isSelectable: true,
                    name: '1.2.2.0.1.2 Budovy veřejné správy',
                  },
                  {
                    id: '1.2.2.0.1.3',
                    isSelectable: true,
                    name: '1.2.2.0.1.3 Budovy pošt',
                  },
                  {
                    id: '1.2.2.0.1.9',
                    isSelectable: true,
                    name: '1.2.2.0.1.9 Budovy administrativní ostatní',
                  },
                  {
                    id: '1.2.2.0.9.1',
                    isSelectable: true,
                    name: '1.2.2.0.9.1 Oplocení budov 1220',
                  },
                ],
              },
            ],
          },
          {
            id: '1.2.3',
            isSelectable: true,
            name: '1.2.3 Budovy pro obchod',
            children: [
              {
                id: '1.2.3.0',
                isSelectable: true,
                name: '1.2.3.0 Budovy pro obchod',
                children: [
                  {
                    id: '1.2.3.0.1.1',
                    isSelectable: true,
                    name: '1.2.3.0.1.1 Budovy obchodních domů',
                  },
                  {
                    id: '1.2.3.0.1.2',
                    isSelectable: true,
                    name: '1.2.3.0.1.2 Budovy pro obchod a služby',
                  },
                  {
                    id: '1.2.3.0.7.9',
                    isSelectable: true,
                    name: '1.2.3.0.7.9 Podzemní obchodní střediska',
                  },
                  {
                    id: '1.2.3.0.9.1',
                    isSelectable: true,
                    name: '1.2.3.0.9.1 Oplocení budov 1230',
                  },
                ],
              },
            ],
          },
          {
            id: '1.2.4',
            isSelectable: true,
            name: '1.2.4 Budovy pro dopravu a telekomunikace',
            children: [
              {
                id: '1.2.4.1',
                isSelectable: true,
                name: '1.2.4.1 Budovy pro telekomunikace, nádraží, terminály a budovy k nim příslušející',
                children: [
                  {
                    id: '1.2.4.1.1.1',
                    isSelectable: true,
                    name: '1.2.4.1.1.1 Nádraží, terminály a budovy k nim příslušející',
                  },
                  {
                    id: '1.2.4.1.1.2',
                    isSelectable: true,
                    name: '1.2.4.1.1.2 Budovy pro telekomunikace, rozhlasové a televizní vysílání',
                  },
                  {
                    id: '1.2.4.1.1.3',
                    isSelectable: true,
                    name: '1.2.4.1.1.3 Zastřešená nástupiště a rampy',
                  },
                  {
                    id: '1.2.4.1.1.4',
                    isSelectable: true,
                    name: '1.2.4.1.1.4 Budovy letišť',
                  },
                  {
                    id: '1.2.4.1.1.5',
                    isSelectable: true,
                    name: '1.2.4.1.1.5 Budovy (věže) majáků',
                  },
                  {
                    id: '1.2.4.1.7.9',
                    isSelectable: true,
                    name: '1.2.4.1.7.9 Podzemní budovy pro telekomunikace, nádraží a terminály',
                  },
                  {
                    id: '1.2.4.1.9.1',
                    isSelectable: true,
                    name: '1.2.4.1.9.1 Oplocení budov 1241',
                  },
                ],
              },
              {
                id: '1.2.4.2',
                isSelectable: true,
                name: '1.2.4.2 Garáže',
                children: [
                  {
                    id: '1.2.4.2.1.1',
                    isSelectable: true,
                    name: '1.2.4.2.1.1 Garáže nadzemní',
                  },
                  {
                    id: '1.2.4.2.7.9',
                    isSelectable: true,
                    name: '1.2.4.2.7.9 Garáže podzemní',
                  },
                  {
                    id: '1.2.4.2.9.1',
                    isSelectable: true,
                    name: '1.2.4.2.9.1 Oplocení budov 1242',
                  },
                ],
              },
            ],
          },
          {
            id: '1.2.5',
            isSelectable: true,
            name: '1.2.5 Budovy pro průmysl a skladování',
            children: [
              {
                id: '1.2.5.1',
                isSelectable: true,
                name: '1.2.5.1 Budovy pro průmysl',
                children: [
                  {
                    id: '1.2.5.1.1.1',
                    isSelectable: true,
                    name: '1.2.5.1.1.1 Budovy pro průmysl',
                  },
                  {
                    id: '1.2.5.1.1.2',
                    isSelectable: true,
                    name: '1.2.5.1.1.2 Budovy výrobní pro energetiku',
                  },
                  {
                    id: '1.2.5.1.1.3',
                    isSelectable: true,
                    name: '1.2.5.1.1.3 Budovy vodního hospodářství, čistíren a úpraven vod',
                  },
                  {
                    id: '1.2.5.1.7.9',
                    isSelectable: true,
                    name: '1.2.5.1.7.9 Podzemní budovy pro průmysl',
                  },
                  {
                    id: '1.2.5.1.9.1',
                    isSelectable: true,
                    name: '1.2.5.1.9.1 Oplocení budov 1251',
                  },
                ],
              },
              {
                id: '1.2.5.2',
                isSelectable: true,
                name: '1.2.5.2 Budovy skladů, nádrže a sila',
                children: [
                  {
                    id: '1.2.5.2.1.1',
                    isSelectable: true,
                    name: '1.2.5.2.1.1 Budovy skladů',
                  },
                  {
                    id: '1.2.5.2.2.1',
                    isSelectable: true,
                    name: '1.2.5.2.2.1 Zásobníky a jámy - pozemní',
                  },
                  {
                    id: '1.2.5.2.2.2',
                    isSelectable: true,
                    name: '1.2.5.2.2.2 Sila samostatná',
                  },
                  {
                    id: '1.2.5.2.3.2',
                    isSelectable: true,
                    name: '1.2.5.2.3.2 Nádrže',
                  },
                  {
                    id: '1.2.5.2.7.9',
                    isSelectable: true,
                    name: '1.2.5.2.7.9 Podzemní sklady, nádrže a sila',
                  },
                  {
                    id: '1.2.5.2.9.1',
                    isSelectable: true,
                    name: '1.2.5.2.9.1 Oplocení staveb 1252',
                  },
                ],
              },
            ],
          },
          {
            id: '1.2.6',
            isSelectable: true,
            name: '1.2.6 Budovy pro společenské a kulturní účely, výzkum, vzdělávání a zdravotnictví',
            children: [
              {
                id: '1.2.6.1',
                isSelectable: true,
                name: '1.2.6.1 Budovy pro společenské a kulturní účely',
                children: [
                  {
                    id: '1.2.6.1.1.1',
                    isSelectable: true,
                    name: '1.2.6.1.1.1 Budovy divadel',
                  },
                  {
                    id: '1.2.6.1.2.1',
                    isSelectable: true,
                    name: '1.2.6.1.2.1 Budovy kin',
                  },
                  {
                    id: '1.2.6.1.3.1',
                    isSelectable: true,
                    name: '1.2.6.1.3.1 Budovy zoologických a botanických zahrad',
                  },
                  {
                    id: '1.2.6.1.4.9',
                    isSelectable: true,
                    name: '1.2.6.1.4.9 Budovy pro společenské a kulturní účely j. n.',
                  },
                  {
                    id: '1.2.6.1.7.9',
                    isSelectable: true,
                    name: '1.2.6.1.7.9 Podzemní budovy pro společenské a kulturní účely',
                  },
                  {
                    id: '1.2.6.1.9.1',
                    isSelectable: true,
                    name: '1.2.6.1.9.1 Oplocení budov 1261',
                  },
                ],
              },
              {
                id: '1.2.6.2',
                isSelectable: true,
                name: '1.2.6.2 Muzea a knihovny',
                children: [
                  {
                    id: '1.2.6.2.1.1',
                    isSelectable: true,
                    name: '1.2.6.2.1.1 Budovy muzeí, knihoven, galerií, archivů',
                  },
                  {
                    id: '1.2.6.2.9.1',
                    isSelectable: true,
                    name: '1.2.6.2.9.1 Oplocení budov 1262',
                  },
                ],
              },
              {
                id: '1.2.6.3',
                isSelectable: true,
                name: '1.2.6.3 Školy, univerzity a budovy pro výzkum',
                children: [
                  {
                    id: '1.2.6.3.1.1',
                    isSelectable: true,
                    name: '1.2.6.3.1.1 Budovy škol a univerzit',
                  },
                  {
                    id: '1.2.6.3.2.1',
                    isSelectable: true,
                    name: '1.2.6.3.2.1 Budovy pro vědu a výzkum',
                  },
                  {
                    id: '1.2.6.3.7.9',
                    isSelectable: true,
                    name: '1.2.6.3.7.9 Podzemní budovy pro vzdělávání, výzkum, vědu apod.',
                  },
                  {
                    id: '1.2.6.3.9.1',
                    isSelectable: true,
                    name: '1.2.6.3.9.1 Oplocení budov 1263',
                  },
                ],
              },
              {
                id: '1.2.6.4',
                isSelectable: true,
                name: '1.2.6.4 Budovy pro zdravotnictví',
                children: [
                  {
                    id: '1.2.6.4.1.1',
                    isSelectable: true,
                    name: '1.2.6.4.1.1 Budovy nemocnic a nemocnic s poliklinikou',
                  },
                  {
                    id: '1.2.6.4.1.2',
                    isSelectable: true,
                    name: '1.2.6.4.1.2 Budovy zdravotnických středisek, poliklinik a odborných zdravotnických zařízení',
                  },
                  {
                    id: '1.2.6.4.1.3',
                    isSelectable: true,
                    name: '1.2.6.4.1.3 Budovy léčebných ústavů a lázeňských léčeben',
                  },
                  {
                    id: '1.2.6.4.1.4',
                    isSelectable: true,
                    name: '1.2.6.4.1.4 Budovy hygienicko - epidemiologické služby',
                  },
                  {
                    id: '1.2.6.4.1.5',
                    isSelectable: true,
                    name: '1.2.6.4.1.5 Budovy středisek péče o matku a dítě',
                  },
                  {
                    id: '1.2.6.4.7.9',
                    isSelectable: true,
                    name: '1.2.6.4.7.9 Podzemní budovy pro zdravotnictví',
                  },
                  {
                    id: '1.2.6.4.9.1',
                    isSelectable: true,
                    name: '1.2.6.4.9.1 Oplocení budov 1264',
                  },
                ],
              },
              {
                id: '1.2.6.5',
                isSelectable: true,
                name: '1.2.6.5 Budovy pro sport',
                children: [
                  {
                    id: '1.2.6.5.1.1',
                    isSelectable: true,
                    name: '1.2.6.5.1.1 Budovy pro halové sporty',
                  },
                  {
                    id: '1.2.6.5.2.1',
                    isSelectable: true,
                    name: '1.2.6.5.2.1 Zastřešené tribuny, stadiony',
                  },
                  {
                    id: '1.2.6.5.3.1',
                    isSelectable: true,
                    name: '1.2.6.5.3.1 Kryté bazény',
                  },
                  {
                    id: '1.2.6.5.4.1',
                    isSelectable: true,
                    name: '1.2.6.5.4.1 Budovy tělocvičen',
                  },
                  {
                    id: '1.2.6.5.5.1',
                    isSelectable: true,
                    name: '1.2.6.5.5.1 Budovy jízdáren',
                  },
                  {
                    id: '1.2.6.5.6.9',
                    isSelectable: true,
                    name: '1.2.6.5.6.9 Budovy pro sport a rekreaci j. n.',
                  },
                  {
                    id: '1.2.6.5.9.1',
                    isSelectable: true,
                    name: '1.2.6.5.9.1 Oplocení staveb 1265',
                  },
                ],
              },
            ],
          },
          {
            id: '1.2.7',
            isSelectable: true,
            name: '1.2.7 Budovy nebytové ostatní',
            children: [
              {
                id: '1.2.7.1',
                isSelectable: true,
                name: '1.2.7.1 Budovy pro zemědělství',
                children: [
                  {
                    id: '1.2.7.1.1.1',
                    isSelectable: true,
                    name: '1.2.7.1.1.1 Budovy pro rostlinnou produkci',
                  },
                  {
                    id: '1.2.7.1.1.3',
                    isSelectable: true,
                    name: '1.2.7.1.1.3 Skleníky pro pěstování rostlin',
                  },
                  {
                    id: '1.2.7.1.2.1',
                    isSelectable: true,
                    name: '1.2.7.1.2.1 Budovy pro skladování a úpravu zemědělských produktů',
                  },
                  {
                    id: '1.2.7.1.2.2',
                    isSelectable: true,
                    name: '1.2.7.1.2.2 Sila pro posklizňovou úpravu a skladování obilí',
                  },
                  {
                    id: '1.2.7.1.3.1',
                    isSelectable: true,
                    name: '1.2.7.1.3.1 Budovy pro živočišnou produkci',
                  },
                  {
                    id: '1.2.7.1.4.1',
                    isSelectable: true,
                    name: '1.2.7.1.4.1 Budovy pro lesnictví',
                  },
                  {
                    id: '1.2.7.1.7.9',
                    isSelectable: true,
                    name: '1.2.7.1.7.9 Podzemní budovy pro zemědělství',
                  },
                  {
                    id: '1.2.7.1.9.1',
                    isSelectable: true,
                    name: '1.2.7.1.9.1 Oplocení staveb 1271',
                  },
                ],
              },
              {
                id: '1.2.7.2',
                isSelectable: true,
                name: '1.2.7.2 Budovy pro bohoslužby a náboženské aktivity',
                children: [
                  {
                    id: '1.2.7.2.1.1',
                    isSelectable: true,
                    name: '1.2.7.2.1.1 Chrámy, kostely, synagogy apod.',
                  },
                  {
                    id: '1.2.7.2.2.1',
                    isSelectable: true,
                    name: '1.2.7.2.2.1 Krematoria, pohřební síně',
                  },
                  {
                    id: '1.2.7.2.3.1',
                    isSelectable: true,
                    name: '1.2.7.2.3.1 Hřbitovy a hřbitovní budovy',
                  },
                  {
                    id: '1.2.7.2.9.1',
                    isSelectable: true,
                    name: '1.2.7.2.9.1 Oplocení náboženských budov a hřbitovů',
                  },
                ],
              },
              {
                id: '1.2.7.3',
                isSelectable: true,
                name: '1.2.7.3 Historické nebo kulturní památky',
                children: [
                  {
                    id: '1.2.7.3.1.1',
                    isSelectable: true,
                    name: '1.2.7.3.1.1 Hrady a zámky',
                  },
                  {
                    id: '1.2.7.3.5.1',
                    isSelectable: true,
                    name: '1.2.7.3.5.1 Pomníky, kašny a jiná drobná architektura, ostatní kulturní památky',
                  },
                ],
              },
              {
                id: '1.2.7.4',
                isSelectable: true,
                name: '1.2.7.4 Budovy nebytové ostatní',
                children: [
                  {
                    id: '1.2.7.4.1.1',
                    isSelectable: true,
                    name: '1.2.7.4.1.1 Budovy nebytové ostatní',
                  },
                  {
                    id: '1.2.7.4.7.9',
                    isSelectable: true,
                    name: '1.2.7.4.7.9 Podzemní budovy nebytové ostatní',
                  },
                  {
                    id: '1.2.7.4.9.1',
                    isSelectable: true,
                    name: '1.2.7.4.9.1 Oplocení budov 1274',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]
