import { Field, FormikProps, useFormikContext } from 'formik'
import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import RadioInput from '../../../../../../components/inputs/RadioInput'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'
import { B3_FORM_INITIAL_VALUES } from '../constants'

interface Props { }

export const B35TechnicalDevices: React.FC<Props> = () => {
    const formik = useFormikContext() as FormikProps<typeof B3_FORM_INITIAL_VALUES>
    return (
        <FormSectionTwoColumns
            sectionKey="b3.technicalDevices"
            title="B.3.5 Technologické řešení - základní popis technických a technologických zařízení"
        >
            <div className="sm:col-span-6 p-5 rounded-md">
                <Field
                    label="a) popis stávajícího stavu"
                    name="b3.technicalDevices.a.enabled"
                    component={RadioInput}
                    options={[
                        {
                            label: 'Nová stavba',
                            value: false,
                        },
                        {
                            label: 'Změna dokončené stavby',
                            value: true,
                        },
                    ]}
                />
                {formik.values.b3.technicalDevices.a.enabled === true && (
                    <div className="pl-7">
                        <div className="sm:col-span-6 mt-3">
                            <Field
                                name="b3.technicalDevices.a.description"
                                label=""
                                component={RichTextInput}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="sm:col-span-6 p-5 rounded-md">
                <label className='block text-sm font-semibold leading-6 text-gray-900'>
                    b) popis navrženého řešení
                </label>
                <Field
                    label="Jsou součástí stavby technologie?"
                    name="b3.technicalDevices.b.enabled"
                    component={RadioInput}
                    options={[
                        {
                            label: 'Ano',
                            value: true,
                        },
                        {
                            label: 'Ne',
                            value: false,
                        },
                    ]}
                />
                {formik.values.b3.technicalDevices.b.enabled === true && (
                    <div className="pl-7">
                        <div className="sm:col-span-6 mt-3">
                            <Field
                                name="b3.technicalDevices.b.description"
                                label=""
                                component={RichTextInput}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="sm:col-span-6 p-5 rounded-md">
                <label className='block text-sm font-semibold leading-6 text-gray-900'>
                    c) energetické výpočty
                </label>
                <Field
                    label="Obsahuje projekt energetické výpočty?"
                    name="b3.technicalDevices.c.enabled"
                    component={RadioInput}
                    options={[
                        {
                            label: 'Ano',
                            value: true,
                        },
                        {
                            label: 'Ne',
                            value: false,
                        },
                    ]}
                />
                {formik.values.b3.technicalDevices.c.enabled === true && (
                    <div className="pl-7">
                        <div className="sm:col-span-6 mt-3">
                            <Field
                                name="b3.technicalDevices.c.description"
                                label=""
                                component={RichTextInput}
                            />
                        </div>
                    </div>
                )}
            </div>
        </FormSectionTwoColumns>
    )
}
