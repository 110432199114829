import { MinusIcon } from '@chakra-ui/icons'
import { PlusIcon } from '@heroicons/react/24/outline'
import { Field, FormikProps } from 'formik'
import React from 'react'
import { Button } from '../../../../components/Button'
import { SecondaryButton } from '../../../../components/SecondaryButton'
import { ContactSelectInput } from '../../../../components/inputs/ContactSelectInput'
import { MunicipalitySearchInput } from '../../../../components/inputs/MunicipalitySearchInput'
import RadioInput from '../../../../components/inputs/RadioInput'
import { TextInput } from '../../../../components/inputs/TextInput'
import { removeElement } from '../../../../model/helpers/arrayHelpers'
import { generateRandomString } from '../../../../model/helpers/stringHelpers'
import { ACCOMPANYING_REPORT_FORM_INITIAL_DATA } from './constants'
import { CheckboxInput } from '../../../../components/inputs/CheckboxInput'
import { cn } from '../../../../lib/utils'
import { FormLabel } from '../../../../components/FormLabel'

interface Props {
  formik: FormikProps<typeof ACCOMPANYING_REPORT_FORM_INITIAL_DATA>
}

export const AccompanyingReportA1: React.FC<Props> = ({ formik }) => {
  return (
    <div className="">
      <h2 id="a1Heading" className="font-semibold text-lg">
        A.1 Identifikační údaje
      </h2>
      <p id="a11Heading" className="font-semibold mt-1 text-base leading-6 text-gray-900">
        A.1.1 Údaje o stavbě
      </p>
      <p className="font-semibold mt-3 text-base leading-6 text-gray-800">a) Název stavby</p>
      <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-2">
          <Field name="a1.constructionName" component={TextInput} />
        </div>
      </div>
      <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-6">
          <p className="font-semibold mt-1 text-base leading-6 text-gray-800">b) Místo stavby</p>
        </div>
      </div>
      {formik.values.a1.places.map((place, placeIndex) => (
        <div
          className={cn(
            placeIndex !== 0 ? 'mt-8' : 'mt-3',
            'border border-dotted border-gray-300 p-2 rounded-md drop-shadow-sm',
          )}
          key={place.indexIdentifier}
        >
          <div className="w-full grid grid-cols-1 sm:grid-cols-8 mt-3 gap-3">
            <div className="col-span-4">
              <Field
                label="Katastrální území"
                name={`a1.places[${placeIndex}].municipality.kuKod`}
                component={MunicipalitySearchInput}
              />
            </div>
            <Field
              label="Parcelní číslo"
              name={`a1.places[${placeIndex}].parcelNumber`}
              component={TextInput}
              wrapperClassname="col-span-1"
            />
            <Field
              label="Adresa a číslo popisné"
              name={`a1.places[${placeIndex}].streetNumber`}
              component={TextInput}
              wrapperClassname="col-span-1 sm:col-span-3"
            />
          </div>
          <div className="w-full grid grid-cols-1 sm:grid-cols-4 mt-3 gap-3">
            <Field
              label="Pozemek s právem zákonné služebnosti"
              name={`a1.places[${placeIndex}].statutoryServitudes`}
              component={CheckboxInput}
              className="col-span-1 sm:col-span-2"
            />
            <Field
              label="Pozemek zařízení staveniště"
              name={`a1.places[${placeIndex}].siteFacilities`}
              component={CheckboxInput}
              className="col-span-1 sm:col-span-2"
            />
          </div>
          <div className="flex justify-end mt-3">
            <SecondaryButton
              onClick={() =>
                formik.setFieldValue(
                  'a1.places',
                  removeElement(formik.values.a1.places, placeIndex),
                )
              }
            >
              <MinusIcon className="h-5 w-5" />
            </SecondaryButton>
          </div>
        </div>
      ))}
      <div className="flex justify-end mt-3">
        <Button
          onClick={() =>
            formik.setFieldValue('a1.places', [
              ...formik.values.a1.places,
              {
                indexIdentifier: generateRandomString(),
                municipality: formik.values.a1.places[0]?.municipality,
                siteFacilities: false,
                statutoryServitudes: false,
              },
            ])
          }
        >
          <PlusIcon className="h-5 w-5" />
        </Button>
      </div>
      <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-6">
          <p className="font-semibold mt-1 text-base leading-6 text-gray-800">
            c) Předmět dokumentace
          </p>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 sm:grid-cols-3 mt-3 gap-5">
        <Field
          name="a1.isNewConstruction"
          component={RadioInput}
          options={[
            {
              label: 'Nová stavba',
              value: true,
            },
            {
              label: 'Změna dokončené stavby',
              value: false,
            },
          ]}
        />
        <Field
          name="a1.isPersistent"
          component={RadioInput}
          options={[
            {
              label: 'Trvalá stavba',
              value: true,
            },
            {
              label: 'Dočasná stavba',
              value: false,
            },
          ]}
        />
        <Field name="a1c.purpose" label="Účel užívání" component={TextInput} />
      </div>

      <p id="a12Heading" className="font-semibold mt-12 text-base leading-6 text-gray-900">
        A.1.2 Údaje o zpracovateli dokumentace
      </p>
      <div className="mt-3">
        <Field
          name="a1.documentationAuthor"
          label={`a) Jméno, popřípadě jména a příjmení, obchodní firma, identifikační číslo
            osoby, bylo-li přiděleno, sídlo (fyzická osoba podnikající) nebo obchodní firma
            nebo název, identifikační číslo osoby, bylo-li přiděleno, sídlo (právnická osoba)
          `}
          component={ContactSelectInput}
          options={[]}
        />
      </div>
      <div className="mt-5">
        <Field
          name="a1.primaryDocumentationAuthor"
          label={`b) Jméno a příjmení hlavního projektanta včetně čísla, pod kterým je zapsán
            v evidenci autorizovaných osob vedené Českou komorou architektů nebo
            Českou komorou autorizovaných inženýrů a techniků činných ve výstavbě,
            s vyznačeným oborem, popřípadě specializací jeho autorizace`}
          component={ContactSelectInput}
        />
      </div>
      <div className="mt-8">
        <FormLabel>
          c) Jména a příjmení projektantů jednotlivých částí dokumentace včetně čísla, pod kterým
          jsou zapsáni v evidenci autorizovaných osob vedené Českou komorou architektů nebo Českou
          komorou autorizovaných inženýrů a techniků činných ve výstavbě, s vyznačeným oborem,
          popřípadě specializací jejich autorizace
        </FormLabel>
        {formik.values.a1.otherAuthors.map((other, otherIndex) => (
          <div className="mt-3 grid grid-cols-1 sm:grid-cols-4 gap-3" key={otherIndex}>
            <div className="col-span-1">
              <Field
                name={`a1.otherAuthors[${otherIndex}].label`}
                component={TextInput}
                label="Část společné dokumentace"
              />
            </div>
            <div className="col-span-3">
              <Field
                name={`a1.otherAuthors[${otherIndex}].author`}
                component={ContactSelectInput}
                label="Projektant"
                extraButtons={
                  <SecondaryButton
                    className="ml-3"
                    onClick={() =>
                      formik.setFieldValue(
                        'a1.otherAuthors',
                        removeElement(formik.values.a1.otherAuthors, otherIndex),
                      )
                    }
                  >
                    <MinusIcon className="h-5 w-5" />
                  </SecondaryButton>
                }
              />
            </div>
          </div>
        ))}
        <div className="flex justify-end mt-3 gap-3">
          <Button
            onClick={() =>
              formik.setFieldValue('a1.otherAuthors', [
                ...formik.values.a1.otherAuthors,
                { indexIdentifier: generateRandomString() },
              ])
            }
          >
            <PlusIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>
      <div className="mt-8">
        <FormLabel>
          d) jméno, popřípadě jména a příjmení autorizovaného zeměměřického inženýra včetně čísla
          položky, pod kterým je veden v rejstříku autorizovaných zeměměřických inženýrů u České
          komory zeměměřičů.
        </FormLabel>
        {formik.values.a1.surveyingEngineers?.map((other, engineerIndex) => (
          <div className="mt-3 grid grid-cols-1 sm:grid-cols-4 gap-3" key={engineerIndex}>
            <div className="col-span-1">
              <Field
                name={`a1.surveyingEngineers[${engineerIndex}].authorizationNumber`}
                component={TextInput}
                label="Číslo autorizace"
              />
            </div>
            <div className="col-span-3">
              <Field
                name={`a1.surveyingEngineers[${engineerIndex}].author`}
                component={ContactSelectInput}
                label="Zeměměřický inženýr"
                extraButtons={
                  <SecondaryButton
                    className="ml-3"
                    onClick={() =>
                      formik.setFieldValue(
                        'a1.surveyingEngineers',
                        removeElement(formik.values.a1.surveyingEngineers, engineerIndex),
                      )
                    }
                  >
                    <MinusIcon className="h-5 w-5" />
                  </SecondaryButton>
                }
              />
            </div>
          </div>
        ))}
        <div className="flex justify-end mt-3 gap-3">
          <Button
            onClick={() =>
              formik.setFieldValue('a1.surveyingEngineers', [
                ...formik.values.a1.surveyingEngineers,
                { indexIdentifier: generateRandomString() },
              ])
            }
          >
            <PlusIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>
    </div>
  )
}
