import React from 'react'
import { DocumentPlusIcon } from '@heroicons/react/24/outline'
import { useUploadDocumentMutation } from '../model/api/document/useUploadDocumentMutation'
import { Button } from './ui/button'

interface Props {
  folder: string
  folderKey?: string
  additionalData?: {
    project?: string
    projectForm?: string
    keyPath?: string
    label?: string
  }
}

export const FileUploadButton: React.FC<Props> = (props) => {
  const { mutate: uploadFile, isLoading: isUploadingFile } = useUploadDocumentMutation()

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef<null | HTMLInputElement>(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event: React.MouseEvent) => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0]
    uploadFile({
      folder: props.folder,
      folderKey: props.folderKey,
      file: fileUploaded,
      ...(props.additionalData ? props.additionalData : {}),
    })
  }

  return (
    <>
      <Button
        size="sm"
        type="button"
        variant="outline"
        onClick={handleClick}
        className="flex gap-2"
        isLoading={isUploadingFile}
      >
        <DocumentPlusIcon className="h-5 w-5" />
        Nahrát soubor
      </Button>
      <input
        type="file"
        name="file"
        style={{ display: 'none' }}
        placeholder="Nahrát soubor"
        role="button"
        ref={hiddenFileInput}
        multiple={false}
        onChange={handleChange}
      />
    </>
  )
}
