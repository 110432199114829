import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { DatePickerInput } from '../../../../../components/inputs/DatePickerInput'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Mv2: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      title="m) základní předpoklady výstavby"
      subtitle="časové údaje o realizaci stavby, členění na etapy, věcné a časové vazby stavby, podmiňující, vyvolané a související investice"
      sectionKey="b1m"
    >
      <div className="sm:col-span-6 mt-5 flex flex-col gap-3">
        <div className="">
          <Field label="Termín zahájení" name="b1m.startDate" component={DatePickerInput} />
        </div>
        <div className="">
          <Field label="Termín dokončení" name="b1m.endDate" component={DatePickerInput} />
        </div>
        <div className="">
          <Field label="Členění na etapy" name="b1m.divisionToStages" component={RichTextInput} />
        </div>
        <div>
          <Field
            component={CheckboxInput}
            name="b1m.objectAndTimeConnections"
            label="Věcné a časové vazby"
          />
          {formik.values?.b1m?.objectAndTimeConnections && (
            <div className="pl-7">
              <Field component={RichTextInput} name="b1m.objectAndTimeConnectionsDetails" />
            </div>
          )}
        </div>
        <div>
          <Field
            component={CheckboxInput}
            name="b1m.conditionalInvestments"
            label="Podmiňující investice"
          />

          {formik.values.b1m?.conditionalInvestments && (
            <div className="pl-7">
              <Field component={RichTextInput} name="b1m.conditionalInvestmentsDetails" />
            </div>
          )}
        </div>
        <div>
          <Field
            component={CheckboxInput}
            name="b1m.triggeredInvestments"
            label="Vyvolané investice"
          />
          {formik.values.b1m?.triggeredInvestments && (
            <div className="pl-7">
              <Field component={RichTextInput} name="b1m.triggeredInvestmentsDetails" />
            </div>
          )}
        </div>
        <div>
          <Field
            component={CheckboxInput}
            name="b1m.relatedInvestments"
            label="Související investice"
          />
          {formik.values.b1m?.relatedInvestments && (
            <div className="pl-7">
              <Field component={RichTextInput} name="b1m.relatedInvestmentsDetails" />
            </div>
          )}
        </div>
      </div>
    </FormSectionTwoColumns>
  )
}
