import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Button } from './Button'
import {
  ClockIcon,
  DocumentTextIcon,
  PlusSmallIcon,
  EnvelopeIcon,
  ClipboardDocumentIcon,
  EyeIcon,
} from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  onClick?: (e: React.MouseEvent) => void
  openPublicLink: () => void
  projectId: string
}

export const ButtonWithDropdown: React.FC<Props> = (props) => {
  const toast = useToast()
  const items = [
    {
      name: 'Zkopírovat veřejnou adresu',
      href: '#',
      icon: ClipboardDocumentIcon,
      onClick: () => {
        window.navigator.clipboard.writeText(
          `http://qik.qikapp.cz/preview/project/${props.projectId}`,
        )
        toast({
          title: 'Adresa zkopírována',
          status: 'info',
          position: 'top',
          isClosable: true,
        })
      },
    },
    {
      name: 'Zobrazit veřejnou adresu',
      href: '#',
      icon: EyeIcon,
      onClick: () => {
        props.openPublicLink()
      },
    },
  ]

  return (
    <div className="inline-flex rounded-md shadow-sm">
      <button
        onClick={props.onClick}
        type="button"
        className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
        Přidat
      </button>
      <Menu as="div" className="relative -ml-px block">
        <Menu.Button className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
          <span className="sr-only">Open options</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {items.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900 rounded' : 'text-gray-700',
                        'flex gap-3 items-center',
                      )}
                    >
                      <>
                        <item.icon className={classNames('ml-3 mr-1 h-5 w-5 text-gray-500')} />
                        <a
                          onClick={item.onClick}
                          href={item.href}
                          className={classNames('block py-2 text-sm')}
                        >
                          {item.name}
                        </a>
                      </>
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
