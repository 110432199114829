import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { InfoPopover } from '../../../../../components/InfoPopover'
import { Link } from 'react-router-dom'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Iv2: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1i'}
      title="i) navrhovaná a vznikající ochranná a bezpečnostní pásma"
      subtitle="rozsah omezení a podmínky ochrany podle jiných právních předpisů, včetně seznamu pozemků podle katastru nemovitostí, na kterých ochranné nebo bezpečnostní pásmo vznikne, bezpečnostní vzdálenost muničního skladiště s rizikem střepinového účinku určená podle jiného právního předpisu"
    >
      <div className="sm:col-span-6 mt-5 flex flex-col gap-3">
        <div>
          <Field component={CheckboxInput} name="b1i.protection.enabled" label="Navrhovaná a vznikající ochranná a bezpečnostní pásma" />
          {formik.values.b1i?.protection?.enabled && (
            <div className="pl-7">
              <Field
                name="b1i.protection.details"
                component={RichTextInput}
              />
            </div>
          )}
        </div>
        <div>
          <Field component={CheckboxInput} name="b1i.scale.enabled" label="Rozsah omezení a podmínky ochrany" />
          {formik.values.b1i?.scale?.enabled && (
            <div className="pl-7">
              <Field
                name="b1i.scale.details"
                component={RichTextInput}
              />
            </div>
          )}
        </div>
        <div>
          <Field component={CheckboxInput} name="b1i.ammunition.enabled" label="Bezpečnostní vzdálenost muničního skladiště" />
          {formik.values.b1i?.ammunition?.enabled && (
            <div className="pl-7">
              <Field
                name="b1i.ammunition.details"
                component={RichTextInput}
              />
            </div>
          )}
        </div>
      </div>
    </FormSectionTwoColumns>
  )
}
