import React from 'react'
import { FieldProps } from 'formik'
import { TailwindModal } from '../TailwindModal'
import { useDisclosure } from '@chakra-ui/react'
import { File, Folder, Tree } from '../ui/file-tree'
import { FormLabel } from '../FormLabel'
import { getFieldValidity } from '../../model/helpers/formHelpers'
import { cn } from '../../lib/utils'
import { FolderTreeIcon } from 'lucide-react'

export interface FileTreeElement {
  id: string
  isSelectable: boolean
  name: string
  children?: FileTreeElement[]
}

interface Props extends FieldProps {
  label?: string
  placeholder?: string
  options: FileTreeElement[]
  initialExpandedItems?: string[]
}

export const FileTreeInput: React.FC<Props> = (props) => {
  const modalDisclosure = useDisclosure()
  const { isInvalid, message } = getFieldValidity(props.form, props.field.name)

  const onItemSelected = (element: FileTreeElement) => {
    props.form.setFieldValue(props.field.name, element.name)
    modalDisclosure.onClose()
  }

  return (
    <>
      <div className="relative">
        <FormLabel>{props.label}</FormLabel>
        <div
          onClick={modalDisclosure.onOpen}
          className={cn(
            'cursor-pointer mt-1 block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6',
            isInvalid ? 'ring-red-500' : 'ring-gray-300',
            'hover:shadow-md',
          )}
        >
          {props.field.value || props.placeholder || 'Vyberte...'}
        </div>
        <div className="absolute bottom-2 right-2">
          <FolderTreeIcon className="w-5 h-5 text-gray-400" />
        </div>
      </div>
      <TailwindModal disclosure={modalDisclosure} dialogTitle={props.label ?? ''}>
        <Tree
          initialExpandedItems={props.initialExpandedItems}
          className="p-2 overflow-hidden rounded-md bg-background"
          elements={props.options}
        >
          {renderTree(props.options, onItemSelected)}
        </Tree>
      </TailwindModal>
    </>
  )
}

function renderTree(
  elements: FileTreeElement[],
  onItemSelected: (element: FileTreeElement) => void,
): any {
  return elements.map((element) => {
    if (element.children) {
      return (
        <Folder key={element.id} element={element.name} value={element.id}>
          {renderTree(element.children, onItemSelected)}
        </Folder>
      )
    } else {
      return (
        <File onClick={() => onItemSelected(element)} key={element.id} value={element.id}>
          <p className="text-left">{element.name}</p>
        </File>
      )
    }
  })
}
