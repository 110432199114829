import React from 'react'
import { ProjectFormEnum } from '../../../../../model/enums/ProjectFormEnum'
import { B2UrbanisticSolution } from './B2UrbanisticSolution'
import { B2_FORM_INIT_VALUES } from './constants'
import { TechnicalFormWrapper } from '../TechnicalFormWrapper'

interface Props {}

export const B2ConstructionDescription: React.FC<Props> = (props) => {
  return (
    <TechnicalFormWrapper
      form={ProjectFormEnum.TECHNICAL_FORM_B2}
      initialValues={B2_FORM_INIT_VALUES}
      title="B2. Urbanistické a základní architektonické řešení"
    >
      <B2UrbanisticSolution />
    </TechnicalFormWrapper>
  )
}
