export function formatNumberWithDelimiters(number: number | string | undefined) {
  if (typeof number === 'undefined' || number === '') {
    return ''
  }

  // Convert the input to a number
  let parsedNumber: number
  if (typeof number === 'string') {
    // Replace comma with dot for decimal parsing and remove any spaces
    parsedNumber = parseFloat(number.replace(',', '.').replace(/\s+/g, ''))
  } else {
    parsedNumber = number
  }

  if (isNaN(parsedNumber)) {
    return ''
  }

  // Use Intl.NumberFormat for Czech locale
  return new Intl.NumberFormat('cs-CZ').format(parsedNumber)
}

export function removeNumberDelimiters(formattedNumber: string | number | undefined) {
  if (typeof formattedNumber === 'undefined' || formattedNumber === '') {
    return undefined
  }

  if (typeof formattedNumber === 'number') {
    return formattedNumber
  }

  // Remove spaces and replace comma with dot for decimal parsing
  const numberString = formattedNumber.replace(/\s+/g, '').replace(',', '.')

  const parsedNumber = parseFloat(numberString)

  if (isNaN(parsedNumber)) {
    return 0
  }

  return parsedNumber
}
