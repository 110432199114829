import { B3_FORM_INITIAL_VALUES as B3_FORM_INITIAL_VALUES_SHARED } from 'shared'

export const B3_FORM_INITIAL_VALUES = B3_FORM_INITIAL_VALUES_SHARED

export const B3NavOptions = [
  {
    label: 'B.3.1. Celková koncepce stavebně technického a technologického řešení',
    target: 'b3.overallConceptSolutions',
  },
  {
    label: 'B.3.2 Celkové řešení podmínek přístupnosti',
    target: 'b3.overallAccessibilityConditions',
  },
  {
    label: 'B.3.3 Zásady bezpečnosti při užívání stavby',
    target: 'b3.safetyPrinciples',
  },
  {
    label: 'B.3.4 Základní technický popis stavby',
    target: 'b3.basicTechnicalDescription',
  },
  {
    label: 'B.3.5 Technologické řešení - základní popis technických a technologických zařízení',
    target: 'b3.technicalDevices',
  },
  {
    label: 'B.3.6 Zásady požární bezpečnosti',
    target: 'b3.fireSafety',
  },
  {
    label: 'B.3.7 Úspora energie a tepelná ochrana budovy',
    target: 'b3.energyConservation',
  },
  {
    label: 'B.3.8 Hygienické požadavky na stavbu, požadavky na pracovní a komunální prostředí',
    target: 'b3.hygienicRequirements',
  },
  {
    label: 'B.3.9 Zásady ochrany stavby před negativními účinky vnějšího prostředí',
    target: 'b3.protectionAgainstEnvironmentalFactors',
  },
]