import { Field, FormikProps } from 'formik'
import React from 'react'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Lv2: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1l'}
      title="l) požadavky na kapacity veřejných sítí"
      subtitle="komunikačních vedení a elektronického komunikačního zařízení veřejné komunikační sítě"
    >
      <div className="sm:col-span-6 mt-5 flex flex-col gap-3">
        <Field
          name="b1l.publicNetworksCapacityRequirements"
          component={CheckboxInput}
          label="Požadavky na kapacity veřejných sítí komunikačních vedení a elektronického komunikačního zařízení veřejné komunikační sítě"
        />
        {formik.values?.b1l?.publicNetworksCapacityRequirements && (
          <div className="pl-7">
            <Field
              name="b1l.publicNetworksCapacityRequirementsDescription"
              component={RichTextInput}
            />
          </div>
        )}
      </div>
    </FormSectionTwoColumns>
  )
}
