'use client'

import React from 'react'
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { ISelectOption } from '../model/interfaces/ISelectOption'

interface Props {
  label?: string
  options: ISelectOption[]
  onSelected: (option: ISelectOption) => void
  selected: ISelectOption
}

export const SimpleSelect: React.FC<Props> = (props) => {
  const onChange = (value: string) => {
    const selected = props.options.find((option) => option.value === value)

    if (typeof selected === 'undefined') {
      return
    }

    props.onSelected(selected)
  }

  return (
    <Listbox value={props.selected?.value} onChange={onChange}>
      <Label className="block text-sm font-medium leading-6 text-gray-900">{props.label}</Label>
      <div className="relative mt-2">
        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
          <span className="block truncate">{props.selected?.label}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </span>
        </ListboxButton>

        <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm">
          {props.options.map((option) => (
            <ListboxOption
              key={option.value}
              value={option.value}
              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-blue-600 data-[focus]:text-white rounded-md m-1"
            >
              <span className="block truncate font-normal group-data-[selected]:font-semibold">
                {option.label}
              </span>

              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                <CheckIcon aria-hidden="true" className="h-5 w-5" />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  )
}
