import React from 'react'
import { useProject } from '../../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { TechnicalFormWrapper } from '../TechnicalFormWrapper'
import { ProjectFormEnum } from '../../../../../model/enums/ProjectFormEnum'
import { B3_FORM_INITIAL_VALUES, B3NavOptions } from './constants'
import { B3TechnicalConnectionsFormV2 } from './B3TechnicalConnectionsFormV2'

interface Props {}

export const B3TechnicalConnections: React.FC<Props> = (props) => {
  const { project } = useProject()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <TechnicalFormWrapper
      form={ProjectFormEnum.TECHNICAL_FORM_B3}
      initialValues={B3_FORM_INITIAL_VALUES}
      title="B.3 Základní stavebně technické a technologické řešení"
      quickNavOptions={B3NavOptions}
    >
      <B3TechnicalConnectionsFormV2 />
    </TechnicalFormWrapper>
  )
}
